import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { CareCoordinator } from "../../models/care-coordinator";
import { DialCode } from "../../models/dial-code";
import { HcpFunction } from "../../models/hcp-function";
import { CareCoordinatorService } from "../../services/care-coordinator.service";
import { GeneralService } from "../../services/general.service";
import { HcpService } from "../../services/hcp.service";
import { LanguageService } from "../../services/language.service";
import { EditOtherEmailModalComponent } from "../edit-other-email-modal/edit-other-email-modal.component";
import { LanguageCode } from "../../models/language-code";

@Component({
  selector: 'edit-care-coordinator-modal',
  templateUrl: './edit-care-coordinator.component.html'
})
export class EditCareCoordinatorModalComponent implements OnInit {
  @Output() onCareCoordinatorUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public showRolesValidationMessage = false;
  public isSaving = false;
  public dialCodeOptions: DialCode[] = [];
  public languageOptions: LanguageCode[];
  public functionOptions: HcpFunction[] = [];
  public hospitalUid: string;
  public careCoordinator: CareCoordinator;
  public careCoordinatorUid: string;
  public isLoading: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public careCoordinatorService: CareCoordinatorService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public modalService: BsModalService,
    public hcpService: HcpService
  ) { }
  
  ngOnInit(): void {
    forkJoin([
      this.generalService.getDialCodes(),
      this.languageService.getLanguageCodes(),
      this.hcpService.getFunctions(this.hospitalUid),
      this.careCoordinatorService.get(this.hospitalUid, this.careCoordinatorUid),
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.dialCodeOptions = data[0];
    this.languageOptions = data[1];
    this.functionOptions = data[2];
    this.careCoordinator = data[3];

    this.formSetup();
    this.isLoading = false;
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      gender: [this.careCoordinator.gender, [Validators.required]],
      first_name: [this.careCoordinator.first_name, [Validators.required]],
      preferred_name: [this.careCoordinator.preferred_name, []],
      last_name: [this.careCoordinator.last_name, [Validators.required]],
      language: [this.careCoordinator.language, [Validators.required]],
      email: [this.careCoordinator.email, [Validators.required, Validators.email]],
      function: [this.careCoordinator.function.value, [Validators.required]],
      job_title: [this.careCoordinator.job_title, [Validators.required]],
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl(this.careCoordinator.phone_number.code, [Validators.required]),
        number: new UntypedFormControl(this.careCoordinator.phone_number.number, [Validators.required])
      })
    });

    this.form.get('email')?.disable();
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.showRolesValidationMessage = false;
    this.validationVisible = false;
    this.isSaving = true;

    let values = this.form.value;

    if (values.preferred_name === '') {
      delete values.preferred_name;
    }

    this.careCoordinatorService.update(this.hospitalUid, this.careCoordinator.uid, this.form.value)
      .subscribe(
        () => this.onSuccess(),
        () => this.isSaving = false
      );
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public onSuccess(): void {
    this.onCareCoordinatorUpdated.emit();
    this.bsModalRef.hide();
    this.toastrService.info(this.translate.instant('modals.update_cc.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
  
  showEditEmailModal() {
    const initialState = {
      cc: this.careCoordinator,
      hospitalUid: this.hospitalUid
    };

    const modalRef = this.modalService.show(EditOtherEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalRef.content.emailChanged.subscribe(() => modalRef.hide());
  }
}
