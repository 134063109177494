import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from 'moment-timezone';
import { forkJoin, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { DateFormatMap } from '../models/date-format-map';
import { DialCode } from "../models/dial-code";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends ApiService {
  public dialCodes: DialCode[] = [];
  public dateFormatMap: DateFormatMap;

  constructor (
    http: HttpClient,
    authenticationService: AuthenticationService,
  ) {
    super(http, authenticationService);
  }
  
  public static BsModalOptions(input: any = {}) {
    if (!input.animated) {
      input.animated = false;
    }

    if(!input.backdrop) {
      input.backdrop = 'static';
    }

    return input;
  }

  appInitializer() {
    return new Promise((resolve, reject) => {
      forkJoin([
        this.getDialCodes(),
        this.getDateFormats()
      ]).subscribe(() => {
        resolve(true);
      }, () => {
        reject(true);
      });
    });
  }

  getDialCodeForCountry(country: string): string {
    return this.dialCodes.filter(dialCode => dialCode.country === country)[0]?.code;
  }

  getDialCodes(): Observable<Array<DialCode>> {
    this.dialCodes = [];

    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/dial-codes`;

      this.basicAuthGet(url).subscribe(result => {
        this.dialCodes = this.mapDialCodes(result);
        observer.next(this.dialCodes);
        observer.complete();
      }, () => {
        observer.next(this.dialCodes);
        observer.complete();
      });
    });
  }

  getDateFormats(): Observable<DateFormatMap> {
    this.dateFormatMap = new DateFormatMap({});
    return new Observable(observer => {

      const url = `${environment.platformUrl}/general/date-formats`;
      this.basicAuthGet(url).subscribe(result => {
        this.dateFormatMap = this.mapDateFormatMap(result);
        observer.next(this.dateFormatMap);
        observer.complete();
      }, () => {
        observer.next(this.dateFormatMap);
        observer.complete();
      });
    });
  }

  getWeekDays(): Observable<Array<any>> {
    return new Observable(observer => {
      const weekdays = [
        { value: 'MONDAY', translationKey: 'dates.weekdays.monday', index: 1},
        { value: 'TUESDAY', translationKey: 'dates.weekdays.tuesday', index: 2},
        { value: 'WEDNESDAY', translationKey: 'dates.weekdays.wednesday', index: 3},
        { value: 'THURSDAY', translationKey: 'dates.weekdays.thursday', index: 4},
        { value: 'FRIDAY', translationKey: 'dates.weekdays.friday', index: 5},
        { value: 'SATURDAY', translationKey: 'dates.weekdays.saturday', index: 6},
        { value: 'SUNDAY', translationKey: 'dates.weekdays.sunday', index: 0}
      ];
      
      observer.next(weekdays);
      observer.complete();
    });
  }

  getOptionList(): Observable<Array<any>> {
    return new Observable(observer => {
      const options = [
        { value: 'OPTIONAL', translationKey: 'Optional', index: 1},
        { value: 'REQUIRED', translationKey: 'Required', index: 2},
        { value: 'DISABLED', translationKey: 'Disabled', index: 3}
      ];
      
      observer.next(options);
      observer.complete();
    });
  }

  getBooleanList(): Observable<Array<any>> {
    return new Observable(observer => {
      const options = [
        { value: 'true', translationKey: 'Yes', index: 1},
        { value: 'false', translationKey: 'No', index: 2}
      ];
      
      observer.next(options);
      observer.complete();
    });
  }

  getNumberFormats(): Observable<Array<any>> {
    return new Observable(observer => {
      const numberFormats = [
        { label: 'US Format', value: 'DECIMAL_POINT'},
        { label: 'European Format', value: 'DECIMAL_COMMA'}
      ];

      observer.next(numberFormats);
      observer.complete();
    });
  }

  getTimeFormats(): Observable<Array<any>> {
    return new Observable(observer => {
      const timeFormats = [
        { label: '12 hours', value: false},
        { label: '24 hours', value: true}
      ];

      observer.next(timeFormats);
      observer.complete();
    });
  }

  getTimeZones(): Observable<Array<any>> {
    return new Observable(observer => {
      const timeZones:any = [];

      moment.tz.names().forEach((element: any) => {
        timeZones.push({label: element, value: element});
      });

      observer.next(timeZones);
      observer.complete();
    });
  }

  getMeasurementUnits(): Observable<Array<any>> {
    return new Observable(observer => {
      const measurementUnits = [
        { label: 'Metric', value: 'METRIC' },
        { label: 'Imperial', value: 'IMPERIAL' }
      ];

      observer.next(measurementUnits);
      observer.complete();
    });
  }

  mapDialCodes(result: any): Array<DialCode> {
    const dialCodes = new Array();

    Object.keys(result).forEach(key => {
      dialCodes.push(new DialCode(key, result[key]));
    });

    return dialCodes;
  }

  mapDateFormatMap(item: string): DateFormatMap {
    return new DateFormatMap(item);
  }
  
  dateToString(date: Date): string {
      let d: string = String(date.getDate());
      let m: string = String(date.getMonth()+1);
      let y: string = String(date.getFullYear());

      if (d.length == 1) {
        d = "0" + d;
      }

      if (m.length == 1) {
        m = "0" + m;
      }

      return `${y}-${m}-${d}`;
  }
}
