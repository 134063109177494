<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals', hospitalUid, 'care_modules']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospital' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1 *ngIf="careModule">{{ careModule.defaultName }}</h1>
        </div>
        <div class="col col-auto">
          <button class="btn btn-sm btn-secondary" (click)="addFaq($event)">{{ 'pages.default.faqs.new' | translate }}</button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-care-module-tabs  [active]="'faqs'" [hospital_uid]="hospitalUid" [care_module]="careModule"></app-care-module-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <table class="table admin-table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">Order</th>
          <th scope="col">Reference</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let faq of faqs; let index = index;">
          <td class="arrow">
            <button (click)="move($event, faq, -1)" [disabled]="disabled(faq, 'up')" class="btn border-white bg-transparent text-danger p-0" [ngClass]="{'text-muted ': disabled(faq, 'up')}">
              <span [inlineSVG]="'/assets/svg/arrow-up.svg'" class="icon"></span>
            </button>
          </td>
          <td class="arrow pl-0">
            <button (click)="move($event, faq, +1)" [disabled]="disabled(faq, 'down')" class="btn border-white bg-transparent text-danger p-0" [ngClass]="{'text-muted ': disabled(faq, 'down')}">
              <span [inlineSVG]="'/assets/svg/arrow-down.svg'" class="icon"></span>
            </button>
          </td>
          <td class="order">{{ faq.order }}</td>
          <td>{{ faq.faq.key }}</td>
          <td class="remove">
            <a href="#" class="text-danger" (click)="removeFaq($event, faq)">{{ 'pages.default.faqs.remove' | translate }}<span [inlineSVG]="'/assets/svg/close.svg'" class="mx-2"></span></a>
          </td>
          <td class="action">
            <a href="#" class="action-button text-white block-action" (click)="editFaq($event, faq)">
              <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon rtl-mirrored-inline-svg"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-muted" *ngIf="faqs.length === 0">
      No FAQ's have been added to this care module.
    </p>
  </div>
</div>
