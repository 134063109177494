<div class="page-tabs">
  <div>
    <ul class="inline-nav no-wrap m-0">
      <li [ngClass]="{'active': active === 'settings'}">
        <a href="" [routerLink]="['/hospitals/' + hospital_uid + '/care_modules/' + care_module?.uid + '/settings']">{{ 'pages.default.care_module.tab_items.settings' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'learning_materials'}">
        <a href="" [routerLink]="['/hospitals/' + hospital_uid + '/care_modules/' + care_module?.uid + '/learning-materials']">{{ 'pages.default.care_module.tab_items.learning_materials' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'faqs'}">
        <a href="" [routerLink]="['/hospitals/' + hospital_uid + '/care_modules/' + care_module?.uid + '/faqs']">{{ 'pages.default.care_module.tab_items.faqs' | translate }}</a>
      </li>
    </ul>
  </div>
</div>
