<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.add_platform_consent.title' | translate }}</h4>

  <form [ngClass]="{'validation-visible': validationVisible}" [formGroup]="form">
    <div class="mt-3">
      <label class="small d-block required">{{ 'form.labels.enabled' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="enabledTrue" formControlName="enabled" value="true">
        <label class="custom-control-label" for="enabledTrue">{{ 'form.labels.yes' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="enabledFalse" formControlName="enabled" value="false">
        <label class="custom-control-label" for="enabledFalse">{{ 'form.labels.no' | translate }}</label>
      </div>
      <app-feedback-field [field]="form.get('enabled')"></app-feedback-field>
    </div>

    <hr class="my-2">

    <div class="form-group styled floating-label">
      <input type="text" id="key" class="form-control w-100" [placeholder]="'form.labels.key' | translate" formControlName="key">
      <label for="key" class="required">{{ 'form.labels.key' | translate }}</label>
      <app-feedback-field [field]="form.get('key')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        id="category"
        [placeholder]="('form.labels.category' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="categories"
        [bindValue]="'value'"
        formControlName="category"
      ></app-select>
      <app-feedback-field [field]="form.get('category')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="order" class="form-control w-100" [placeholder]="'form.labels.order' | translate" formControlName="order">
      <label for="order" class="required">{{ 'form.labels.order' | translate }}</label>
      <app-feedback-field [field]="form.get('order')"></app-feedback-field>
    </div>

    <hr class="my-2">

    <div class="form-group">
      <label class="required">{{ 'form.labels.language' | translate }}</label>
      <div class="custom-control custom-checkbox mb-1" *ngFor="let language of languages">
        <input type="checkbox" class="custom-control-input" [id]="language.code" (click)="onLanguageCheckChanged($event, language.key)">
        <label class="custom-control-label" [for]="language.code">{{languageTranslations[language.code] ?? language.key}}</label>
      </div>
      <p class="validation-feedback d-block" *ngIf="validationVisible && selectedLanguages.length === 0">{{ 'form.feedback.select_min_one' | translate }}</p>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_platform_consent.add_consent' | translate }}</span>
    </button>
  </div>
</div>

