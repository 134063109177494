import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PathwayConfigurationParameter } from '../../interfaces/pathway-configuration-parameter.interface';
import { CareModule } from '../../models/care-module';
import { PathwayTemplate } from '../../models/pathway-template';
import { CareModuleService } from '../../services/care-module.service';
import { GeneralService } from '../../services/general.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-care-modal-edit-template',
  templateUrl: './care-modal-edit-template.component.html'
})
export class CareModalEditTemplateComponent {
  @Input() pathwayTemplate: PathwayTemplate;
  @Input() careModule: CareModule;
  @Input() hospitalUid: string;

  @Output() onCareModuleUpdated = new EventEmitter();

  public pathwayTemplateValid: boolean;
  public configurationParameters: PathwayConfigurationParameter[];
  public currentConfigurationParameters: any;

  public isSaving: boolean;

  public get buttonEnabled(): boolean {
    return this.pathwayTemplateValid;
  }

  constructor(
    public bsModalRef: BsModalRef,
    public careModuleService: CareModuleService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public modalService: BsModalService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentConfigurationParameters = this.careModule.configuration_parameters;
  }

  mappedPayload(): CareModule {
    let configuration_parameters: any = {};

    this.configurationParameters?.forEach((param: PathwayConfigurationParameter) => {
      configuration_parameters[param.key] = param.default_value.toString();
    });

    this.careModule.configuration_parameters = configuration_parameters;
    this.careModule.pathway_template_uid = this.pathwayTemplate?.uid;
    this.careModule.pathway_template_version = this.pathwayTemplate?.major_version;

    return this.careModule;
  }

  onHandleSubmit() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.careModuleService.update(this.hospitalUid, this.mappedPayload()).subscribe(() => {
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onCareModuleUpdated.emit();
    }, () => {
      this.isSaving = false;
    });
  }

  onHandleClose() {
    if (this.pathwayTemplateValid) {
      const modalRef = this.modalService.show(ConfirmModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered',
          initialState: {
            title: this.translateService.instant('modals.edit_cm_template.confirm_cancel.title'),
            description: this.translateService.instant('modals.edit_cm_template.confirm_cancel.description'),
            yes: this.translateService.instant('modals.edit_cm_template.confirm_cancel.yes'),
            no: this.translateService.instant('modals.edit_cm_template.confirm_cancel.no')
          }
        })
      );

      modalRef.content.onChoice.subscribe(() => {
        modalRef.hide();
        this.bsModalRef.hide();
      });
    } else {
      this.bsModalRef.hide();
    }
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_cm_template.success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

}
