<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals']" class="text-secondary font-weight-bold">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_applications' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ application.name }}</h1>
        </div>
        <div class="col col-auto">
          <button class="btn btn-sm btn-secondary" (click)="addConsent($event)">{{ 'pages.default.application_consents.add' | translate }}</button>
        </div>
      </div>

      <div class="page-tabs">
        <div>
          <ul class="inline-nav no-wrap m-0">
            <li class="active">
              <a href="#">{{ 'pages.default.application.tab_items.consents' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <p class="text-muted" *ngIf="consents.length === 0">
      {{ 'pages.default.application_consents.empty' | translate }}
    </p>
    <table class="table admin-table" *ngIf="consents.length > 0">
      <thead>
        <tr>
          <th scope="col">Order</th>
          <th scope="col">Enabled</th>
          <th scope="col">Key</th>
          <th scope="col">Version</th>
          <th scope="col">Category</th>
          <th scope="col">Language(s)</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let consent of consents; let index = index;">
          <td>{{index + 1}}</td>
          <td class="icon-col">
            <span *ngIf="consent?.enabled" [inlineSVG]="'/assets/svg/true.svg'"></span>
            <span *ngIf="!consent?.enabled" [inlineSVG]="'/assets/svg/false.svg'"></span>
          </td>
          <td><strong>{{ consent?.key }}</strong></td>
          <td>{{consent?.version}}</td>
          <td>{{consent?.category}}</td>
          <td>{{consent?.languages?.join(', ')}}</td>
          <td class="remove">
            <a href="#" class="text-danger" (click)="removeConsent($event, consent)">{{ 'action.remove' | translate }}<span [inlineSVG]="'/assets/svg/close.svg'" class="mx-2"></span></a>
          </td>
          <td class="action">
            <a href="#" class="action-button text-white block-action" (click)="editConsent($event, consent)">
              <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon rtl-mirrored-inline-svg"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
