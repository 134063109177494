import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PathwayInstanceService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);

    this.platformUrl = environment.platformUrl;
  }

  public generateLinks(patientPathwayInstanceUid: string): Observable<any> {
    return new Observable(observer => {
      this.authenticatedGet(`${this.platformUrl}/platform/pathway-instances/${patientPathwayInstanceUid}/generate-links`).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
