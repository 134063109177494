import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareModule } from '../../models/care-module';
import { CareModuleService } from '../../services/care-module.service';
import { LanguageService } from '../../services/language.service';
import { LanguageCode } from '../../models/language-code';

@Component({
  selector: 'app-add-cm-translation',
  templateUrl: './add-cm-translation.component.html',
  styleUrls: ['./add-cm-translation.component.scss']
})
export class AddCmTranslationComponent implements OnInit {
  @Output() onTranslationAdded = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public isSaving: boolean = false;
  public validationVisible: boolean = false;
  public languages: LanguageCode[];
  public hospitalUid: string;
  public careModule: CareModule;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public languageService: LanguageService,
    public careModuleService: CareModuleService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      language: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', []],
    });

    this.languageService.getLanguageCodes().subscribe((result) => {
      const keys = this.careModule.name.translations.map((item) => item.language);
      this.languages = result.filter((option) => !keys.includes(option.key));
    });
  }

  public onHandleSubmit() {

    if (!this.form.valid) {
      this.validationVisible = true
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    let data = this.careModule.original;

    data.name.translations.push({
      translation: this.form.value.name,
      language: this.form.value.language
    });

    if (this.form.value.description !== '') {
      data.description.translations.push({
        translation: this.form.value.description,
        language: this.form.value.language
      });
    }

    this.careModuleService.update(this.hospitalUid, data).subscribe(() => {
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onTranslationAdded.emit();
    }
      , () => {
        this.isSaving = false;
      });
  }

  public onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.add_cm_translation.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
