import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Hospital, GeneralConfigurationInterface } from "../../models/hospital";
import { HospitalService } from "../../services/hospital.service";

@Component({
  selector: 'app-edit-hospital-registration-code-modal',
  templateUrl: './edit-hospital-registration-code-modal.component.html'
})
export class EditHospitalRegistrationCodeModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public form = this.formBuilder.group({
    hospital_code_days_valid_indefinite: new FormControl<boolean>(false, { nonNullable: true }),
    hospital_code_days_valid: new FormControl<number | null>(null)
  });

  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;

  public hospitalCodeDaysValidMin = 7;
  public hospitalCodeDaysValidMax = 365;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService
  ) { }

  ngOnInit(): void {
    console.debug('ngOnInit', this.hospital);
    this.formSetup();
  }

  formSetup() {
    this.form.patchValue({
      hospital_code_days_valid_indefinite: this.hospital.general_configuration.hospital_code_days_valid_indefinite,
      hospital_code_days_valid: this.hospital.general_configuration.hospital_code_days_valid_indefinite ?
        null :
        this.hospital.general_configuration.hospital_code_days_valid
    });

    if (!this.form.get('hospital_code_days_valid_indefinite')?.value) {
      this.form.get('hospital_code_days_valid')?.setValidators([Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)]);
      this.form.get('hospital_code_days_valid').updateValueAndValidity();
    }

    this.form.get('hospital_code_days_valid_indefinite')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('hospital_code_days_valid').clearValidators();
      } else {
        this.form.get('hospital_code_days_valid')?.setValidators([Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)]);
      }

      this.form.get('hospital_code_days_valid').updateValueAndValidity();
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);

    clonedHospital.general_configuration = this.form.value as GeneralConfigurationInterface;

    if (this.form.get('hospital_code_days_valid_indefinite')?.value) {
      delete clonedHospital.general_configuration.hospital_code_days_valid;
    }

    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
