import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Application } from '../../../app/models/application';
import { ApplicationService } from '../../../app/services/application.service';

@Component({
  selector: 'app-add-application-modal',
  templateUrl: './add-application-modal.component.html'
})
export class AddApplicationModalComponent implements OnInit {
  @Output() onApplicationCreated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public application = new Application();
  public showRolesValidationMessage = false;
  public types = [{
    label: "Mobile",
    value: "MOBILE"
  }, {
    label: "Web",
    value: "WEB"
  }];

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      active: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.min(1)]],
      application_url: ['', [Validators.required, Validators.min(1)]],
      type: ['', [Validators.required]],
      role_patient: [''],
      role_hcp: [''],
      role_cc: [''],
      minimum_required_version: ['', [Validators.required, Validators.min(1), Validators.max(50)]],
      session_time_out_minutes: ['', [Validators.required, Validators.min(5), Validators.max(525600)]],
      secret: [this.generateSecret(), [Validators.required, Validators.min(1)]],
    });
  }

  public generateSecret(): string {
    if (window.crypto) {
      let length = Math.floor(Math.random() * 16) + 8;
      let wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';
      return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join('');
    }

    return '';
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    const values = this.form.value;
    if (!values.role_patient && !values.role_hcp && !values.role_cc) {
      this.showRolesValidationMessage = true;
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    this.applicationService.createApplication(this.form.value).subscribe(() => this.onCreateSuccess(), 
      (error) => this.isSaving = false);
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.add_application.created'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onApplicationCreated.emit();
    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
