import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-profile-picture-modal',
  templateUrl: './profile-picture-modal.component.html'
})
export class ProfilePictureModalComponent implements OnInit {
  @Output() onImageCropped: EventEmitter<any> = new EventEmitter();

  public picture: string;
  public croppedImage: any = '';

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.blobToBase64(event.blob).then(result => {
      this.croppedImage = result;
    });
  }

  handleCropped() {
    this.onImageCropped.emit(this.croppedImage);
    this.bsModalRef.hide();
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
