<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_address' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div formGroupName="address">
      <div class="row">
        <div class="col-6">
          <div class="form-group styled floating-label">
            <input type="text" id="street" class="form-control w-100" [placeholder]="'form.labels.street' | translate" formControlName="street">
            <label for="street" class="required">{{ 'form.labels.street' | translate }}</label>
            <app-feedback-field [field]="form.get('address.street')"></app-feedback-field>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group styled floating-label">
            <input type="text" id="number" class="form-control w-100" [placeholder]="'form.labels.number' | translate" formControlName="number">
            <label for="number" class="required">{{ 'form.labels.number' | translate }}</label>
            <app-feedback-field [field]="form.get('address.number')"></app-feedback-field>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group styled floating-label">
            <input type="text" id="bus" class="form-control w-100" [placeholder]="'form.labels.bus' | translate" formControlName="bus">
            <label for="bus">{{ 'form.labels.bus' | translate }}</label>
            <app-feedback-field [field]="form.get('address.bus')"></app-feedback-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="form-group styled floating-label">
            <input type="text" id="postal_code" class="form-control w-100" [placeholder]="'form.labels.postal_code' | translate" formControlName="postal_code">
            <label for="postal_code" class="required">{{ 'form.labels.postal_code' | translate }}</label>
            <app-feedback-field [field]="form.get('address.postal_code')"></app-feedback-field>
          </div>
        </div>
        <div class="col-7">
          <div class="form-group styled floating-label">
            <input type="text" id="city" class="form-control w-100" [placeholder]="'form.labels.city' | translate" formControlName="city">
            <label for="city" class="required">{{ 'form.labels.city' | translate }}</label>
            <app-feedback-field [field]="form.get('address.city')"></app-feedback-field>
          </div>
        </div>
      </div>
      <div class="form-group required">
        <app-select
          id="country"
          [placeholder]="('form.labels.country' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="countries"
          [bindValue]="'value'"
          formControlName="country"
        ></app-select>
        <app-feedback-field [field]="form.get('address.country')"></app-feedback-field>
      </div>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
