import { AddressInterface } from "../interfaces/address.interface";
import { LocaleInterface } from "../interfaces/locale.interface";
import { PhoneNumberInterface } from "../interfaces/phone-number.interface";
import { SiteInterface } from "../interfaces/site.interface";
import { TranslationInterface } from "../interfaces/translation.interface";
import { PasswordPolicy } from "./password-policy";

export interface GeneralConfigurationInterface {
  hospital_code_days_valid_indefinite: boolean;
  hospital_code_days_valid?: number | undefined;
}

interface SecurityConfigurationInterface {
  password_policy: PasswordPolicy;
}

interface IntegrationConfigurationInterface {
  external: boolean;
}

interface dataSourceConfigurationInterface {
  external: boolean;
  url: string;
  username: string;
  password: string;
}

export class Hospital {
  public uid: string;
  public name: string;
  public site: SiteInterface;
  public address: AddressInterface;
  public phone_number: PhoneNumberInterface;
  public active: boolean;
  public logo: TranslationInterface;
  public metadata: TranslationInterface;
  public email: string;
  public default_locale: LocaleInterface;
  public general_configuration?: GeneralConfigurationInterface;
  public security_configuration: SecurityConfigurationInterface;
  public datasource_configuration: dataSourceConfigurationInterface;
  public integration_configuration: IntegrationConfigurationInterface;


  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.uid = item.uid;
    this.name = item.name;
    this.site = item.site;
    this.address = item.address;
    this.phone_number = item.phone_number;
    this.active = item.active;
    this.logo = item.logo;
    this.metadata = item.metadata;
    this.email = item.email;
    this.default_locale = item.default_locale;
    this.general_configuration = item.general_configuration;
    this.security_configuration = item.security_configuration;
    this.datasource_configuration = item.datasource_configuration;
    this.integration_configuration = item.integration_configuration;
  }
}
