import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { forkJoin } from "rxjs";
import { EditHospitalAddressModalComponent } from "../../../modals/edit-hospital-address-modal/edit-hospital-address-modal.component";
import { EditHospitalContactModalComponent } from "../../../modals/edit-hospital-contact-modal/edit-hospital-contact-modal.component";
import { EditHospitalFeaturesComponent } from "../../../modals/edit-hospital-features/edit-hospital-features.component";
import { EditHospitalGeneralInformationModalComponent } from "../../../modals/edit-hospital-general-information-modal/edit-hospital-general-information-modal.component";
import { EditHospitalPasswordPolicyModalComponent } from "../../../modals/edit-hospital-password-policy-modal/edit-hospital-password-policy-modal.component";
import { EditHospitalPreferencesModalComponent } from "../../../modals/edit-hospital-preferences-modal/edit-hospital-preferences-modal.component";
import { EditHospitalRegistrationCodeModalComponent } from "../../../modals/edit-hospital-registration-code-modal/edit-hospital-registration-code-modal.component";
import { DialCode } from "../../../models/dial-code";
import { Hospital } from "../../../models/hospital";
import { Module } from "../../../models/module";
import { CountryService } from "../../../services/country.service";
import { GeneralService } from "../../../services/general.service";
import { HospitalService } from "../../../services/hospital.service";
import { LanguageService } from "../../../services/language.service";
import { ModuleService } from "../../../services/module.service";

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
})
export class HospitalComponent {
  public hospital: Hospital | undefined;
  public hospitalUid: string;
  public country: string = '';
  public language: string = '';
  public isLoading: boolean;

  public dialCodeOptions: DialCode[] = [];
  public measurementUnits: any[];
  public numberFormats: any[];
  public dateFormats: any;
  public timeZones: any[];
  public countries: any[];
  public options: any[];

  public enabledFeatures: string[] = [];
  public disabledFeatures: string[] = [];
  public modules: Array<Module> = [];

  constructor(
    public hospitalService: HospitalService,
    public readonly activatedRoute: ActivatedRoute,
    public countryService: CountryService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public modalService: BsModalService,
    public moduleService: ModuleService,
    public translateService: TranslateService
  ) {
    this.handleHospitalUpdated = this.handleHospitalUpdated.bind(this);

    this.getHospital();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.getHospital();
    });

    forkJoin([
      this.generalService.getDialCodes(),
      this.generalService.getMeasurementUnits(),
      this.generalService.getNumberFormats(),
      this.generalService.getTimeZones(),
      this.generalService.getDateFormats(),
      this.generalService.getOptionList(),
      this.moduleService.getModule(this.hospitalUid, 'messaging'),
      this.moduleService.getModule(this.hospitalUid, 'appointment'),
      this.moduleService.getModule(this.hospitalUid, 'analytics')
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.dialCodeOptions = data[0];
    this.measurementUnits = data[1];
    this.numberFormats = data[2];
    this.timeZones = data[3];
    this.dateFormats = data[4].dateFormats;
    this.options = data[5];

    this.setFeatureStatus(data[6], 'messaging');
    this.setFeatureStatus(data[7], 'appointment');
    this.setFeatureStatus(data[8], 'analytics');

    if (this.generalService.dateFormatMap) {
      this.dateFormats = this.generalService.dateFormatMap.dateFormats;
    }
  }

  getHospital() {
    if (!this.hospitalUid) {
      return
    }

    this.hospital = undefined;
    this.hospitalService.getHospital(this.hospitalUid).subscribe(result => {
      this.isLoading = false;
      this.hospital = result;
      this.getCountryLabel(this.hospital.address.country);
      this.getLanguageLabel(this.hospital.default_locale.language);
    });
  }

  setFeatureStatus(module: Module, defaultName: string) {
    if (module) {
      this.modules = this.modules.filter(m => m.name !== module.name);
      this.modules.push(module);
    }

    if (module && module.enabled && !this.enabledFeatures.find(feature => feature === defaultName)) {
      this.enabledFeatures.push(defaultName);

      this.disabledFeatures = this.disabledFeatures.filter(feature => feature !== defaultName);

    } else if (module && !module.enabled && !this.disabledFeatures.find(feature => feature === defaultName)) {
      this.disabledFeatures.push(defaultName);

      this.enabledFeatures = this.enabledFeatures.filter(feature => feature !== defaultName);
    }
  }

  getCountryLabel(country: string) {
    this.countryService.getCountryLabelForKey(country).subscribe((result: string) => {
      this.country = result;
    });
  };

  getLanguageLabel(language: string) {
    this.languageService.getLanguageFromLanguageCode(language.toLowerCase()).subscribe((result: string) => {
      this.language = result;
    });;
  }

  getNumberFormat(): string {
    if (!this.hospital?.default_locale?.number_format) return '';
    return this.findKey(this.numberFormats, this.hospital?.default_locale?.number_format);
  }

  getPhoneCountryCode(): string {
    if (!this.hospital?.phone_number?.code) return '';
    return this.findKey(this.dialCodeOptions, this.hospital.phone_number.code);
  }

  getMeasurementUnit(): string {
    if (!this.hospital?.default_locale?.measurement_unit) return '';
    return this.findKey(this.measurementUnits, this.hospital.default_locale.measurement_unit);
  }

  getTimeZone(): string {
    if (!this.hospital?.default_locale?.time_zone) return '';
    return this.findKey(this.timeZones, this.hospital?.default_locale?.time_zone);
  }

  getDateFormat(): string {
    if (!this.hospital?.default_locale?.date_format) return '';
    return this.findKey(this.dateFormats, this.hospital?.default_locale?.date_format);
  }

  getOption(key: string | undefined): string {
    if (key === undefined || !this.options) return '';

    const result = this.options.find((item) => item.value === key);

    if (!result) return key;

    return result.translationKey;
  }

  getBooleanLabel(value: boolean | undefined) {
    if (value === undefined) return '';
    if (value) {
      return 'general.yes';
    } else {
      return 'general.no';
    }
  }

  editGeneralInformation(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalGeneralInformationModalComponent);
  }

  editContact(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalContactModalComponent);
  }

  editAddress(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalAddressModalComponent);
  }

  editRegistrationCode(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalRegistrationCodeModalComponent);
  }

  editPreferences(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalPreferencesModalComponent);
  }

  editPasswordPolicy(event: MouseEvent) {
    event.preventDefault();
    this.showModal(EditHospitalPasswordPolicyModalComponent);
  }

  editFeatures(event: MouseEvent) {
    event.preventDefault();

    this.showModal(EditHospitalFeaturesComponent, {
      modules: this.modules
    });
  }

  showModal(modal: any, initialStateProps?: any) {
    const initialState = {
      hospital: this.hospital,
      ...initialStateProps
    };

    const modalref = this.modalService.show(modal,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    //@ts-ignore
    modalref.content.onHospitalUpdated.subscribe(this.handleHospitalUpdated);
  }

  handleHospitalUpdated(hospital: Hospital) {
    if (hospital) {
      this.hospital = new Hospital(hospital);
      this.getCountryLabel(this.hospital.address.country);
      this.getLanguageLabel(this.hospital.default_locale.language);
    } else {
      this.getHospital();
      this.getFeatures();
    }
  }

  getFeatures() {
    this.moduleService.getModule(this.hospitalUid, 'messaging').subscribe(result => {
      if (result) {
        this.setFeatureStatus(result, 'messaging')
      }
    });

    this.moduleService.getModule(this.hospitalUid, 'appointment').subscribe(result => {
      if (result) {
        this.setFeatureStatus(result, 'appointment')
      }
    });

    this.moduleService.getModule(this.hospitalUid, 'analytics').subscribe(result => {
      if (result) {
        this.setFeatureStatus(result, 'analytics')
      }
    });
  }

  private findKey(array: Array<{ label: string, value: string }>, key: string) {
    let result = undefined;
    if (array) {
      result = array.find((item) => item.value === key);
    }

    if (!result) return key;

    return result.label;
  }
}
