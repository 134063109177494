<div class="page-tabs">
  <div>
    <ul class="inline-nav no-wrap m-0">
      <li [ngClass]="{'active': active === 'settings'}">
        <a href="#" [routerLink]="['/hospitals/' + hospital?.uid]">{{ 'pages.default.hospital.tab_items.settings' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'applications'}">
        <a href="#" [routerLink]="['/hospitals/' + hospital?.uid + '/applications']">{{ 'pages.default.hospital.tab_items.applications' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'consents'}">
        <a href="#" [routerLink]="['/hospitals/' + hospital?.uid + '/consents']">{{ 'pages.default.hospital.tab_items.consents' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'care_modules'}">
        <a href="#" [routerLink]="['/hospitals/' + hospital?.uid + '/care_modules']">{{ 'pages.default.hospital.tab_items.care_modules' | translate }}</a>
      </li>
      <li [ngClass]="{'active': active === 'care_coordinators'}">
        <a href="#" [routerLink]="['/hospitals/' + hospital?.uid + '/care_coordinators']">{{ 'pages.default.hospital.tab_items.care_coordinators' | translate }}</a>
      </li>
    </ul>
  </div>
</div>
