import { Component, Input } from '@angular/core';
import { Hospital } from '../../models/hospital';

@Component({
  selector: 'app-hospital-tabs',
  templateUrl: './hospital-tabs.component.html'
})
export class HospitalTabsComponent {
  @Input() hospital?: Hospital;
  @Input() active : string;
  
  constructor() { }
}
