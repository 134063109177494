<div class="team-step">
  <div class="container">
    <h3>{{ 'modals.add_care_module.default_team' | translate }}</h3>

    <form [formGroup]="teamForm" autocomplete="off">
      <div class="form-group" [ngClass]="{'required': cmType === careModuleType.EDUCATION}">
        <app-select [placeholder]="('modals.add_care_module.clinical_lead' | translate)" [hideSelected]="false"
          [multiple]="false" [clearable]="false" [searchable]="true" [items]="hcps" [searchFn]="customSearchFn"
          (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()" formControlName="clinicalLead"
          [asFilter]="false" [showCheckboxes]="false">
        </app-select>
      </div>

      <div class="form-group" [ngClass]="{'required': cmType === careModuleType.EDUCATION}">
        <app-select [placeholder]="('modals.add_care_module.case_manager' | translate)" [hideSelected]="false"
          [multiple]="false" [clearable]="false" [searchable]="true" [items]="hcps" [searchFn]="customSearchFn"
          (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()" formControlName="caseManager"
          [asFilter]="false" [showCheckboxes]="false">
        </app-select>
      </div>
    </form>
  </div>
</div>
