import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HospitalService } from '../../../../app/services/hospital.service';
import { AddFaqModalComponent } from '../../../modals/add-faq-modal/add-faq-modal.component';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { EditFaqModalComponent } from '../../../modals/edit-faq-modal/edit-faq-modal.component';
import { CareModule } from '../../../models/care-module';
import { Faq } from '../../../models/faq';
import { CareModuleService } from '../../../services/care-module.service';
import { FaqService } from '../../../services/faq.service';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-care-module-faqs',
  templateUrl: './care-module-faqs.component.html'
})
export class CareModuleFaqsComponent implements OnInit {
  public faqs: Array<Faq>  =  [];
  public hospitalUid: string;
  public careModuleUid: string;
  public careModule: CareModule;
  public isLoading = false;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public hospitalService: HospitalService,
    public modalService: BsModalService,
    public careModuleService: CareModuleService,
    public faqService: FaqService,
    public generalService: GeneralService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.careModuleUid = params.careModuleUid;
      this.getFaqs();
      this.getCareModule();
    });
  }

  private getCareModule() {
    this.careModuleService.getCareModule(this.hospitalUid, this.careModuleUid).subscribe(careModule => { this.careModule = careModule})
  }

  private getFaqs(loader = true) {
    if (loader) {
      this.isLoading = true;
    }
    this.faqService.getFaqs(this.hospitalUid, this.careModuleUid).subscribe(result => {
      this.isLoading = false;
      this.faqs = result.faqs;
    })
  }

  public move(event: MouseEvent, faq: Faq, direction: number) {
    event.preventDefault();
    const copy = cloneDeep(faq);
    copy.order = Number(faq.order) + direction;
    this.faqService.updateFaq(this.hospitalUid, this.careModuleUid, copy.uid, copy).subscribe(result => {
      this.getFaqs(false);
    });
  }

  disabled(faq: Faq, direction: string) {
    if (direction == 'up' && faq == this.faqs[0])  {
      return true;
    }

    if (direction == 'down' && faq == this.faqs[this.faqs.length - 1]) {
      return true;
    }

    return false;
  }

  public addFaq(event: MouseEvent) {
    const initialState = {
      hospitalUid: this.hospitalUid,
      careModuleUid: this.careModuleUid
    };

    const modalref  = this.modalService.show(AddFaqModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref.content.onFaqCreated.subscribe((faq: any) => {
      this.getFaqs();
    });
  }

  public editFaq(event: MouseEvent, faq: Faq) {
    event.preventDefault();
    const initialState = {
      hospitalUid: this.hospitalUid,
      careModuleUid: this.careModuleUid,
      faq: faq
    };

    const modalref  = this.modalService.show(EditFaqModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref.content.onFaqUpdated.subscribe((faq: any) => {
      this.getFaqs();
    });
  }

  public removeFaq(event: MouseEvent, faq: Faq) {
    event.preventDefault();

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.remove_faq.title'),
          description: this.translateService.instant('modals.remove_faq.description'),
          yes: this.translateService.instant('modals.remove_faq.yes'),
          data: faq
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.content.showYesLoading = true;
      const faq = modalRef.content.data;
      this.faqService.removeFaq(this.hospitalUid, this.careModuleUid, faq.uid).subscribe(result => {
        this.getFaqs();
        modalRef.hide();
      }) 
    });
  }
}
