import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Application } from '../../models/application';
import { ApplicationService } from '../../services/application.service';
import { ConsentService } from '../../services/consent.service';

@Component({
  selector: 'app-add-application-consent',
  templateUrl: './add-application-consent.component.html',
  styleUrls: ['./add-application-consent.component.scss']
})
export class AddApplicationConsentComponent implements OnInit {
  @Output() onApplicationUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  
  public isSaving: boolean = false;
  public isLoading: boolean = true;
  public validationVisible: boolean = false;

  public consentOptions: Array<{value: string, label: string}> = [];

  public application: Application;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    private consentService: ConsentService,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      consent_key: new UntypedFormControl('', [Validators.required])
    });

    // TODO: smart load all consents
    this.consentService.getPlatformConsents(0, 9999).subscribe((results) => {
      this.isLoading = false;
      this.consentOptions = results.consents.map((item) => {
        return {
          value: item.key,
          label: item.key
        }
      });
    });
  }

  public onHandleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const key = this.form.get('consent_key')?.value;

    this.application.consents.push(key);

    this.applicationService.update(this.application).subscribe(() => {
      this.onApplicationUpdated.emit();
      this.isSaving = false;
      this.bsModalRef.hide();
    }, error => console.debug('error', error));
  }

  public onHandleClose() {
    this.bsModalRef.hide();
  }
}
