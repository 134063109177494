import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddEducationalMaterialModalComponent } from "../../../modals/add-educational-material-modal/add-educational-material-modal.component";
import { ConfirmModalComponent } from "../../../modals/confirm-modal/confirm-modal.component";
import { EditEducationalMaterialModalComponent } from "../../../modals/edit-educational-material-modal/edit-educational-material-modal.component";
import { CareModule } from "../../../models/care-module";
import { EducationalMaterial } from "../../../models/educational-material";
import { EducationalMaterialPhase } from "../../../models/educational-material-phase";
import { Phase } from "../../../models/phase";
import { CareModuleService } from "../../../services/care-module.service";
import { EducationalMaterialService } from "../../../services/educational-material.service";
import { GeneralService } from "../../../services/general.service";
import { PathwayTemplateService } from "../../../services/pathway-template-service.service";
import { PhaseTranslationService } from "../../../services/phase-translation.service";

@Component({
  selector: 'app-care-module-learning-materials',
  templateUrl: './care-module-learning-materials.component.html'
})
export class CareModuleLearningMaterialsComponent implements OnInit {
  public activePhase: Phase | undefined;
  public activeEducationalMaterialPhase: EducationalMaterialPhase | undefined;
  public phases: Array<Phase> = [];
  public educationalMaterialPhases: Array<EducationalMaterialPhase> = [];
  public hospitalUid: string;
  public careModuleUid: string;
  public careModule: CareModule;
  public selectedPhase: Phase | undefined = undefined;
  public breadcrumbs: Array<Phase> = [];
  public isLoading: boolean = true;
  private phaseFound: boolean = false;

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    public careModuleService: CareModuleService,
    public pathwayTemplateService: PathwayTemplateService,
    public modalService: BsModalService,
    public phaseTranslationService: PhaseTranslationService,
    private educationalMaterialService: EducationalMaterialService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.careModuleUid = params.careModuleUid;
      this.getCareModule();
    });
  }

  private getCareModule() {
    this.careModuleService.getCareModule(this.hospitalUid, this.careModuleUid).subscribe(careModule => {
      this.careModule = careModule;
      this.educationalMaterialPhases = careModule.educational_material_phases;
      this.getPhases();
    });
  }

  private getPhases() {
    this.isLoading = true;
    this.pathwayTemplateService.getVersionDetails(this.careModule.pathway_template_uid, this.careModule.pathway_template_version).subscribe(result => {

      if (result.phases?.length) {
        this.phaseTranslationService.loadAllTranslations(result.phases[0].name.region).subscribe(() => {
          this.phases = result.phases;

          this.mapMaterialsToPhase();
          this.translatePhases();

          if (this.activePhase) {
            this.phaseFound = false;
            this.setActivePhase(this.phases, this.activePhase.id);
          }

          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  setActivePhase(phases: Phase[], activePhaseId: string) {
    let activePhase = phases.find((phase) => phase.id === this.activePhase?.id);

    if (!this.phaseFound && !activePhase) {
      for (let i = 0; i < phases.length; i++) {
        if (phases[i].sub_phase_definitions.length > 0) {
          this.setActivePhase(phases[i].sub_phase_definitions, activePhaseId);
        }
      }
    }

    if (activePhase) {
      this.phaseFound = true;
      this.activePhase = activePhase;
      this.activeEducationalMaterialPhase = this.getActiveEducationalMaterialPhase(this.activePhase);
      this.phases = this.activePhase.sub_phase_definitions;
      this.translatePhases();
    }
  }

  onPhaseClicked(event: MouseEvent, phase?: Phase) {
    event.preventDefault();

    if (phase) {
      this.activePhase = phase;
      this.activeEducationalMaterialPhase = this.getActiveEducationalMaterialPhase(this.activePhase);
      this.phases = phase.sub_phase_definitions;
      this.mapMaterialsToPhase();
      this.translatePhases();

      this.resetBreadCrumbs();
    } else {
      this.activePhase = undefined;
      this.breadcrumbs = [];
      this.getPhases();
    }
  }

  resetBreadCrumbs() {
    const breadcrumbs = [];

    breadcrumbs.push(this.activePhase);

    if (this.activePhase && this.activePhase.parent) {
      breadcrumbs.push(this.activePhase.parent);

      let lastParent = this.activePhase.parent;

      while (lastParent.parent) {
        lastParent = lastParent.parent;
        breadcrumbs.push(lastParent);
      }
    }

    // @ts-ignore
    this.breadcrumbs = breadcrumbs.reverse();
  }

  editEducationalMaterial(event: Event, educationalMaterial: EducationalMaterial) {
    event.preventDefault();

    const modalRef = this.modalService.show(EditEducationalMaterialModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          educationalMaterial,
          educationalMaterialPhase: this.activeEducationalMaterialPhase,
          hospitalUid: this.hospitalUid,
          careModuleUid: this.careModule.uid
        }
      })
    );

    modalRef.content.onEducationalMaterialUpdated.subscribe(() => this.getCareModule());
  }

  addEducationalMaterial(event: Event) {
    event.preventDefault();

    const modalRef = this.modalService.show(AddEducationalMaterialModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModuleUid: this.careModule.uid,
          phase: this.activePhase,
          educationalMaterialPhase: this.activeEducationalMaterialPhase
        }
      })
    );

    modalRef.content.onEducationalMaterialCreated.subscribe(() => this.getCareModule());
  }

  removeEducationalMaterial(eduMat: EducationalMaterial, event: Event) {
    event.preventDefault();
    if (!this.activePhase?.careModuleEducationalMaterialsId) {
      return
    }

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.remove_material.title'),
          description: this.translateService.instant('modals.remove_material.description'),
          yes: this.translateService.instant('modals.remove_material.yes')
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.content.showYesLoading = true;
      if (this.activePhase?.careModuleEducationalMaterialsId) {
        this.educationalMaterialService.removeEducationalMaterialFromPhase(this.hospitalUid, this.careModuleUid, this.activePhase?.careModuleEducationalMaterialsId, eduMat.id).subscribe(result => {
          this.getCareModule();
          modalRef.hide();
        }, (error) => modalRef.hide());
      }
    });
  }

  move(material: EducationalMaterial, direction: -1 | 1) {
    if (!this.activePhase) return;

    material.order = material.order + direction;

    if (!this.activeEducationalMaterialPhase?.phase) return;

    const data = {
      phase: this.activeEducationalMaterialPhase?.phase,
      educational_materials: [
        material,
        ...this.activePhase.educational_materials.filter((item) => item.id !== material.id)
      ]
    };

    this.isLoading = true;

    this.educationalMaterialService.updateEducationalMaterial(this.hospitalUid, this.careModuleUid, this.activeEducationalMaterialPhase?.id, data)
      .subscribe(
        {
          next: () => this.getCareModule(),
          complete: () => this.isLoading = false
        }
      );
  }

  disabled(material: any, direction: 'up' | 'down') {
    const materials = this.activePhase?.educational_materials;

    if (!materials) return false;

    if (direction == 'up' && material == materials[0]) {
      return true;
    }

    if (direction == 'down' && material == materials[materials.length - 1]) {
      return true;
    }

    return false;
  }

  private mapMaterialsToPhase() {
    this.careModule.educational_material_phases.forEach(em => {
      this.connect(this.phases, em);
    });
  }

  private connect(phases: Array<Phase>, em: EducationalMaterialPhase) {
    phases.forEach((phase: Phase) => {
      if (em.mappingKey == phase.mappingKey) {
        phase.careModuleEducationalMaterialsId = em.id;
        phase.educational_materials = em.educational_materials.sort((a: EducationalMaterial, b: EducationalMaterial) => {
          return a.order - b.order;
        });
      } else {
        if (phase.sub_phase_definitions && phase.sub_phase_definitions.length > 0) {
          this.connect(phase.sub_phase_definitions, em);
        }
      }
    });
  }

  private translatePhases() {
    this.phases.forEach((phase: Phase) => phase.translatedName = this.phaseTranslationService.getTranslation(phase.name.key));
  }

  private getActiveEducationalMaterialPhase(phase: Phase): EducationalMaterialPhase | undefined {
    const educationalMaterialPhase = this.educationalMaterialPhases.find((educationalMaterialPhase: EducationalMaterialPhase) => {
      return educationalMaterialPhase.mappingKey === phase.mappingKey;
    });
    
    if(educationalMaterialPhase?.educational_materials?.length) {
      this.phaseTranslationService.getMaterialTranslations(educationalMaterialPhase.educational_materials).subscribe(materials => {
        educationalMaterialPhase.educational_materials = materials;
      });  
    }

    return educationalMaterialPhase;
  }
}
