<div class="modal-body" *ngIf="step === 'details'">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_contact_details.title' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <!-- Email -->
    <div class="form-group styled floating-label">
      <input type="email" id="email" class="form-control w-100" [placeholder]="('form.labels.email' | translate)" formControlName="email">
      <label for="email" class="required">{{ 'form.labels.email' | translate }}</label>
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>

    <!-- Phone -->
    <div class="row">
      <div class="col-5">
        <div class="form-group">
          <app-select 
            [placeholder]="('form.labels.country_code' | translate)" 
            [clearable]="false"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"
    
            formControlName="country_code"
    
            [asFilter]="false" 
            [showCheckboxes]="false"
          ></app-select>
        </div>
      </div>
      <div class="col-7">
        <div class="form-group styled floating-label">
          <input type="tel" id="inputPhone" class="form-control w-100" [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
          <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
          <p class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.phone_is_wrong">{{ 'form.feedback.phone_wrong_format' | translate }}</p>
          <p class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-body" *ngIf="step === 'code'">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_personal_details.title' | translate }}</h2>
  <p>{{ 'modals.edit_personal_details.code_description' | translate }}</p>

  <form [formGroup]="codeForm" [ngClass]="{'validation-visible': validationVisible}">
    <!-- Code -->
    <div class="form-group styled floating-label">
      <input type="text" id="code" class="form-control w-100" [placeholder]="('form.labels.code' | translate)" formControlName="code">
      <label for="code" class="required">{{ 'form.labels.code' | translate }}</label>
      <app-feedback-field [field]="form.get('code')"></app-feedback-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-personal-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="edit-personal-modal-confirm" [ngClass]="{'loader': isSaving}" (click)="handleConfirm()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
