export class PasswordPolicy {
  public mfa: string;
  public use_lowercase: string;
  public use_uppercase: string;
  public use_numbers: string;
  public use_specials: string;
  public min_length: number;
  public max_length: number;
  public history: boolean;
  public history_size: number;
  public expires: boolean;
  public expires_in_days: number;
  public expiry_notification_in_days: number;

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.mfa = item.mfa;
    this.use_lowercase = item.use_lowercase;
    this.use_uppercase = item.use_uppercase;
    this.use_numbers = item.use_numbers;
    this.use_specials = item.use_specials;
    this.min_length = item.min_length;
    this.max_length = item.max_length; 
    this.history = item.history;
    this.history_size = item.history_size;
    this.expires = item.expires;
    this.expires_in_days = item.expires_in_days;
    this.expiry_notification_in_days = item.expiry_notification_in_days;
  }
}
