import { Component, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddHospitalModalComponent } from "../../../../../src/app/modals/add-hospital-modal/add-hospital-modal.component";
import { Hospital } from "../../../../../src/app/models/hospital";
import { GeneralService } from "../../../../../src/app/services/general.service";
import { HospitalService } from "../../../../../src/app/services/hospital.service";
import { CountryService } from "../../../services/country.service";

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
})
export class HospitalsComponent implements OnInit {
  public hospitals: Array<Hospital>  =  [];

  public isLoading: boolean;

  public filters: any;
  public filtersAreSet: boolean;
  public sorting = 'name,asc';
  public sortDirectionAsc: boolean;
  public hospitalsUidSearch = new Subject<string>();
  public hospitalsNameSearch = new Subject<string>();
  public hospitalsCitySearch = new Subject<string>();

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  constructor(
    public hospitalService: HospitalService,
    public generalService: GeneralService,
    public modalService: BsModalService,
    private countryService: CountryService
  ) {
    this.setSearchBoxes();
  }

  ngOnInit() {
    this.clearFilters();
    this.getHospitals().subscribe(() => {});
  }

  setSearchBoxes () {
    this.hospitalsUidSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.uid = value;
        this.onFilterChange();
      });
    
    this.hospitalsNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.name = value;
        this.onFilterChange();
      });

    this.hospitalsCitySearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.city = value;
        this.onFilterChange();
      });
  }

  getHospitals(showLoading = true) {
    if (showLoading) {
      this.isLoading = true;
      this.hospitals = [];
      this.totalPages = 0;
      this.totalElements = undefined;
    }

    return new Observable(observer => {
      this.hospitals = [];
      this.hospitalService.getHospitals(this.filters, this.sorting, this.currentPage - 1, this.currentPageSize).subscribe(result => {
        this.isLoading = false;

        this.hospitals = result.hospitals;

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements
        }

        observer.next();
        observer.complete();
      }, () => {
        this.isLoading = false;
      });
    });
  }

  onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getHospitals().subscribe(() => {});
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getHospitals().subscribe(() => {});
    }
  }

  onChangeSortDirectionAsc(event: MouseEvent, label: string , isAsc: boolean) {
    event.preventDefault();
    this.sortDirectionAsc = isAsc;
    this.setSortDirectionOnFilter(label);
    this.getHospitals().subscribe(() => {});
  }

  setSortDirectionOnFilter(label = 'last_name') {
    if (this.sortDirectionAsc) {
      this.sorting = `${label},asc`;
    } else {
      this.sorting = `${label},desc`;
    }
  }

  actionResetFilters() {
    this.clearFilters();
    this.getHospitals().subscribe(() => {});
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.currentPage = 1;
    this.getHospitals().subscribe(() => {});
  }

  clearFilters() {
    this.filtersAreSet = false;

    this.sortDirectionAsc = true;

    this.filters = {
      uid: null,
      name: null,
      city: null
    };
  }

  newHospital(event:MouseEvent) {
    event.preventDefault();
    this.modalService.show(AddHospitalModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  public getCountryLabel(key: string) : Observable<string> {
    return this.countryService.getCountryLabelForKey(key);
  }
}
