import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { EducationalMaterial } from "../models/educational-material";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class EducationalMaterialService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public createEducationalMaterial(hospitalUid: string, careModuleUid: string, data: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/educational-materials`;
      this.authenticatedPost(url, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public getEducationalMaterial(hospitalUid: string, careModuleUid: string, educationalMaterialId: string): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/educational-materials/${educationalMaterialId}`;
      this.authenticatedGet(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, (error) => observer.error(error))
    });
  }

  public updateEducationalMaterial(hospitalUid: string, careModuleUid: string, educationalMaterialUid: string, data: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/educational-materials/${educationalMaterialUid}`;
      this.authenticatedPut(url, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public removeEducationalMaterialFromPhase(hospitalUid: string, careModuleUid: string, careModuleEducationalMaterialId: string, educationalMaterialId: string) {
    return new Observable(observer => {
      this.getEducationalMaterial(hospitalUid, careModuleUid, careModuleEducationalMaterialId).subscribe(result => {
        if (result?.educational_materials) {
          let data = result;
          delete data.id;
          data.educational_materials = data.educational_materials.filter((mat: any) => mat.id !== educationalMaterialId);

          this.updateEducationalMaterial(hospitalUid, careModuleUid, careModuleEducationalMaterialId, data).subscribe(result => {
            observer.next(result);
            observer.complete();
          }, (error) => observer.error(error));
        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }
}
