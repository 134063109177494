export class ModuleFeaturePermission {
  accessible_by_patient: boolean
  accessible_by_hcp_medical: boolean
  accessible_by_hcp_non_medical: boolean
  accessible_by_cc_medical: boolean
  accessible_by_cc_non_medical: boolean

  constructor(input?: any) {
    if (input) {
      this.accessible_by_patient = input.accessible_by_patient;
      this.accessible_by_hcp_medical = input.accessible_by_hcp_medical;
      this.accessible_by_hcp_non_medical = input.accessible_by_hcp_non_medical;
      this.accessible_by_cc_medical = input.accessible_by_cc_medical;
      this.accessible_by_cc_non_medical = input.accessible_by_cc_non_medical;
    }
  }
  
  static get default(): ModuleFeaturePermission {
    return new ModuleFeaturePermission({
      accessible_by_patient: true,
      accessible_by_hcp_medical: true,
      accessible_by_hcp_non_medical: true,
      accessible_by_cc_medical: true,
      accessible_by_cc_non_medical: true
    })
  }
  
  static get defaultCC(): ModuleFeaturePermission {
    return new ModuleFeaturePermission({
      accessible_by_patient: false,
      accessible_by_hcp_medical: false,
      accessible_by_hcp_non_medical: false,
      accessible_by_cc_medical: true,
      accessible_by_cc_non_medical: true
    })
  }
  
  static get defaultHcp(): ModuleFeaturePermission {
    return new ModuleFeaturePermission({
      accessible_by_patient: false,
      accessible_by_hcp_medical: true,
      accessible_by_hcp_non_medical: true,
      accessible_by_cc_medical: true,
      accessible_by_cc_non_medical: true
    })
  }
}
