import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { HomeFlowService } from '../../../services/home-flow.service';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private authenticationService: AuthenticationService,
    private homeFlowService: HomeFlowService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const queryParams: Params = this.route.snapshot.queryParams;
      let code = queryParams['code'] || '';
      this.loginService.onboardingLogin(code).subscribe((res) => {
        this.router.navigate([this.homeFlowService.getNextRouteByRole()]);
      });
    });
  }
}
