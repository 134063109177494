import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialCode } from '../../models/dial-code';
import { Profile } from '../../models/profile';
import { GeneralService } from '../../services/general.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-edit-contact-information',
  templateUrl: './edit-contact-information.component.html',
  styleUrls: ['./edit-contact-information.component.scss']
})
export class EditContactInformationComponent implements OnInit {
  @Output() onInformationUpdated = new EventEmitter<void>();

  public form: any;
  public codeForm: any;
  public profile?: Profile;
  public isSaving: boolean = false;
  public validationVisible: boolean = false;
  public step: "details" | "code" = 'details';
  public dialCodeOptions: Array<DialCode> = [];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    public userService: UserService,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.dialCodeOptions = this.generalService.dialCodes;

    this.form = this.formBuilder.group({
      email: new UntypedFormControl(this.profile?.email, [Validators.required]),
      country_code: [this.profile?.mobile_number?.code],
      phone: [this.profile?.mobile_number?.number],
    });

    this.codeForm = this.formBuilder.group({
      code: new UntypedFormControl('', [Validators.required]),
    });
  }

  handleConfirm() {
    if (this.step === 'details') {
      this.handleSubmitDetails();
    }

    if (this.step === 'code') {
      this.handleSubmitCode();
    }
  }

  private handleSubmitDetails() {
    if (!this.profile) return;

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;

    this.isSaving = true;

    this.profile.mobile_number = {
      code: this.form.get('country_code').value,
      number: this.form.get('phone').value
    };
 
    this.userService.updateProfile(this.profile).subscribe(() => {
      if (this.profile?.email !== this.form.get('email').value) {
        this.isSaving = false;
        this.userService.changeEmail(this.form.get('email').value).subscribe(() => {
          this.step = "code";
        });
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  private handleSubmitCode() {
    if (!this.codeForm.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;

    this.isSaving = true;
    this.userService.verifyChangeEmailCode(this.codeForm.get('code').value).subscribe(() => {
      this.isSaving = false;
      this.onInformationUpdated.emit();
      this.bsModalRef.hide();
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }
}
