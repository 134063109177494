import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlatformConsent } from '../../models/platform-consent';
import { ConsentService } from '../../services/consent.service';
import { LanguageService } from '../../services/language.service';
import { LanguageCode } from '../../models/language-code';

@Component({
  selector: 'app-edit-platform-consent-modal',
  templateUrl: './edit-platform-consent-modal.component.html',
  styleUrls: ['./edit-platform-consent-modal.component.scss']
})
export class EditPlatformConsentModalComponent implements OnInit {
  @Output() onConsentUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public consent: PlatformConsent = new PlatformConsent();
  public showRolesValidationMessage = false;
  public languages: Array<LanguageCode> = [];
  public languageTranslations: any;
  public categories = [{
    label: "Contact",
    value: "CONTACT"
  }, {
    label: "Analytics",
    value: "ANALYTICS"
  }, {
    label: "Other",
    value: "OTHER"
  }];
  public selectedLanguages: Array<string> = [];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    public consentService: ConsentService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageService.getLanguageCodes().subscribe((languages) => this.languages = languages);
    this.languageService.getLanguagesFromCMS().subscribe((result) => this.languageTranslations = result);

    this.selectedLanguages = this.consent.languages;

    this.form = this.formBuilder.group({
      key: [this.consent.key, [Validators.required]],
      category: [this.consent.category, [Validators.required]],
      order: [this.consent.order, [Validators.required]],
      enabled: [String(this.consent.enabled ?? false), [Validators.required]],
      increaseVersion: [false, []]
    });
  }

  onHandleSubmit(): void {  
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }
    
    if (this.selectedLanguages.length === 0) {
      this.showRolesValidationMessage = true;
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    let version = this.consent.version;

    if (this.form.value.increaseVersion) {
      version++;
    }

    const data = {
      key: this.form.value.key,
      category: this.form.value.category,
      languages: this.selectedLanguages,
      required_for_correlation_id: false,
      order: this.form.value.order,
      enabled: this.form.value.enabled,
      version
    };

    this.consentService.updatePlatformConsent(data).subscribe(() => this.onUpdateSuccess(), 
      (error) => this.isSaving = false);
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public onLanguageCheckChanged(event: any, locale: string) {
    if(event.target.checked) {
      this.selectedLanguages.push(locale);
    } else {
      this.selectedLanguages = this.selectedLanguages.filter((item) => item !== locale);
    }
  }

  private onUpdateSuccess() {
    this.toastrService.info(this.translate.instant('modals.add_platform_consent.created'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onConsentUpdated.emit();

    this.onHandleClose();
  }

  public getLanguage(language: LanguageCode) {

    return this.languageTranslations[language.code] ?? language.key;
  }
}
