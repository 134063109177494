<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part mb-0">
      <app-flow-welcome></app-flow-welcome>
      
      <div class="environment-indicator-nagative">
        <app-environment-indicator [readOnly]="true"></app-environment-indicator>
      </div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': validationVisible}">
      <h2>{{ 'pages.flow.forgot_password.title' | translate }}</h2>

      <p>{{ 'pages.flow.forgot_password.description' | translate }}</p>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" class="form-group styled floating-label mb-1"
        [ngClass]="{'validation-visible': validationVisible}">
        <input id="input_forgot_password_email_address" type="email" class="form-control w-100"
          [placeholder]="('form.labels.email_address'|translate)" formControlName="email">
        <label id="label_forgot_password_email_address" for="input_forgot_password_email_address">{{
          'form.labels.email_address' | translate }}</label>
        <p class="validation-feedback"
          *ngIf="form?.controls?.email?.errors?.email || form?.controls?.email?.errors?.required">{{
          'pages.flow.forgot_password.email_invalid' | translate }}</p>
      </form>

      <button id="button_forgot_password_submit" type="submit" class="btn btn-sm btn-secondary mt-2 mt-lg-3"
        [ngClass]="{'loader': isLoading}" (click)="formSubmit()">
        <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
        <span>{{ 'pages.flow.forgot_password.submit' | translate }}</span>
      </button>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background"></div>
