import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileLocale } from '../models/user-profile-locale';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public localeService: LocaleService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  login(username: string, password: string): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/token";
      const params = {
        username: username,
        password: password,
        grant_type: 'password'
      }

      this.basicAuthPost(url, params).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        this.syncOauthLocaleWhenPossible().subscribe(() => {
          observer.next(result);
          observer.complete();
        }, () => {
          observer.error();
        });
      }, error => {
        observer.error(error);
      })
    });
  }

  refreshToken(): Observable<any> {
    return new Observable(observer => {
      const refresh_token = this.authenticationService.getRefreshToken();
      const url = this.platformUrl + '/oauth/token';
      const params = {
        refresh_token,
        grant_type: 'refresh_token'
      };

      this.basicAuthPost(url, params).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  onboardingLogin(code: string) {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/token";
      const params = {
        'code': code,
        'grant_type': 'authorization_code'
      }

      this.basicAuthPost(url, params).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        this.syncOauthLocaleWhenPossible().subscribe(() => {
          observer.next(result);
          observer.complete();
        }, () => {
          observer.error();
        });
      }, error => {
        observer.error(error);
      })
    });
  }

  syncOauthLocaleWhenPossible() {
    if (!this.authenticationService.hasFinalRole()) {
      return this.getOauthLocale();
    } else {
      return new Observable(observer => {
        observer.next();
        observer.complete();
      });
    }
  }

  getOauthLocale() {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/locale";
      this.authenticatedGet(url).subscribe(result => {
        const locale = new UserProfileLocale(result);
        this.localeService.setLocalePreferences(locale);
        observer.next(locale);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  }
}
