import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    if(req.url.includes('eu-cdn.contentstack.com')) {
      return next.handle(req);
    }

    let clonedRequest = req.clone({});

    // TEMP FIX! DEMO AND QA CAN NOT HANDLE THIS YET. SO THEY ARE NULL IN .ENV
    
    if(environment.app_version) {
      clonedRequest = req.clone({
        headers: clonedRequest.headers
          .append('X-App-Version', environment.app_version)
      });
    }
    

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
