import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { LogoutModalComponent } from "../../modals/logout-modal/logout-modal.component";
import { AuthenticationService } from "../../services/authentication.service";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarOpen: boolean;
  public backdropVisible: boolean;
  public backdropShown: boolean;
  public activeState: string;

  public subnavCollapsedState: any = {
    pathways: true
  }

  constructor(
    public router: Router,
    public modalService: BsModalService,
    public generalService: GeneralService,
    private authenticationService: AuthenticationService
  ) { }

  openSidebarHandler(event: MouseEvent) {
    event.preventDefault();

    this.sidebarOpen = true;
    this.backdropVisible = true;
    setTimeout(() => this.backdropShown = true);
  }

  closeSidebarHandler(event: MouseEvent) {
    event.preventDefault();
    this.backdropShown = false;
    this.sidebarOpen = false;
    setTimeout(() => {
      this.backdropVisible = false;
    }, 150);
  }

  toggleSubnav(event: MouseEvent, item: string) {
    event.preventDefault();

    if (this.sidebarOpen) {
      this.subnavCollapsedState[item] = !this.subnavCollapsedState[item];
    } else {
      this.openSidebarHandler(event);
      this.subnavCollapsedState[item] = false;
    }
  }

  isSubnavCollapsed(item: string): boolean {
    return this.subnavCollapsedState[item];
  }

  showLogoutModal(event: MouseEvent) {
    event.preventDefault();

    this.modalService.show(LogoutModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  };

  defineActiveState() {
    const url = this.router.url;

    if(url.indexOf('/hospitals') === 0 || url.indexOf('/hospital/') === 0) {
      this.activeState = 'hospitals';
    } 
  }

  hasRole(role: string) {
    let roles = this.authenticationService.getCurrentRoles();

    return roles.includes(role);
  }
}
