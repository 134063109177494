import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareCoordinator } from '../../models/care-coordinator';
import { CareCoordinatorService } from '../../services/care-coordinator.service';

@Component({
  selector: 'app-edit-other-email-modal',
  templateUrl: './edit-other-email-modal.component.html'
})
export class EditOtherEmailModalComponent implements OnInit {
  public isSaving: boolean = false;
  public validationVisible: boolean = false;

  public cc: CareCoordinator | undefined;
  public hospitalUid: string;
  public form: FormGroup;

  public reasonMinLength = 1;
  public reasonMaxLength = 50;

  @Output() public emailChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public careCoordinatorService: CareCoordinatorService,
    public toastService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.formSetup()
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = new FormGroup({
      new_email: new FormControl('', [
        Validators.required,
        Validators.email]),
      reason: new FormControl('', [
        Validators.required,
        Validators.minLength(this.reasonMinLength),
        Validators.maxLength(this.reasonMaxLength)
      ]),
    });
  }

  handleClose() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;
    this.validationVisible = false;
    
    /*
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    NOT TESTED YET. BLOCKED BY PF ERROR
    */
   
    this.careCoordinatorService.changeEmail(
      this.hospitalUid,
      this.cc!.uid,
      {
        new_email: this.form.get('new_email')?.value,
        reason: this.form.get('reason')?.value
      }
    ).subscribe(() => {
      this.submitSuccessHandler();
    }, () => {
      this.submitErrorHandler()
    })

  }

  submitSuccessHandler() {
    this.isSaving = false;

    this.toastService.info(
      this.translate.instant('modals.edit_other_email.change_email_success_notification_description'),
      this.translate.instant('modals.edit_other_email.change_email_success_notification'),
      {
        disableTimeOut: false,
        timeOut: 4000
      });

    this.emailChanged.emit(true);
  }

  submitErrorHandler() {
    this.isSaving = false;
    this.validationVisible = true;
  }
}
