import { PathwayTemplateVersion } from "./pathway-template-version";
import { Phase } from "./phase";
import { TemplateGroup } from "./template-group";

export interface PathwayTemplateVersionHistory {
  minor_version: string,
  reason: string
}

export class PathwayTemplate {
  public uid: string;
  public description: string;
  public is_sub_process: boolean;
  public name: string;
  public phases: Array<Phase> = [];
  public version: number;
  public group: TemplateGroup;

  public major_version: number
  public minor_version: number
  public reason: string
  public created_at: string

  public version_history: PathwayTemplateVersionHistory[]
  public versions: PathwayTemplateVersion[];

  // Used for template error validation
  public file: string;

  constructor(data?: any) {
    if (data) {
      this.mapFromJson(data);
    }
  }

  private mapFromJson(data: any) {
    this.uid = data.uid;
    this.description = data.description;
    this.name = data.name;
    this.phases = data.phases;
    this.version = data.version;

    this.phases = [];
    if (data.phases && data.phases.length > 0) {
      data.phases.forEach((item: any) => {
        this.phases.push(new Phase(item));
      });
    }
    this.is_sub_process = data.is_sub_process;
    this.major_version = data.major_version;
    this.minor_version = data.minor_version;
    this.reason = data.reason;
    this.created_at = data.created_at;
    this.version_history = data.version_history;
    this.versions = data.versions;

    if (data?.group) {
      this.group = new TemplateGroup(data.group)
    }
  }

  get getLatestMajorVersion(): number | null {
    if (!this.versions?.length) {
      return null
    }

    const versions = this.versions.map((version: PathwayTemplateVersion) => version.major_version);

    return Math.max(...versions);
  }
}
