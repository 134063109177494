import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HospitalService } from "../../../app/services/hospital.service";
import { Application } from "../../models/application";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: 'app-add-application-to-hospital-modal',
  templateUrl: './add-application-to-hospital-modal.component.html'
})
export class AddApplicationToHospitalModalComponent implements OnInit {
  @Output() onAppsAdded = new EventEmitter<void>();
  
  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospitalUid: string;

  public apps: Application[] = [];
  public appSearchResults: Application[] = [];
  public selectedApp: Application | null;
  public isSearching = false;
  public linkedApplications: Application[] = [];

  public searchAppListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public applicationService: ApplicationService
  ) { }
  
  ngOnInit(): void {
    this.formSetup();

    this.searchAppListEvent.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(result => {
      this.search(result?.term);
    });
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      searchOn: new FormControl('name', [])
    });
  }

  public onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }
    this.validationVisible = false;
    this.isSaving = true;

    const requests: any[] = [];
    this.apps.forEach(app => {
      requests.push(this.hospitalService.addApplication(app.uid, this.hospitalUid));
    });

    forkJoin(requests).subscribe(() => {
      this.onAppsAdded.emit();
      this.showSuccessToast();
      this.onHandleClose();
      this.isSaving = false;
    }, (error) => {
      this.isSaving = false;
    });
  }

  public onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.add_application.success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  public onAddApp(app: any): void {
    if (app) {
      const index = this.apps.indexOf(app);
      if (index < 0) {
        this.apps.push(app);
      }

      setTimeout(() => {
        this.selectedApp = null;
      }, 0);
    }
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  search(term: string): void {
    this.isSearching = true;

    this.applicationService.search(this.form.controls.searchOn.value, term).subscribe((apps: Application[]) => {
      apps = apps.filter(app => {
        return this.linkedApplications.filter(linkedApp => {
          return linkedApp.uid === app.uid;
        }).length === 0;
      });

      this.appSearchResults = apps; 
      this.isSearching = false;
    });
  }

  public isAppSelected(app: Application): boolean {
    const apps = this.apps.map(a => {
      return a.uid;
    });

    return apps.filter(uid => {
      return uid === app.uid;
    }).length > 0;
  }

  public onRemoveApp(app: Application): void {
    const index = this.apps.indexOf(app);
    if (index > -1) {
      this.apps.splice(index, 1);
    }
  }
}
