import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../src/environments/environment";
import { LanguageCode } from "../models/language-code";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends ApiService {
  
  // Returns same languages as getPlatformSupportedLanguages. But available for every user.
  public getLanguageCodes(): Observable<Array<LanguageCode>> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/language-codes`;

      this.basicAuthGet(url, 2).subscribe(langCodes => {
        observer.next(this.mapLanguageCodes(langCodes));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
  
  // Returns all languages known by PF. (Only use to edit supported-language-codes)
  public getPlatformLanguageCodes(): Observable<Array<LanguageCode>> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/platform/language-codes`;

      this.authenticatedGet(url).subscribe(langCodes => {
        observer.next(this.mapLanguageCodes(langCodes));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPlatformSupportedLanguages(): Observable<Array<LanguageCode>> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/platform/supported-language-codes`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapLanguageCodes(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }  
  
  addPlatformSupportedLanguageCodes(languages: string[]): Observable<void> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/platform/supported-language-codes`;

      this.authenticatedPost(url, {languages}).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
  
  removePlatformSupportedLanguageCode(languageCode: string): Observable<void> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/platform/supported-language-codes/${languageCode}`;

      this.authenticatedDelete(url).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public mapLanguageCodes(languageCodesObject: any): Array<LanguageCode> {
    const languageCodes = new Array();
    
    for (const key in languageCodesObject) {
      languageCodes.push(new LanguageCode({key, ...languageCodesObject[key]}));
    }
    
    return languageCodes;
  }

  getLanguagesFromCMS(): Observable<any> {
    return new Observable(observer => {
      const httpOptions = {
        headers: new HttpHeaders({
          api_key: environment.cmsApiKey,
          access_token: environment.cmsAccessToken
        }),
        params: {
          environment: environment.cmsEnvironment,
          locale: 'en'
        }
      };

      const url = environment.cmsUrl + '/v3/content_types/cross_app_shared_labels/entries';
      this.http.get(url, httpOptions).subscribe((result: any) => {
        if (result.entries) {
          observer.next(result.entries[0].languages);
        } else {
          observer.next({});
        }
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getLanguageFromLanguageCode(key: string): Observable<string> {
    return new Observable(observer => {
      this.getLanguagesFromCMS().subscribe((languages) => {

        if (languages[key]) {
          observer.next(languages[key]);
        } else {
          observer.next(key);
        }

        observer.complete();
      }, (error: any) => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
