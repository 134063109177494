import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CmsService extends ApiService {
  public getAllTranslationsByRegion(region: string): Observable<Array<{key: string, value: string}>> {
    return new Observable(observer => {
      const url = `${environment.cmsUrl}/v3/content_types/${region}/entries`;
      let translations: Array<{key: string, value: string}> = [];

      this.cmsGet(url).subscribe( result => {
        console.debug('First', result);
        result.entries.forEach((item: any) => {
          translations.push({
            key: item.title,
            value: item.value
          })
        });

        if (result.count > 100) {
          let pages = Math.ceil(result.count / 100);
          
          let promises = [];
          for (let i = 1; i < pages; i++) {
            promises.push(this.cmsGet(`${url}?skip=${i*100}`));
          }

          const joined = forkJoin(promises);

          joined.subscribe((results) => {
            results.forEach((resultPage: any) => {
              resultPage.entries.forEach((item: any) => {
                translations.push({
                  key: item.title,
                  value: item.value
                })
              });
            });

            observer.next(translations);
          });
        } else {
          observer.next(translations);
        }
      });
    });
  }
}
