<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.account_settings.overview.title' | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="body-content">
  <div class="container">
    <!-- Start: personal information -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span *ngIf="profile === undefined || profile?.profile_picture === undefined" class="icon" [inlineSVG]="'/assets/svg/person.svg'"></span>
            <div *ngIf="profile !== undefined && profile?.profile_picture !== undefined">
              <span class="avatar" [style.backgroundImage]="'url(' + (profile.profile_picture.url | secure | async) + ')'" [ngClass]="{'empty': false}"></span>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.account_settings.overview.personal_information' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.first_name' | translate }}</label>
                <p id="first_name">{{profile.first_name}}</p>
              </div>

              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.last_name' | translate }}</label>
                <p id="last_name">{{profile.last_name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="" (click)="editPersonalInformation($event)">
        <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: personal information -->

    <!-- Start: contact information -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'/assets/svg/mail.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.account_settings.overview.contact_information' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.email_address' | translate }}</label>
                <p id="first_name">{{profile.email}}</p>
              </div>

              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.phone' | translate }}</label>
                <p id="phone" *ngIf="profile !== undefined && profile.mobile_number !== undefined && profile.mobile_number.code !== undefined">
                  ({{ generalService.getDialCodeForCountry(profile.mobile_number.code)}}) {{ profile.mobile_number.number }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="" (click)="editContactInformation($event)">
        <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: contact information -->

    <!-- Start: preferences -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'/assets/svg/settings.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.account_settings.overview.preferences' | translate }}</h3>
              </div>
            </div>
            <div class="row" *ngIf="profile !== undefined && profile.locale !== undefined">
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.number_format' | translate }}</label>
                <p id="first_name">{{profile.locale.number_format}}</p>
              </div>
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.date_format' | translate }}</label>
                <p id="first_name">{{profile.locale.date_format}}</p>
              </div>
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.time_zone' | translate }}</label>
                <p id="first_name">{{profile.locale.time_zone}}</p>
              </div>
              <div class="col-4">
                <label class="tiny m-0">{{ 'form.labels.time_format' | translate }}</label>
                <p id="time_24_hours" *ngIf="profile.locale.time_24_hours">24 Hours</p>
                <p id="time_24_hours" *ngIf="!profile.locale.time_24_hours">12 Hours</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="" (click)="editPreferences($event)">
        <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: preferences -->

    <!-- Start: Password -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'/assets/svg/password.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3 class="mb-1">{{ 'pages.default.account_settings.overview.password' | translate }}</h3>
              </div>
            </div>
            <div class="row" *ngIf="profile !== undefined && profile.locale !== undefined">
              <div class="col-4">
                <p id="first_name" class="value text-muted">{{ 'pages.default.account_settings.overview.password_description' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="" (click)="editPassword($event)" id="edit-password">
        <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: Password -->
  </div>
</div>
