import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Faq } from '../../models/faq';
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'app-edit-faq-modal',
  templateUrl: './edit-faq-modal.component.html'
})
export class EditFaqModalComponent implements OnInit {
  @Output() onFaqUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public faq:Faq;
  public hospitalUid: string;
  public careModuleUid: string;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public faqService: FaqService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      order: [this.faq.order, [Validators.required]],
      faq: new UntypedFormGroup({
        region: new UntypedFormControl('faq', Validators.required),
        key: new UntypedFormControl(this.faq.faq.key, Validators.required)
      })
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }
    this.validationVisible = false;
    this.isSaving = true;

    this.faqService.updateFaq(this.hospitalUid, this.careModuleUid, this.faq.uid, this.form.value).subscribe(() => this.onUpdateSuccess(), 
      (error) => this.isSaving = false);
  }

  onUpdateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.edit_faq.updated'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onFaqUpdated.emit();
    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
