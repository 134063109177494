export interface PathwayTemplateError {
  component_id: string,
  component_type: string,
  validation_rule: string,
  type: ErrorType;
  reason: string,
  reason_key: string,
}

export enum ErrorType {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export class PathwayTemplateErrorResponse {
  public error_id: string;
  public status: string;
  public key: string;
  public description: string;
  public major_version: number

  public results: PathwayTemplateError[];

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.error_id = item.error_id;
    this.status = item.status;
    this.key = item.key;
    this.description = item.description;
    this.major_version = item.major_version;

    this.results = item.results
  }
  
  filterResults(type: ErrorType): PathwayTemplateError[] {
    const errors: PathwayTemplateError[] = [];

    this.results?.forEach( (result: PathwayTemplateError) => {
      if(result.type === type) {
        errors.push(result);
      }
    });
    
    return errors;
  }
  
  get errors(): PathwayTemplateError[] {
    return this.filterResults(ErrorType.ERROR);
  }
  
  get warnings(): PathwayTemplateError[] {
    return this.filterResults(ErrorType.WARNING);
  }
}
