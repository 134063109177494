import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { Hospital } from "../../models/hospital";
import { GeneralService } from "../../services/general.service";
import { HospitalService } from "../../services/hospital.service";

@Component({
  selector: 'app-edit-hospital-password-policy-modal',
  templateUrl: './edit-hospital-password-policy-modal.component.html'
})
export class EditHospitalPasswordPolicyModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;

  public options: any[];
  public booleanList: any[];

  public pwLengthValidatorMin: number = 8;
  public pwLengthValidatorMax: number = 68;
  public historySizeValidatorMin: number = 5;
  public historySizeValidatorMax: number = 10;
  public expiresInDaysValidatorMin: number = 0;
  public expiresInDaysValidatorMax: number = 365;
  public expiryNotificationInDaysValidatorMin: number = 7;
  public expiryNotificationInDaysValidatorMax: number = 30;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.generalService.getOptionList(),
      this.generalService.getBooleanList()
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.options = data[0];
    this.booleanList = data[1];
  };

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      security_configuration: new UntypedFormGroup({
        password_policy: new UntypedFormGroup({
          mfa: new UntypedFormControl(this.hospital.security_configuration.password_policy.mfa, Validators.required),
          use_lowercase: new UntypedFormControl(this.hospital.security_configuration.password_policy.use_lowercase, Validators.required),
          use_uppercase: new UntypedFormControl(this.hospital.security_configuration.password_policy.use_uppercase, Validators.required),
          use_numbers: new UntypedFormControl(this.hospital.security_configuration.password_policy.use_numbers, Validators.required),
          use_specials: new UntypedFormControl(this.hospital.security_configuration.password_policy.use_specials, Validators.required),
          min_length: new UntypedFormControl(this.hospital.security_configuration.password_policy.min_length, [Validators.required, Validators.min(this.pwLengthValidatorMin), Validators.max(this.pwLengthValidatorMax)]),
          max_length: new UntypedFormControl(this.hospital.security_configuration.password_policy.max_length, [Validators.required, Validators.min(this.pwLengthValidatorMin), Validators.max(this.pwLengthValidatorMax)]),
          history: new UntypedFormControl(this.hospital.security_configuration.password_policy.history ? 'true' : 'false', Validators.required),
          history_size: new UntypedFormControl(this.hospital.security_configuration.password_policy.history_size, [Validators.required, Validators.min(this.historySizeValidatorMin), Validators.max(this.historySizeValidatorMax)]),
          expires: new UntypedFormControl(this.hospital.security_configuration.password_policy.expires ? 'true' : 'false', Validators.required),
          expires_in_days: new UntypedFormControl(this.hospital.security_configuration.password_policy.expires_in_days ?? this.expiresInDaysValidatorMin, [Validators.required, Validators.min(this.expiresInDaysValidatorMin), Validators.max(this.expiresInDaysValidatorMax)]),
          expiry_notification_in_days: new UntypedFormControl(this.hospital.security_configuration.password_policy.expiry_notification_in_days ?? this.expiryNotificationInDaysValidatorMin, [Validators.required, Validators.min(this.expiryNotificationInDaysValidatorMin), Validators.max(this.expiryNotificationInDaysValidatorMax)]),
        })
      })
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);
    const password_policy = this.hospital.security_configuration.password_policy;
    password_policy.mfa = this.form.get('security_configuration.password_policy.mfa')?.value;
    password_policy.use_lowercase = this.form.get('security_configuration.password_policy.use_lowercase')?.value;
    password_policy.use_uppercase = this.form.get('security_configuration.password_policy.use_uppercase')?.value;
    password_policy.use_numbers = this.form.get('security_configuration.password_policy.use_numbers')?.value;
    password_policy.use_specials = this.form.get('security_configuration.password_policy.use_specials')?.value;
    password_policy.min_length = this.form.get('security_configuration.password_policy.min_length')?.value;
    password_policy.max_length = this.form.get('security_configuration.password_policy.max_length')?.value;
    password_policy.history = this.form.get('security_configuration.password_policy.history')?.value;
    password_policy.history_size = this.form.get('security_configuration.password_policy.history_size')?.value;
    password_policy.expires = this.form.get('security_configuration.password_policy.expires')?.value;
    password_policy.expires_in_days = this.form.get('security_configuration.password_policy.expires_in_days')?.value;
    password_policy.expiry_notification_in_days = this.form.get('security_configuration.password_policy.expiry_notification_in_days')?.value;

    clonedHospital.security_configuration.password_policy = password_policy;

    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
