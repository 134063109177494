export interface Phone {
  description: string;
  disclaimer: string;
  number: string;
}

export class Country {
  public title: string;
  public description: string;
  public code: string;
  public phones: Phone[];
  public label: string;
  public value: string;

  constructor(item? :any) {
    if (item) {
      this.title = item.title;
      this.description = item.description;
      this.code = item.code;
      this.phones = item.phone;
      this.label = item.title;
      this.value = item.code;
    }
  }
}
