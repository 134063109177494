import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PathwayTemplate } from '../../models/pathway-template';
import { TemplateGroup } from '../../models/template-group';
import { GeneralService } from '../../services/general.service';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { PathwayTemplateFilter, SubProcessFilterType } from '../../interfaces/pathway-template-filter.interface';

@Component({
  selector: 'app-template-group-detail',
  templateUrl: './template-group-detail.component.html'
})
export class TemplateGroupDetailComponent implements OnInit {
  @Input() group: TemplateGroup;

  @Input() filter: PathwayTemplateFilter;
  @Output() filterChange = new EventEmitter<PathwayTemplateFilter>();

  @Output() groupSelected = new EventEmitter<TemplateGroup>();
  @Output() templateSelected = new EventEmitter<PathwayTemplate>();
  @Output() addNewTemplate = new EventEmitter<TemplateGroup>();
  @Output() updateScope = new EventEmitter<TemplateGroup>(); // this triggers new api request in parent

  public templates: Array<PathwayTemplate>;

  public filterUpdate = new Subject<{ key: string, value: string }>();
  public processTypes = [
    { label: 'All', value: SubProcessFilterType.INCLUDE },
    { label: 'Sub processes only', value: SubProcessFilterType.ONLY },
    { label: 'Processes only', value: SubProcessFilterType.EXCLUDE },
  ];
  public isLoading: boolean = false;
  public parentGroupName = TemplateGroup.PARENT_NAME

  public get selectedProcessType(): string {
    const types = this.processTypes.filter(t => t.value === this.filter?.sub_process_filter_type);
    return (types.length > 0) ? types[0].label : 'Processes';
  }

  constructor(
    public pathwayTemplateService: PathwayTemplateService,
    public generalService: GeneralService,
    public modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.filterUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(event => {
        this.updateScope.emit(this.group);
      });
  }

  ngOnDestroy() {
    this.filterUpdate.complete();
  }

  selectGroup(group: TemplateGroup) {
    this.groupSelected.emit(group);
  }

  selectTemplate(template: PathwayTemplate) {
    this.templateSelected.emit(template);
  }

  onProcessChange(value: SubProcessFilterType) {
    this.filter.sub_process_filter_type = value;
    this.updateScope.emit(this.group);
  }

  resetFilters() {
    this.filter = {
      group: this.group.path,
      name: '',
      sub_process_filter_type: SubProcessFilterType.INCLUDE,
      upload_date: ''
    };

    this.filterChange.emit(this.filter);
    this.updateScope.emit(this.group);
  }

  hasFiltersSet(): boolean {
    if (this.filter.name !== '' || this.filter.sub_process_filter_type !== '' || this.filter.upload_date !== '') {
      return true;
    }

    return false;
  }

  newTemplate() {
    this.addNewTemplate.emit(this.group);
  }
}
