import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import PaginationInterface from "../interfaces/pagination.interface";
import { HospitalConsent } from "../models/hospital-consent";
import { PlatformConsent } from "../models/platform-consent";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class ConsentService extends ApiService {
  private platformUrl: string;
  
  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public getPlatformConsents(page: number, size: number): Observable<{consents: Array<PlatformConsent>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/platform/consents?${paramsString}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const consents = this.mapPlatformConsents(result.items);
        observer.next({
          consents: consents,
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public getPlatformConsent(uid: string): Observable<PlatformConsent> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/platform/consents/${uid}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospital = this.mapPlatformConsent(result);
        observer.next(hospital);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public getHospitalConsents(hospitalUid: string, page: number, size: number): Observable<{consents: Array<HospitalConsent>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/consents?${paramsString}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const consents = this.mapConsents(result.items);
        observer.next({
          consents: consents,
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public addHospitalConsent(hospitalUid: string, consent: HospitalConsent) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/consents`;
      this.authenticatedPost(url, consent).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public updateHospitalConsent(hospitalUid: string, key: string, consent: HospitalConsent) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/consents/${key}`;
      this.authenticatedPut(url, consent).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public updatePlatformConsent(consent: PlatformConsent) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/platform/consents/${consent.key}`;
      this.authenticatedPut(url, consent).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public createPlatformConsent(data: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/platform/consents`;
      this.authenticatedPost(url, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  private mapConsents(list: any): Array<HospitalConsent> {
    const consents = new Array();
    if(!list || !Array.isArray(list)) {
      return [];
    }
    list.forEach(item => consents.push(new HospitalConsent(item)));
    return consents;
  }

  private mapPlatformConsents(list: any): Array<PlatformConsent> {
    const consents = new Array();
    if(!list || !Array.isArray(list)) {
      return [];
    }
    list.forEach(item => consents.push(this.mapPlatformConsent(item)));
    return consents;
  }

  private mapPlatformConsent(data: any) {
    return new PlatformConsent(data);
  }
}
