export class UserProfileLocale  {
  public language: string;
  public measurement_unit: string;
  public number_format: string;
  public date_format: string;
  public time_24_hours: boolean;
  public first_day_of_week: string;
  public notification_channels: string[];
  public time_zone: string;
  
  constructor(item?: any) {
    if(!item) {
      return
    }

    this.language = item.language;
    this.measurement_unit = item.measurement_unit;
    this.number_format = item.number_format;
    this.date_format = item.date_format;
    this.time_24_hours = item.time_24_hours;
    this.first_day_of_week = item.first_day_of_week;
    this.notification_channels = item.notification_channels;
    this.time_zone = item.time_zone;
   }
}
