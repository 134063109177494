import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddPlatformConsentModalComponent } from '../../../modals/add-platform-consent-modal/add-platform-consent-modal.component';
import { EditPlatformConsentModalComponent } from '../../../modals/edit-platform-consent-modal/edit-platform-consent-modal.component';
import { PlatformConsent } from '../../../models/platform-consent';
import { ConsentService } from '../../../services/consent.service';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-consents-overview',
  templateUrl: './consents-overview.component.html',
  styleUrls: ['./consents-overview.component.scss']
})
export class ConsentsOverviewComponent implements OnInit {
  public consents: Array<PlatformConsent> = [];
  public isLoading: boolean = true;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 1000;

  constructor(
    public consentService: ConsentService, 
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.getConsents();
  }

  public disabled(consent: PlatformConsent, direction: string) {
    if (direction == 'up' && consent == this.consents[0])  {
      return true;
    }

    if (direction == 'down' && consent == this.consents[this.consents.length - 1]) {
      return true;
    }

    return false;
  }

  public move(event: MouseEvent, consent: PlatformConsent, direction: number) {
    event.preventDefault();
    const copy = cloneDeep(consent);
    copy.order = Number(consent.order) + direction;

    this.consentService.updatePlatformConsent(copy).subscribe(result => {
      this.getConsents();
    });
  }

  public onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getConsents();
    }
  }

  public onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getConsents();
    }
  }

  public addConsent(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddPlatformConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );

    modalRef.content.onConsentCreated.subscribe(() => this.getConsents());
  }

  public editConsent(event: MouseEvent, consent: PlatformConsent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditPlatformConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          consent
        }
      })
    );

    modalRef.content.onConsentUpdated.subscribe(() => this.getConsents());
  }

  private getConsents() {
    this.consentService.getPlatformConsents(this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.consents = result.consents.sort((a, b) => a.order - b.order);
      this.isLoading = false;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }
    });
  }
}
