import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../..//services/general.service';
import { AddHospitalConsentComponent } from '../../../modals/add-hospital-consent/add-hospital-consent.component';
import { EditHospitalConsentModalComponent } from '../../../modals/edit-hospital-consent-modal/edit-hospital-consent-modal.component';
import { Hospital } from '../../../models/hospital';
import { HospitalConsent } from '../../../models/hospital-consent';
import { ConsentService } from '../../../services/consent.service';
import { HospitalService } from '../../../services/hospital.service';

@Component({
  selector: 'app-hospital-consents',
  templateUrl: './hospital-consents.component.html',
  styleUrls: []
})
export class HospitalConsentsComponent implements OnInit {
  public consents: Array<HospitalConsent> = [];
  public isLoading: boolean = true;
  public hospital: Hospital| undefined;
  public hospitalUid: string;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  constructor(
    private hospitalService: HospitalService, 
    private activatedRoute: ActivatedRoute,
    private consentService: ConsentService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.getHospital();
      this.getConsents();
    });
  }

  public disabled(consent: HospitalConsent, direction: string) {
    if (direction == 'up' && consent == this.consents[0])  {
      return true;
    }

    if (direction == 'down' && consent == this.consents[this.consents.length - 1]) {
      return true;
    }

    return false;
  }

  public move(event: MouseEvent, consent: HospitalConsent, direction: number) {
    event.preventDefault();
    const copy = cloneDeep(consent);
    copy.order = Number(consent.order) + direction;

    this.consentService.updateHospitalConsent(this.hospitalUid, copy.key, copy).subscribe(result => {
      this.getConsents();
    });
  }

  public onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getConsents();
    }
  }

  public onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getConsents();
    }
  }

  public editConsent(event: MouseEvent, consent: HospitalConsent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditHospitalConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          consent,
          hospitalUid: this.hospitalUid
        }
      })
    );

    modalRef.content.onConsentUpdated.subscribe(() => this.getConsents());
  }

  public addConsent(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddHospitalConsentComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid
        }
      })
    );

    modalRef.content.onConsentUpdated.subscribe(() => this.getConsents());
  }

  private getHospital() {
    this.hospitalService.getHospital(this.hospitalUid).subscribe(hospital => {
      this.hospital = hospital;
    });
  }

  private getConsents() {
    this.consentService.getHospitalConsents(this.hospitalUid, this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.consents = result.consents.sort((a, b) => a.order - b.order);

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }
      
      this.isLoading = false;
    });
  }
}
