export class LanguageCode {
  public key: string; // 'EN' or 'DA_DK'
  public code: string; // 'en' or 'da-DK'
  public description: string;
  public reading_direction: 'LEFT_TO_RIGHT' | 'RIGHT_TO_LEFT';

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.key = item.key;
    this.code = item.code;
    this.description = item.description;
    this.reading_direction = item.reading_direction;
  }

  get translationKey():string {
    return `languages.${this.key}`;
  }
  
  // legacy fallback
  get value(): string {
    console.error('language.value is legacy code. Switch to key');
    return this.key;
  }
  
  // legacy fallback
  get locale(): string {
    console.error('language.locale is legacy code. Switch to code');
    return this.code;
  }
}
