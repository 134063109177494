import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
})
export class ToggleSwitchComponent {
  @Input() public value: boolean = false;
  @Input() public inverse: boolean = false;

  protected onChange: any = () => { };
  protected onTouched: any = () => { };

  onToggle(event: MouseEvent) {
    event.preventDefault();
    this.value = !this.value;

    if (this.inverse) {
      this.onChange(!this.value);
    } else {
      this.onChange(this.value);
    }
  }

  writeValue(value: any): void {
    if (this.inverse) {
      this.value = !value;
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
