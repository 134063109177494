import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../app/services/api.service';
import { AuthenticationService } from '../../app/services/authentication.service';
import { environment } from '../../environments/environment';
import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ApiService {
  private countries: Array<Country>;
  private loadCountriesSubsription?: Observable<void>;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService
  ) {
    super(http, authenticationService);
  }

  private loadCountries(): Observable<void> {
    if (!this.loadCountriesSubsription) {
      this.loadCountriesSubsription = new Observable(observer => {
        if (!this.countries) {
          this.getCountriesFromCms().subscribe(countries => {
            this.countries = countries;
            observer.next();
            observer.complete();
          }, error => {
            observer.error(error);
            observer.complete();
          });
        } else {
          observer.next();
          observer.complete();
        }
      });
    }

    return this.loadCountriesSubsription;
  }

  getCountryLabelForKey(key: string): Observable<string> {
    return new Observable(observer => {
      this.loadCountries().subscribe(() => {
        let label;
        for (const country of this.countries) {
          if (country.value === key) {
            label = country.label;
            break;
          }
        }
        if (!label) {
          console.warn(`No label found for country with key ${key}, using this key as fallback`);
          label = key;
        }
        observer.next(label);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getCountriesFromCms(): Observable<Country[]> {
    const url = environment.cmsUrl + '/v3/content_types/countries/entries';

    return new Observable<Country[]>(observer => {
      this.cmsGet(url).subscribe((result: any) => {
        if (result['entries']) {
          observer.next(result['entries'].map( (country: any) => new Country(country)));
          observer.complete();
        } else {
          observer.next();
          observer.complete();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
