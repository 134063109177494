<div class="modal-body">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_preferences.title' | translate }}</h2>

  <form [formGroup]="unitForm" [ngClass]="{'validation-visible': validationVisible}">
    <div class="mt-3 mb-5">
      <div class="form-group">
        <app-select
          id="preference-number-format"
          [placeholder]="('form.labels.number_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="numberFormats"
          [bindValue]="'value'"
          formControlName="number_format"
          (change)="handleUnitFormSubmission()"
        ></app-select>
      </div>

      <div class="form-group">
        <app-select
          id="preference-date-format"
          [placeholder]="('form.labels.date_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="dateFormats"
          [bindValue]="'value'"
          formControlName="date_format"
          (change)="handleUnitFormSubmission()"
        ></app-select>
      </div>

      <div class="form-group">
        <app-select
          id="preference-time-zone"
          [placeholder]="('form.labels.time_zone' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeZones"
          [bindValue]="'value'"
          formControlName="time_zone"
          (change)="handleUnitFormSubmission()"
        ></app-select>
      </div>

      <div class="form-group">
        <app-select
          id="preference-time-24-hours"
          [placeholder]="('form.labels.time_format' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeFormats"
          [bindValue]="'value'"
          formControlName="time_24_hours"
          (change)="handleUnitFormSubmission()"
        ></app-select>
      </div>

      <div class="form-group">
        <app-select
          id="preference-first-day-of-week"
          [placeholder]="('form.labels.first_day_of_week' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="weekdays"
          [bindValue]="'value'"
          formControlName="first_day_of_week"
          (change)="handleUnitFormSubmission()"
        ></app-select>
      </div>

    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-personal-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="edit-personal-modal-confirm" [ngClass]="{'loader': isSaving}" (click)="handleUnitFormSubmission()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
