import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageService } from '../../services/language.service';
import { LanguageCode } from '../../models/language-code';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-edit-platform-supported-languages',
  templateUrl: './edit-platform-supported-languages.component.html'
})
export class EditPlatformSupportedLanguagesComponent implements OnInit {
  @Output() onLanguagesUpdated = new EventEmitter<void>();

  public platformSupportedLanguages: LanguageCode[];
  public isSaving = false;
  public selectedLanguages: LanguageCode[];
  public selectedLanguage: LanguageCode;
  public _languageOptions: LanguageCode[];
  public searchedLanguageOptions: LanguageCode[];
  public _initialLanguages: LanguageCode[];

  searchLangListEvent = new EventEmitter<{ term: string, items: any[] }>();

  public get languagesAdd(): LanguageCode[] {
    return this.selectedLanguages?.filter((lang: LanguageCode) => {
      return !this._initialLanguages?.some(language => language.key === lang.key);
    }) ?? [];
  }

  public get languagesRemove(): LanguageCode[] {
    return this._initialLanguages?.filter((lang: LanguageCode) => {
      return !this.selectedLanguages?.some(language => language.key === lang.key);
    }) ?? [];
  }

  constructor(
    public bsModalRef: BsModalRef,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.getPlatformSupportedLanguages();
    this.getPlatformLanguageCodes();

    this.searchLangListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchLangList(result?.term);
    });
  }

  getPlatformLanguageCodes() {
    this.languageService.getPlatformLanguageCodes().subscribe(languages => {
      this._languageOptions = languages;
      this.searchedLanguageOptions = languages;
    });
  }

  getPlatformSupportedLanguages() {
    this.languageService.getPlatformSupportedLanguages().subscribe(languages => {
      this._initialLanguages = cloneDeep(languages);
      this.selectedLanguages = languages;
    });
  }

  onAddLanguage(language: LanguageCode) {
    if (!this.isLangSelected(language)) {
      this.selectedLanguages.push(language);
    }
  }

  removeLanguage(language: LanguageCode) {
    this.selectedLanguages = this.selectedLanguages.filter(lang => lang.key !== language.key);
  }

  isLangSelected(language: LanguageCode): boolean {
    return this.selectedLanguages.some((lang: LanguageCode) => lang.code === language.code);
  }

  onHandleSubmit() {
    const observables = [];

    if (this.languagesAdd.length) {
      observables.push(this.languageService.addPlatformSupportedLanguageCodes(
        this.languagesAdd.map((lang: LanguageCode) => lang.key)
      ));
    }

    if (this.languagesRemove.length) {
      this.languagesRemove.forEach((lang: LanguageCode) => {
        observables.push(this.languageService.removePlatformSupportedLanguageCode(lang.key));
      });
    }

    if (observables.length) {
      this.isSaving = true;
      forkJoin(observables).subscribe(() => {
        setTimeout(() => {
          this.onLanguagesUpdated.emit();
          this.isSaving = false;
          this.bsModalRef.hide();
        }, 500);
      }, () => this.isSaving = false);
    } else {
      this.bsModalRef.hide();
    }
  }

  searchLangList(term: string) {
    this.searchedLanguageOptions = this._languageOptions.filter((lang: LanguageCode) => lang.description.toLowerCase().includes(term.toLowerCase()));
  }

  customSearchFn(term: string, item: any) {
    return true;
  }
}
