import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TemplateGroup } from '../../models/template-group';
import { GeneralService } from '../../services/general.service';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { AddFolderModalComponent } from '../add-folder-modal/add-folder-modal.component';

@Component({
  selector: 'app-select-destination-folder-modal',
  templateUrl: './select-destination-folder-modal.component.html'
})
export class SelectDestinationFolderModalComponent implements OnInit {
  @Output() onFolderSelected = new EventEmitter<String>();

  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public selectedGroup: TemplateGroup | undefined;

  public groups: TemplateGroup[];
  public loadingTemplatesFor: string = '';

  constructor(
    public bsModalRef: BsModalRef,
    public pathwayTemplateService: PathwayTemplateService,
    public modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups() {
    this.isLoading = true;

    this.pathwayTemplateService.getGroups().subscribe(results => {
      this.groups = results;
      this.isLoading = false;
    });
  }

  selectGroup(group: TemplateGroup, event?: MouseEvent): void {
    event?.preventDefault();

    if (this.selectedGroup?.path === group.path) {
      this.selectedGroup = undefined;
      return;
    }

    group.isOpen = true;

    this.groups.map((gr: TemplateGroup) => {
      if (gr.name === group.name) {
        gr = group;
      }
    });

    this.selectedGroup = group;
  }

  toggleGroup(group: TemplateGroup, event?: MouseEvent): void {
    event?.preventDefault();

    if (group.isOpen) {
      group.toggleOpen();
    } else {
      this.selectGroup(group);
    }
  }

  isSelected(selected: string): boolean {
    return this.selectedGroup?.name === selected;
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  onHandleSubmit(): void {
    this.bsModalRef.hide();
    this.onFolderSelected.emit(this.selectedGroup?.path);
  }

  addNewFolder(event: MouseEvent, groupPath?: string): void {
    event.preventDefault();

    const modalRef = this.modalService.show(AddFolderModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );

    modalRef.content.onFolderCreated.subscribe((folderName: string) => {
      let newGroup = new TemplateGroup().create(folderName, this.selectedGroup);

      (this.selectedGroup) ? this.selectedGroup.groups.push(newGroup) : this.groups.push(newGroup);
      this.selectedGroup = newGroup;
    });
  }

}
