import { PathwayConfigurationParameter } from "../interfaces/pathway-configuration-parameter.interface";
import { Phase } from "./phase";

export interface minorVersion {
  minor_version: number;
  reason: string
}
export class PathwayTemplateVersion {
  public major_version: number;
  public minor_version: number;
  public reason: string;
  public version_history: minorVersion[];
  public phases: Phase[];
  public configuration_parameters: PathwayConfigurationParameter[];
  public created_at: string;
  
  public templateUid: string;
  

  constructor(data?: any) {
    if (data) {
      this.mapFromJson(data);
    }
  }

  private mapFromJson(data: any) {
    this.major_version = data.major_version;
    this.minor_version = data.minor_version;
    this.reason = data.reason;
    this.version_history = data.version_history;
    this.configuration_parameters = data.configuration_parameters;
    this.created_at = data.created_at;
    
    this.templateUid = data.temtemplateUid;
    
    this.phases = [];
    if (data.phases && data.phases.length > 0) {
      data.phases.forEach((item: any) => {
        this.phases.push(new Phase(item));
      });
    }
  }
  
  getMinorVersion(minorVersion: any): string {
    return `${this.major_version}.${minorVersion}`;
  }
}
