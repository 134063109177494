import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddCmTranslationComponent } from '../../../modals/add-cm-translation/add-cm-translation.component';
import { EditCmTranslationComponent } from '../../../modals/edit-cm-translation/edit-cm-translation.component';
import { CareModule } from '../../../models/care-module';
import { CareModuleService } from '../../../services/care-module.service';
import { GeneralService } from '../../../services/general.service';
import { PathwayTemplateService } from '../../../services/pathway-template-service.service';

@Component({
  selector: 'app-care-module-translations',
  templateUrl: './care-module-translations.component.html',
  styleUrls: ['./care-module-translations.component.scss']
})
export class CareModuleTranslationsComponent implements OnInit {
  public hospitalUid: string;
  public careModuleUid: string;
  public careModule: CareModule;
  public isLoading = true;
  public translations: Array<{
    language: string,
    name: string;
    description: string;
  }>;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public careModuleService: CareModuleService,
    public pathwayTemplateService: PathwayTemplateService,
    public modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.careModuleUid = params.careModuleUid;
      this.getCareModule();
    });
  }

  private getCareModule() {
    this.careModuleService.getCareModule(this.hospitalUid, this.careModuleUid)
      .subscribe(careModule => { 
        this.careModule = careModule;
        this.mapTranslations(careModule);
        this.isLoading = false;
      });
  }

  private mapTranslations(careModule: CareModule) {
    this.translations = [];

    careModule.name.translations.forEach((item) => {
      this.translations.push({
        language: item.language,
        name: item.translation,
        description: ''
      });
    });

    careModule.description.translations.forEach((item) => {
      const existingTranslation = this.translations.find((trans) => trans.language === item.language);
      if (existingTranslation) {
        existingTranslation.description = item.translation;
      } else {
        this.translations.push({
          language: item.language,
          name: '',
          description: item.translation
        });
      }
    });
  }

  addTranslation(event: Event) {
    event.preventDefault();

    const modalRef = this.modalService.show(AddCmTranslationComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModule: this.careModule
        }
      })
    );

    modalRef.content.onTranslationAdded.subscribe(() => {
      this.getCareModule();
    });
  }

  public editTranslation(event: MouseEvent, translation: any, index: number) {
    event.preventDefault();

    const modalRef = this.modalService.show(EditCmTranslationComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModule: this.careModule,
          translation
        }
      })
    );

    modalRef.content.onTranslationAdded.subscribe(() => {
      this.getCareModule();
    });
  }
}
