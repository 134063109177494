<div class="edit-supported-platform-languages">
  <div class="modal-header mb-0">
    <h4>{{ 'modals.edit_platform_supported_languages.platform_supported_languages' | translate }}</h4>

    <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
      (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>
  </div>

  <div class="modal-body">
    <app-page-loader *ngIf="!selectedLanguages || !searchedLanguageOptions"></app-page-loader>

    <div *ngIf="selectedLanguages && searchedLanguageOptions">
      <div class="form-group">
        <ng-select [placeholder]="('form.labels.language' | translate)" [clearable]="false" [searchable]="true"
          [hideSelected]="false" [multiple]="false" [items]="searchedLanguageOptions" [(ngModel)]="selectedLanguage"
          (change)="onAddLanguage($event)" (search)="searchLangListEvent.next($event)" [searchFn]="customSearchFn">
          <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
            <div *ngIf="item" class="wrapper d-flex justify-content-between align-items-center py-1">
              <p class="title mb-0">{{ item?.description }}</p>

              <div *ngIf="isLangSelected(item)" class="status small text-muted">
                <span>{{ 'modals.edit_platform_supported_languages.already_added' | translate }}</span>
              </div>
              <div *ngIf="!isLangSelected(item)" class="status small text-secondary">
                <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
                <span>{{ 'modals.edit_platform_supported_languages.add' | translate }}</span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="selected-languages">
        <div class="language bg-light mb-1 px-2 py-1 d-flex justify-content-between align-items-center"
          *ngFor="let lang of selectedLanguages">
          <p class="mb-0">{{lang.description}}</p>

          <button class="btn btn-link" (click)="removeLanguage(lang)">
            <span [inlineSVG]="'/assets/svg/close.svg'"></span>
            <span>{{ 'action.remove' | translate}}</span>
          </button>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <div class="m-start-auto">
      <button (click)="bsModalRef.hide()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

      <button (click)="onHandleSubmit()"
        [ngClass]="{'loader': isSaving, 'disabled': !languagesAdd.length && !languagesRemove.length}"
        class="btn btn-secondary m-start-3">
        <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'action.save' | translate }}</span>
      </button>
    </div>
  </div>
</div>
