<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospitals' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ hospital?.name }}</h1>
      <app-hospital-tabs [hospital]="hospital" [active]="'settings'"></app-hospital-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="single-item-block mt-2 mb-5" *ngIf="!hospital">
      <div class="block-wrapper p-3 w-100">
        <app-page-loader [padding]="'30px 0'"></app-page-loader>
      </div>
    </div>
    <div *ngIf="hospital">
      <!-- Start: general information -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/hospital.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.general_information' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.uid' | translate }}</label>
                  <p id="uid">{{ hospital.uid }}</p>

                  <label class="tiny m-0">{{ 'form.labels.website' | translate }}</label>
                  <p id="website">
                    <a [href]="hospital.site.url" class="text-info text-icon-link" target="_blank">
                      <span class="label">{{ hospital.site.name }}</span>
                      <span class="icon-end" [inlineSVG]="'/assets/svg/external.svg'"></span>
                    </a>
                  </p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.active' | translate }}</label>
                  <p id="active" *ngIf="hospital?.active" class="highlight">{{ 'general.yes' | translate }}</p>
                  <p id="active" *ngIf="!hospital?.active">{{ 'general.no' | translate }}</p>

                  <div *ngIf="hospital?.logo?.key">
                    <label class="tiny m-0">{{ 'form.labels.cms_ref_logo' | translate }}</label>
                    <p id="logo">{{ hospital.logo.key }}</p>
                  </div>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.name' | translate }}</label>
                  <p id="name">{{ hospital.name }}</p>

                  <div *ngIf="hospital?.metadata?.key">
                    <label class="tiny m-0">{{ 'form.labels.cms_ref_metadata' | translate }}</label>
                    <p id="metadata">{{ hospital.metadata.key }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editGeneralInformation($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: general information -->

      <!-- Start: contact -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/mail.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.contact' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.phone_number' | translate }}</label>
                  <p id="phone_number">{{ getPhoneCountryCode() }} {{ hospital.phone_number.number }}</p>
                </div>

                <div class="col-4" *ngIf="hospital?.email">
                  <label class="tiny m-0">{{ 'form.labels.email_address' | translate }}</label>
                  <p>
                    <a [href]="'mailto:' + hospital.email" class="text-info text-icon-link" target="_blank">
                      <span class="label">{{ hospital.email }}</span>
                      <span class="icon-end" [inlineSVG]="'/assets/svg/external.svg'"></span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editContact($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: contact -->

      <!-- Start: address -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/building.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.address' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.street' | translate }}</label>
                  <p id="street">{{ hospital.address.street }}</p>

                  <label class="tiny m-0">{{ 'form.labels.postal_code' | translate }}</label>
                  <p id="postal_code">{{ hospital.address.postal_code}}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.number' | translate }}</label>
                  <p id="number">{{ hospital.address.number}}</p>

                  <label class="tiny m-0">{{ 'form.labels.city' | translate }}</label>
                  <p id="city">{{ hospital.address.city}}</p>
                </div>

                <div class="col-4">
                  <div *ngIf="hospital?.address?.bus">
                    <label class="tiny m-0">{{ 'form.labels.bus' | translate }}</label>
                    <p id="bus">{{ hospital.address.bus }}</p>
                  </div>

                  <label class="tiny m-0">{{ 'form.labels.country' | translate }}</label>
                  <p id="city">{{ this.country }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editAddress($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: address -->

      <!-- Start: default pref. -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/check-square.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.default_preferences' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.language' | translate }}</label>
                  <p id="language">{{ language }}</p>

                  <label class="tiny m-0">{{ 'form.labels.date_format' | translate }}</label>
                  <p id="date_format">{{ getDateFormat() }}</p>

                  <label class="tiny m-0">{{ 'form.labels.first_day_of_week' | translate }}</label>
                  <p id="first_day_of_week">{{ hospital.default_locale.first_day_of_week | titlecase }}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.units' | translate }}</label>
                  <p id="units">{{ getMeasurementUnit() }}</p>
                  <label class="tiny m-0">{{ 'form.labels.time_zone' | translate }}</label>
                  <p id="time_zone">{{ getTimeZone() }}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.number_format' | translate }}</label>
                  <p id="number_format">{{ getNumberFormat() }}</p>

                  <label class="tiny m-0">{{ 'form.labels.time_format' | translate }}</label>
                  <p id="time_format" *ngIf="hospital?.default_locale?.time_24_hours">{{ 'form.labels.24_hours' | translate }}</p>
                  <p id="time_format" *ngIf="!hospital?.default_locale?.time_24_hours">{{ 'form.labels.12_hours' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editPreferences($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: default pref. -->

      <!-- Start: Features -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/code.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.features' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.enabled' | translate }}</label>
                  <p id="enabled_features">
                    {{ this.enabledFeatures.join(', ') }}
                    <span *ngIf="this.enabledFeatures.length === 0">-</span>
                  </p>
                </div>
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.disabled' | translate }}</label>
                  <p id="disabled_features">
                    {{ this.disabledFeatures.join(', ') }}
                    <span *ngIf="this.disabledFeatures.length === 0">-</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editFeatures($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: Features -->

      <!-- Start: registration codes -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/code.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.registration_codes' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="tiny m-0">{{ 'form.labels.hospital_code_days_valid' | translate }}</label>
                  <p id="hospital_code_days_valid">{{ hospital.general_configuration.hospital_code_days_valid_indefinite? ('pages.default.hospital.details.no_expiry_date' | translate) : hospital.general_configuration.hospital_code_days_valid }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editRegistrationCode($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: registration codes -->

      <!-- Start: password policy -->
      <div class="action-item-block mb-1">
        <div class="block-wrapper p-start-3 p-end-1 w-100" *ngIf="hospital">
          <div class="row align-items-start">
            <div class="col-auto icon-column">
              <span class="icon" [inlineSVG]="'/assets/svg/password.svg'"></span>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.hospital.details.password_policy' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.use_2_step_authentication' | translate }}</label>
                  <p id="use_2_step_authentication">{{ getOption(hospital.security_configuration.password_policy.mfa) | titlecase }}</p>
                  
                  <label class="tiny m-0">{{ 'form.labels.numbers_required' | translate }}</label>
                  <p id="numbers_required">{{ getOption(hospital.security_configuration.password_policy.use_numbers) | titlecase }}</p>
                  
                  <label class="tiny m-0">{{ 'form.labels.max_password_length' | translate }}</label>
                  <p id="max_password_length">{{ hospital.security_configuration.password_policy.max_length }}</p>
                  
                  <label class="tiny m-0">{{ 'form.labels.password_expires' | translate }}</label>
                  <p id="password_expires">{{ getBooleanLabel(hospital.security_configuration.password_policy.expires) | translate }}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.lowercase_required' | translate }}</label>
                  <p id="lowercase_required">{{ getOption(hospital.security_configuration.password_policy.use_lowercase) | titlecase }}</p>
                  
                  <label class="tiny m-0">{{ 'form.labels.symbols_required' | translate }}</label>
                  <p id="symbols_required">{{ getOption(hospital.security_configuration.password_policy.use_specials) | titlecase }}</p>
                  
                  <label class="tiny m-0">{{ 'form.labels.cant_reuse_password_from_the_past' | translate }}</label>
                  <p id="history">{{ getBooleanLabel(hospital.security_configuration.password_policy.history) | translate }}</p>

                  <label class="tiny m-0" *ngIf="hospital?.security_configuration?.password_policy?.expires_in_days">{{ 'form.labels.amount_days_expires' | translate }}</label>
                  <p id="expires_in_days">{{ hospital.security_configuration.password_policy.expires_in_days }}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.uppercase_required' | translate }}</label>
                  <p id="uppercase_required">{{ getOption(hospital.security_configuration.password_policy.use_uppercase) | titlecase }}</p>
              
                  <label class="tiny m-0">{{ 'form.labels.min_password_length' | translate }}</label>
                  <p id="min_password_length">{{ hospital.security_configuration.password_policy.min_length }}</p>

                  <label class="tiny m-0">{{ 'form.labels.amount_passwords_to_remember' | translate }}</label>
                  <p id="history_size">{{ hospital.security_configuration.password_policy.history_size }}</p>
                  
                  <label class="tiny m-0" *ngIf="hospital?.security_configuration?.password_policy?.expiry_notification_in_days">{{ 'form.labels.expiry_notification_in_days' | translate }}</label>
                  <p id="expiry_notification_in_days">{{ hospital.security_configuration.password_policy.expiry_notification_in_days }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="editPasswordPolicy($event)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
      <!-- End: password policy -->

      
              
      

      

      

      
    </div>
  </div>
</div>
