import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import PaginationInterface from '../interfaces/pagination.interface';
import { AdminUser } from '../models/admin-user';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getAdminUsers(page: number = 0, size: number = 25): Observable<{adminUsers: Array<AdminUser>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/platform/admins?${paramsString}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const adminUsers = this.mapAdminUsers(result.items);
        observer.next({
          adminUsers,
          pagination: result.pagination
        });
        observer.complete();
      });
    });
  }

  public create(values: any): Observable<any> {
    const roles = ['ROLE_PLATFORM'];

    if (values.role_org_admin) roles.push('ROLE_ORG_ADMIN');
    if (values.role_user_admin) roles.push('ROLE_USER_ADMIN');
    if (values.role_hotline) roles.push('ROLE_HOTLINE');

    const data = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      roles
    };

    return new Observable(observer => {
      this.authenticatedPost(`${this.platformUrl}/platform/admins`, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public update(adminUser: AdminUser): Observable<any> {
    const data = {
      email: adminUser.email,
      first_name: adminUser.first_name,
      last_name: adminUser.last_name,
      roles: adminUser.roles
    };
    return new Observable(observer => {
      this.authenticatedPut(`${this.platformUrl}/platform/admins/${adminUser.uid}`, data).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  public getAdminUser(uid: string) {
    return new Observable(observer => {
      this.authenticatedGet(`${this.platformUrl}/platform/admins/${uid}`).subscribe((result) => {
        observer.next();
        observer.complete();
      });
    });
  }

  private mapAdminUsers(list: any[]): Array<AdminUser> {
    const hospitals = new Array();
    list.forEach(item => {
      hospitals.push(this.mapAdminUser(item));
    });
    return hospitals;
  }

  private mapAdminUser(input: any): AdminUser {
    return new AdminUser(input);
  }
}
