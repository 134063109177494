import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService {
  public static SLUG_CROSS_APP_TRANSLATIONS: string = 'cross_app_shared_labels';
  public static SLUG_BUSINESS_ERROR_TRANSLATIONS: string = 'business_error_keys';
  public static SLUG_BUSINESS_FUNCTION_TRANSLATIONS: string = 'business_function_keys';

  public static GetAllTranslations(http, lang: string): Observable<any> {
    return new Observable(observer => {
      TranslationLoaderService.SharedTranslationLoader(http, lang).subscribe({
        next: responses => {
          const sharedTranslations = responses;

          let result: any = {};

          result.shared = {};

          Object.keys(sharedTranslations).forEach(key => {
            result.shared[key] = sharedTranslations[key];
          });

          observer.next(result);
          observer.complete();
        }, error: () => {
          observer.error();
          observer.complete();
        }
      })
    });
  }

  public static SharedTranslationLoader(http, lang: string) {
    return new Observable(observer => {

      const content_slug_shared_labels = TranslationLoaderService.SLUG_CROSS_APP_TRANSLATIONS;
      const content_slug_errors = TranslationLoaderService.SLUG_BUSINESS_ERROR_TRANSLATIONS;
      const content_slug_functions = TranslationLoaderService.SLUG_BUSINESS_FUNCTION_TRANSLATIONS;

      forkJoin([
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_shared_labels),
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_errors),
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_functions)
      ]).subscribe({
        next: responses => {
          const crossAppSharedLabels = responses[0];
          const businessErrorKeys = responses[1];
          const businessFunctionKeys = responses[2];

          const result: any = {};

          if (crossAppSharedLabels && crossAppSharedLabels.length) {
            const crossAppSharedLabels_entry = crossAppSharedLabels[0];
            result.languages = crossAppSharedLabels_entry.languages;

            Object.keys(Object.assign({}, result?.languages)).forEach(key => {
              result.languages[key.toUpperCase()] = result.languages[key];
            });
          }

          result[content_slug_errors] = {};
          if (businessErrorKeys && businessErrorKeys.length) {
            result[content_slug_errors] = TranslationLoaderService.ParseCmsKeyValueList(businessErrorKeys);
          }

          result[content_slug_functions] = {};
          if (businessFunctionKeys && businessFunctionKeys.length) {
            result[content_slug_functions] = TranslationLoaderService.ParseCmsKeyValueList(businessFunctionKeys);
          }

          observer.next(result);
          observer.complete();
        }, error: () => {
          observer.error();
          observer.complete();
        }
      });
    });
  }

  public static GetAllEntries(http, lang: string, type: string, skip: number = 0, allEntries?: Array<any>, passedObserver?: any): Observable<Array<any>> {
    if (!allEntries) {
      allEntries = [];
    }

    const contentHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'api_key': environment.cmsApiKey,
      'access_token': environment.cmsAccessToken
    });

    return new Observable(observer => {
      const finalObserver = passedObserver || observer;

      const url = `${environment.cmsUrl}/v3/content_types/${type}/entries?environment=${environment.cmsEnvironment}&locale=${lang.toLowerCase()}&include_fallback=true&include_count=true&skip=${skip}`;

      http.get(url, { headers: contentHeader }).subscribe(response => {

        allEntries = allEntries.concat(response['entries']);

        const count = response['count'];
        const length = response['entries']?.length;


        if (count > length + skip) {
          TranslationLoaderService.GetAllEntries(http, lang, type, skip + 100, allEntries, finalObserver).subscribe(() => { });
        } else {
          finalObserver.next(allEntries);
          finalObserver.complete();
        }

      }, () => {
        finalObserver.next(allEntries);
        finalObserver.complete();
      });
    });
  }

  public static ParseCmsKeyValueList(entries: Array<any>): any {
    const parsed: any = {};

    entries.forEach(entry => {
      const slug = entry['title'];
      const value = entry['value'];

      if (slug && value) {
        parsed[slug] = value;
      }
    });

    return parsed;
  }
}
