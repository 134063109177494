import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TemplateGroup } from "../../models/template-group";
import { GeneralService } from "../../services/general.service";
import { PathwayTemplateService } from "../../services/pathway-template-service.service";
import { SelectDestinationFolderModalComponent } from "../select-destination-folder-modal/select-destination-folder-modal.component";
import { AddPathwayTemplateVersionModalComponent } from "../add-pathway-template-version-modal/add-pathway-template-version-modal.component";
import { PathwayTemplate } from "../../models/pathway-template";

@Component({
  selector: 'pathway-template-modal',
  templateUrl: './pathway-template-modal.component.html'
})

export class PathwayTemplateModalComponent implements OnInit {
  @Input() group: TemplateGroup;
  @Input() template: PathwayTemplate;

  @Output() onSubmitDone = new EventEmitter<void>();

  public form: FormGroup;
  public validationVisible = false;
  public isSavingWithParameters = false;
  public isSavingWithoutParameters = false;

  public get groupLabel(): string {
    return this.form.get('group')?.value.split('/').pop();
  }

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public templateService: PathwayTemplateService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      name: new FormControl(this.template?.name, [Validators.required, Validators.min(1), Validators.max(50)]),
      description: new FormControl(this.template?.description, [Validators.required, Validators.min(1), Validators.max(50)]),
      is_sub_process: new FormControl(this.template?.is_sub_process, [Validators.required]),
      group: new FormControl(null)
    });

    if (this.group) {
      this.form.get('group')?.setValue(this.group.path);
      this.form.updateValueAndValidity();
    }
  }

  openMajorVersionModal(pathwayTemplate: PathwayTemplate): void {
    const modalRef = this.modalService.show(AddPathwayTemplateVersionModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xxl',
        initialState: {
          pathwayTemplate
        }
      })
    );

    modalRef.content.onMajorVersionCreated.subscribe(() => {
      this.onSubmitDone.emit();
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(msg: string): void {
    this.toastrService.info(this.translate.instant(msg), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  public openFolderSelect(event?: MouseEvent): void {
    event?.preventDefault();

    const modalRef = this.modalService.show(SelectDestinationFolderModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );

    modalRef.content.onFolderSelected.subscribe((group: string) => {
      this.form.get('group')?.setValue(group);
      this.form.updateValueAndValidity();
    });
  }

  onHandleSubmitNewTemplate(openMajorVersionModal: boolean = false): void {
    if (this.isSavingWithParameters || this.isSavingWithoutParameters) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;

    if (openMajorVersionModal) {
      this.isSavingWithParameters = true;
    } else {
      this.isSavingWithoutParameters = true;
    }


    this.templateService.create(this.form.value).subscribe((pathwayTemplate: PathwayTemplate) => {
      this.showSuccessToast('modals.pathway_template.add_success_notification');

      if (openMajorVersionModal) {
        this.openMajorVersionModal(pathwayTemplate);
      } else {
        this.onSubmitDone.emit();
      }

      this.onHandleClose();

    }, () => {
      this.isSavingWithParameters = false;
      this.isSavingWithoutParameters = false;
    });
  }

  onHandleSubmitEditTemplate() {
    if (this.form.pristine) {
      this.onHandleClose();
    }

    if (this.isSavingWithoutParameters) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSavingWithoutParameters = true;
    
    this.templateService.updateTemplate(this.form.value, this.template.uid).subscribe(() => {
      this.onSubmitDone.emit();
      this.showSuccessToast('modals.pathway_template.edit_success_notification');
      this.onHandleClose();
    }, () => {
      this.isSavingWithoutParameters = false;
    });
  }
}
