import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class HomeFlowService {
  public static FlowLogin = 'LOGIN';

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService
  ) { }

  goNextStepOrDone(overrideFlow?: string): Observable<any> {
    const queryParams:any = Object.assign({}, this.route.snapshot.queryParams);
    const flow = overrideFlow?.toUpperCase();
    const nextRoute: string = this.getNextRouteByRole(flow);

    if(nextRoute) {
      return new Observable(observer => {
        this.router.navigate([nextRoute], {
          queryParams: queryParams
        });
        observer.next(null);
        observer.complete();
      });
    } else {
      return this.completeFlow(queryParams);
    }
  };

  completeFlow(queryParams: any): Observable<any> {
    return new Observable(observer => {
      this.finaliseFlow().subscribe(() => {
        observer.next(null);
        observer.complete();
      });
    });
  };

  getNextRouteByRole(flow?: string) {
    let role: string = this.authenticationService.getCurrentFirstRole();
    let nextRoute = '';

    if(role) {
      role = role.toUpperCase();

      if(role === AuthenticationService.RoleMfaRequired) {
        nextRoute = '/security-code';
      }

      if(role === AuthenticationService.RoleConsentRequired) {
        nextRoute = '/onboarding/consent';
      }

      if(role === AuthenticationService.RolePwChangeRequired) {
        nextRoute = '/password-expired'
      }
    }

    return nextRoute;
  }

  fetchRequiredContexts() {
    return new Observable(observer => {
      this.userService.getProfile().subscribe(() => {
        observer.next();
        observer.complete();
      }, () => {
        observer.error();
      });
    });
  }

  finaliseFlow(): Observable<any> {
    return new Observable(observer => {
      this.fetchRequiredContexts().subscribe(() => {
        this.navigateAfterFinalisation();
        observer.next(null);
        observer.complete();
      });
    });
  }

  navigateAfterFinalisation() {
    const params = this.route.snapshot.queryParams;
    if(params && params.returnUrl) {
      this.router.navigateByUrl(params.returnUrl);
    } else {
      this.router.navigateByUrl('hospitals');
    }
  }
}