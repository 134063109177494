import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Application } from '../../models/application';
import { PlatformConsent } from '../../models/platform-consent';
import { ApplicationService } from '../../services/application.service';
import { ConsentService } from '../../services/consent.service';

@Component({
  selector: 'app-edit-application-consent',
  templateUrl: './edit-application-consent.component.html',
  styleUrls: ['./edit-application-consent.component.scss']
})
export class EditApplicationConsentComponent implements OnInit {
  @Output() onApplicationUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  
  public isSaving: boolean = false;
  public isLoading: boolean = true;
  public validationVisible: boolean = false;

  public consentOptions: Array<{value: string, label: string}> = [];

  public application: Application;
  public consent: PlatformConsent;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public consentService: ConsentService,
    public applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      consent_key: new UntypedFormControl(this.consent?.key, [Validators.required])
    });

    // TODO: smart load all consents
    this.consentService.getPlatformConsents(0, 9999).subscribe((results) => {
      this.isLoading = false;
      this.consentOptions = results.consents.map((item) => {
        return {
          value: item.key,
          label: item.key
        }
      });
    });
  }

  public onHandleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }
    
    this.isSaving = true;

    const key = this.form.get('consent_key')?.value;

    let consents = this.application.consents.filter((item) => item !== this.consent.key);
    consents.push(key);
    this.application.consents = consents;

    this.applicationService.update(this.application).subscribe(() => {
      this.onApplicationUpdated.emit();
      this.bsModalRef.hide();
    }, error => console.debug('error', error));
  }

  public onHandleClose() {
    this.bsModalRef.hide();
  }
}
