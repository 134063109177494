import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { PlatformAdminGuard } from './helpers/platform-admin.guard';
import { UserAdminGuard } from './helpers/user-admin.guard';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { AccountSettingsComponent } from './pages/default/account-settings/account-settings.component';
import { AdminUsersComponent } from './pages/default/admin-users/admin-users.component';
import { ApplicationConsentsComponent } from './pages/default/application-consents/application-consents.component';
import { ApplicationsComponent } from './pages/default/applications/applications.component';
import { CareModuleFaqsComponent } from './pages/default/care-module-faqs/care-module-faqs.component';
import { CareModuleLearningMaterialsComponent } from './pages/default/care-module-learning-materials/care-module-learning-materials.component';
import { CareModuleSettingsComponent } from './pages/default/care-module-settings/care-module-settings.component';
import { CareModuleTranslationsComponent } from './pages/default/care-module-translations/care-module-translations.component';
import { ConsentsOverviewComponent } from './pages/default/consents-overview/consents-overview.component';
import { HospitalApplicationsComponent } from './pages/default/hospital-applications/hospital-applications.component';
import { HospitalCareCoordinatorsComponent } from './pages/default/hospital-care-coordinators/hospital-care-coordinators.component';
import { HospitalCareModulesComponent } from './pages/default/hospital-care-modules/hospital-care-modules.component';
import { HospitalConsentsComponent } from './pages/default/hospital-consents/hospital-consents.component';
import { HospitalComponent } from './pages/default/hospital/hospital.component';
import { HospitalsComponent } from './pages/default/hospitals/hospitals.component';
import { PathwayTemplatesComponent } from './pages/default/pathway-templates/pathway-templates.component';
import { GDPRAdminComponent } from './pages/default/gdpr-admin/gdpr-admin.component';
import { CamundaCockpitLinksComponent } from './pages/default/camunda-cockpit-links/camunda-cockpit-links.component';
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import { MfaCodeComponent } from './pages/flow/mfa-code/mfa-code.component';
import { OnboardingComponent } from './pages/flow/onboarding/onboarding.component';
import { PlatformConfigurationComponent } from './pages/platform-configuration/platform-configuration.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';

export const routes: Routes = [
  {
    path: '',
    component: FlowLayoutComponent,
    children: [
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      { path: 'security-code', component: MfaCodeComponent, canActivate: [AuthGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'password-reset', component: ForgotPasswordResetComponent },
      { path: 'onboarding', component: OnboardingComponent },
      { path: 'password-expired', component: ExpiredPasswordResetComponent, canActivate: [AuthGuard] },
      { path: 'maintenance', component: MaintenanceComponent },
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: 'hospitals', component: HospitalsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid', component: HospitalComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/applications', component: HospitalApplicationsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_modules', component: HospitalCareModulesComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_modules/:careModuleUid/settings', component: CareModuleSettingsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_modules/:careModuleUid/translations', component: CareModuleTranslationsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_modules/:careModuleUid/faqs', component: CareModuleFaqsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_modules/:careModuleUid/learning-materials', component: CareModuleLearningMaterialsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/care_coordinators', component: HospitalCareCoordinatorsComponent, canActivate: [AuthGuard] },
      { path: 'hospitals/:hospitalUid/consents', component: HospitalConsentsComponent, canActivate: [AuthGuard] },
      { path: 'admin-users', component: AdminUsersComponent, canActivate: [AuthGuard, UserAdminGuard]},
      { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'applications/:applicationUid/consents', component: ApplicationConsentsComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'pathway-templates', component: PathwayTemplatesComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'platform-configuration', component: PlatformConfigurationComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'gdpr-admin', component: GDPRAdminComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'camunda-cockpit-links', component: CamundaCockpitLinksComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'platform-consents', component: ConsentsOverviewComponent, canActivate: [AuthGuard, PlatformAdminGuard]},
      { path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard] }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
