import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Base64 } from 'js-base64';
import { ConfigurationsParameterType, PathwayConfigurationParameter } from '../../interfaces/pathway-configuration-parameter.interface';
import { PathwayTemplate } from '../../models/pathway-template';
import { PathwayTemplateErrorResponse } from '../../models/pathway-template-error-response';
import { PathwayTemplateVersion } from '../../models/pathway-template-version';
import { GeneralService } from '../../services/general.service';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { ConfigurationParameterComponent } from '../configuration-parameter/configuration-parameter.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { PathwayTemplateErrorModalComponent } from '../pathway-template-error-modal/pathway-template-error-modal.component';

@Component({
  selector: 'app-add-pathway-template-version-modal',
  templateUrl: './add-pathway-template-version-modal.component.html'
})
export class AddPathwayTemplateVersionModalComponent implements OnInit {
  @ViewChild('inputFile') fileFormInput: ElementRef;
  @Output() onMajorVersionCreated = new EventEmitter();

  public pathwayTemplate: PathwayTemplate;
  public majorVersion: number;
  public isMajorVersion: boolean;
  public isSaving: boolean = false;
  public validationVisible: boolean = false;

  public form: FormGroup;
  public parameters: PathwayConfigurationParameter[] = [];
  public configurationsParameterType = ConfigurationsParameterType;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    public modalService: BsModalService,
    public translateService: TranslateService,
    public pathwayTemplateService: PathwayTemplateService,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.isMajorVersion = this.majorVersion === undefined || this.majorVersion < 0;

    if (this.pathwayTemplate?.versions?.length && this.isMajorVersion) {
      this.getLatestVersionParameters();
    }

    this.formSetup();
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = new FormGroup({
      file: new FormControl(null, Validators.required),
      reason: new FormControl('')
    });

    if (!this.isMajorVersion) {
      this.form.get('reason')?.setValidators(Validators.required);
      this.form.updateValueAndValidity();
    }
  }

  getLatestVersionParameters(): void {
    const latestVersion = this.pathwayTemplate.getLatestMajorVersion;
    if (latestVersion === null) {
      return;
    }

    this.pathwayTemplateService.getVersionDetails(this.pathwayTemplate.uid, latestVersion).subscribe((majorVersion: PathwayTemplateVersion) => {
      this.parameters.push(...majorVersion?.configuration_parameters);
    });
  }

  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.readAsText(file);
      reader.onload = (evt: any) => {
        const data = Base64.encode(evt.target.result);

        this.form.patchValue({
          file: data
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  openParameterModal(parameter?: PathwayConfigurationParameter): void {
    const modalRef = this.modalService.show(ConfigurationParameterComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          parameter: parameter
        }
      })
    );

    modalRef.content.onParameterCreated.subscribe((result: { parameter: PathwayConfigurationParameter, deleteKey: string }) => {
      this.parameters = this.parameters.filter((param: PathwayConfigurationParameter) => param.key !== result?.deleteKey)
      this.parameters.push(result.parameter);
    });
  }

  removeParam(parameter: PathwayConfigurationParameter): void {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.add_pathway_template_version.confirm_delete.title'),
          description: this.translateService.instant('modals.add_pathway_template_version.confirm_delete.description'),
          yes: this.translateService.instant('modals.add_pathway_template_version.confirm_delete.yes'),
          no: this.translateService.instant('modals.add_pathway_template_version.confirm_delete.no')
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      this.parameters = this.parameters.filter((param: PathwayConfigurationParameter) => param.key !== parameter.key);
    });

  }

  editParam(parameter: PathwayConfigurationParameter): void {
    this.openParameterModal(parameter);
  }

  onHandleSubmit() {
    if (this.isSaving) {
      return;
    }

    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    let params: any = {
      template_file: this.form.get('file')?.value
    };

    if (this.form.get('reason')?.value) {
      params['reason'] = this.form.get('reason')?.value;
    }

    if (this.parameters?.length && this.isMajorVersion) {
      params['configuration_parameters'] = this.parameters
    }

    this.isSaving = true;

    const updateObservable: Observable<HttpResponse<any>> = this.isMajorVersion ?
      this.pathwayTemplateService.createMajorPathwayTemplateVersion(this.pathwayTemplate.uid, params) :
      this.pathwayTemplateService.createMinorPathwayTemplateVersion(this.pathwayTemplate.uid, this.majorVersion, params);


    updateObservable.subscribe((result: HttpResponse<any>) => {
      this.showSuccessToast();
      this.isSaving = false;

      if (result?.status === 207) {    // Template has warnings but was created.
        this.handleError(this.pathwayTemplate, new PathwayTemplateErrorResponse(result.body));
      } else {
        this.onHandleClose();
      }

    }, (error: HttpErrorResponse) => {
      const pathwayTemplateError = new PathwayTemplateErrorResponse(error.error);
      this.pathwayTemplate.file = this.form.get('file')?.value;

      if (pathwayTemplateError?.results?.length && [400, 404, 409, 412].includes(error.status)) {
        this.handleError(this.pathwayTemplate, pathwayTemplateError);
      }

      this.isSaving = false
    });
  }

  onHandleClose(): void {
    this.onMajorVersionCreated.emit();
    return this.bsModalRef.hide();
  }

  showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.add_pathway_template_version.success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  handleError(pathwayTemplate: PathwayTemplate, error: PathwayTemplateErrorResponse): void {
    const modalRef = this.modalService.show(PathwayTemplateErrorModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState: {
          pathwayTemplate,
          error
        }
      })
    );

    modalRef.content.onNewFileSelected.subscribe((pathwayTemplate: PathwayTemplate) => {
      this.form.get('file')?.setValue(pathwayTemplate?.file);

      this.onHandleSubmit();
    });

    modalRef.content.onCloseAfterWarnings.subscribe(() => {
      this.onHandleClose();
    })
  }
}
