import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-folder-modal',
  templateUrl: './add-folder-modal.component.html'
})
export class AddFolderModalComponent implements OnInit {
  @Output() onFolderCreated = new EventEmitter<string>();
  @ViewChild("folderName") folderNameInput : ElementRef;

  public form: UntypedFormGroup;
  public validationVisible = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      folderName: new UntypedFormControl('', [Validators.required, Validators.pattern('[^/]+')]),
    });
    
    setTimeout(() => this.folderNameInput?.nativeElement.focus());
  }

  handleSave(): void {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.onFolderCreated.emit(this.form.get('folderName')?.value);
    this.bsModalRef.hide();
  }

  handleClose(): void {
    this.bsModalRef.hide();
  }
}
