import { TranslationInterface } from "../interfaces/translation.interface";

export class Faq {
  public uid: string;
  public faq: TranslationInterface;
  public order: Number;

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.uid = item.uid;
    this.faq = item.faq;
    this.order = item.order;
  }
}