import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LocaleService } from '../services/locale.service';

@Pipe({name: 'time'})
export class TimePipe implements PipeTransform {
  constructor(
    private readonly localeService: LocaleService
  ) {}

  transform(time: string): string|undefined {
    const timeZone = this.localeService.getLocalePreferences().locale?.time_zone;
    const time24Hours = this.localeService.getLocalePreferences().locale?.time_24_hours;

    if (!timeZone || time24Hours === undefined || !time) {
      return;
    }
    
    let mTime = moment(time);
    if (!mTime.isValid()) {
      mTime = moment(new Date(time)); 
    }
    
    if (time24Hours) {
      return mTime.tz(timeZone).format('HH:mm');
    } 
    
    return mTime.tz(timeZone).format('hh:mm a');
  }
}
