import { PathwayTemplate } from "./pathway-template";

export class TemplateGroup {
  public name: string;
  public groups: Array<TemplateGroup> = [];
  public parent: TemplateGroup;
  public templates: Array<PathwayTemplate> = [];
  public breadcrumb: Array<TemplateGroup> = [];
  public isOpen: boolean;
  
  // Used for root folder in pathway templates folders.
  public static PARENT_NAME = 'All';
  
  constructor(item?: any, parent?: TemplateGroup) {

    if (!item) {
      return;
    }
    this.name = item.name;

    if (parent) {
      this.parent = parent;
      this.breadcrumb = [...this.parent.breadcrumb];
    }

    this.breadcrumb.push(this);

    item.groups?.forEach((element: any) => {
      this.groups.push(new TemplateGroup(element, this));
    });

    this.isOpen = item.isOpen ?? false;
  }

  toggleOpen(): boolean {
    this.isOpen = !this.isOpen;
    return this.isOpen;
  }
  
  public get path(): string {
    const names = this.breadcrumb.map((crumb: TemplateGroup) => { return crumb.name });
    return names.join('/');
  }

  public create(name: string, parentGroup?: TemplateGroup): TemplateGroup {
    let group = new TemplateGroup({
      name: name,
      groups: [],
      templates: [],
      isOpen: false
    });

    if (parentGroup) {
      group.parent = parentGroup;
      group.breadcrumb = [...parentGroup.breadcrumb, group];
    }

    return group;
  }
}
