import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { TemplateGroup } from '../../../models/template-group';
import { PathwayTemplateModalComponent } from '../../../modals/pathway-template-modal/pathway-template-modal.component';
import { PathwayTemplate } from '../../../../app/models/pathway-template';
import { GeneralService } from '../../../../app/services/general.service';
import { PathwayTemplateService } from '../../../../app/services/pathway-template-service.service';
import { PathwayTemplateVersion } from '../../../models/pathway-template-version';
import { PathwayTemplateFilter, SubProcessFilterType } from '../../../interfaces/pathway-template-filter.interface';
import { cloneDeep } from 'lodash';

export enum DetailViews {
  'GROUP',
  'TEMPLATE',
  'VERSION'
}

@Component({
  selector: 'app-pathway-templates',
  templateUrl: './pathway-templates.component.html',
  styleUrls: ['./pathway-templates.component.scss']
})

export class PathwayTemplatesComponent implements OnInit {
  public templates: Array<PathwayTemplate> = [];
  public groups: Array<TemplateGroup> = [];
  public parentGroupName = TemplateGroup.PARENT_NAME;
  public parentGroup = new TemplateGroup({ name: this.parentGroupName, isOpen: true });

  public isLoading: boolean = false;
  public loadingTemplatesFor: string | undefined;

  public detailViews = DetailViews;
  public currentView: DetailViews | undefined;

  public groupDetail: TemplateGroup | undefined;
  public filter: PathwayTemplateFilter = {
    group: '',
    name: '',
    sub_process_filter_type: SubProcessFilterType.INCLUDE,
    upload_date: ''
  };
  public templateDetail: PathwayTemplate | undefined;
  public versionDetail: PathwayTemplateVersion | undefined;

  constructor(
    public modalService: BsModalService,
    public pathwayTemplateService: PathwayTemplateService,
  ) { }

  ngOnInit(): void {
    this.getGroupsAndTemplates().subscribe((result: { templates: PathwayTemplate[], groups: TemplateGroup[] }) => {
      this.groups = result.groups;
      this.templates = result.templates;
    });
  }

  refreshTemplatesAndGroups(group?: TemplateGroup) {
    if (group?.name === this.parentGroupName) {
      group = undefined;
    }

    if (group) {
      this.filter.group = group.path;
    }

    this.getGroupsAndTemplates(false).subscribe((result: { templates: PathwayTemplate[], groups: TemplateGroup[] }) => {
      if (group) {
        // Using input group scope becaue PF handles groups different
        result.templates.map(template => {
          if (group) {
            template.group = group
          }

          return template;
        });
      }

      // add filtered templates and isOpen state to groups;
      this.groups = this.mapGroups(this.flattenGroups(this.groups), result.groups, result.templates);

      if (group) {
        group = this.mapTemplatesToGroup(group, result.templates);
        this.groupDetail = this.flattenGroups(this.groups).find(_groups => _groups.path === group?.path);
      } else {
        this.templates = result.templates;
        this.parentGroup.templates = result.templates
      }

      this.groups = result.groups;
    });
  }

  getTemplates(): Observable<PathwayTemplate[]> {
    return new Observable<PathwayTemplate[]>(observer => {
      this.loadingTemplatesFor = this.filter.group;

      if (this.filter.group === this.parentGroupName) {
        this.filter.group = '';
      }
      this.pathwayTemplateService.getTemplates(this.filter, 'name,desc').subscribe(response => {

        this.loadingTemplatesFor = undefined;

        observer.next(response.templates);
        observer.complete();
      });
    })
  }

  getGroupsAndTemplates(showLoader: boolean = true): Observable<{ templates: PathwayTemplate[], groups: TemplateGroup[] }> {
    return new Observable<{ templates: PathwayTemplate[], groups: TemplateGroup[] }>(observer => {
      if (showLoader) {
        this.isLoading = true;
      }

      forkJoin([
        this.getTemplates(),
        this.pathwayTemplateService.getGroups()
      ]).subscribe((data: [PathwayTemplate[], TemplateGroup[]]) => {
        this.isLoading = false;

        observer.next({ templates: data[0], groups: data[1] });
        observer.complete();
      });
    });
  }


  // Groups
  // Groups
  // Groups


  mapGroups(flatGroups: TemplateGroup[], newGroups: TemplateGroup[], newTemplates: PathwayTemplate[]): TemplateGroup[] {
    let mappedGroups: TemplateGroup[] = [];

    for (let i = 0; i < newGroups.length; i++) {
      const match = flatGroups.find(group => group.path === newGroups[i].path);
      const filteredTemplates = newTemplates.filter((template: PathwayTemplate) => template?.group?.path === newGroups[i].path);

      if (match) {
        if (filteredTemplates?.length) {
          newGroups[i].templates = filteredTemplates;
        }

        newGroups[i].isOpen = match.isOpen;
      }

      if (newGroups[i].groups?.length) {
        newGroups[i].groups = this.mapGroups(flatGroups, newGroups[i].groups, newTemplates);
      }

      mappedGroups.push(newGroups[i]);
    }

    return mappedGroups;
  }

  flattenGroups(groups: TemplateGroup[]): TemplateGroup[] {
    let flatGroups: TemplateGroup[] = [];

    for (let i = 0; i < groups.length; i++) {
      flatGroups.push(groups[i]);

      if (groups[i].groups?.length) {
        flatGroups.push(...this.flattenGroups(groups[i].groups));
      }
    }

    return flatGroups;
  }

  mapTemplatesToGroup(group: TemplateGroup, templates: PathwayTemplate[]): TemplateGroup {
    templates.forEach(template => {
      template.group = group;
    });

    group.templates = templates;
    return group;
  }

  isSelected(selected: string): boolean {
    if (this.groupDetail && this.groupDetail?.path === selected) {
      return true;
    }

    if (this.templateDetail && this.templateDetail.uid === selected) {
      return true;
    }

    return false;
  }

  openGroup(group: TemplateGroup): void {
    if (!group.isOpen || !this.isSelected(group.path)) {

      this.filter.group = group.path;

      this.getTemplates().subscribe((templates: PathwayTemplate[]) => {
        group = this.mapTemplatesToGroup(group, templates);

        if (group.path === this.parentGroupName) {
          this.parentGroup.groups = this.groups;
          this.templates = templates;
        }

        group.isOpen = true;
        this.groups = this.mapGroups([group], this.groups, templates);
        this.handleGroup(group);
      });
    }
  }

  handleGroup(group: TemplateGroup) {
    if (group.isOpen) {
      this.navigateTo(group);
    }
  }

  toggleGroup(group: TemplateGroup): void {
    if (group.isOpen) {
      group.toggleOpen();
      this.handleGroup(group)
    } else {
      this.openGroup(group);
    }
  }

  backToGroup(group: TemplateGroup): void {
    this.openGroup(group);
  }

  isParentGroup(group: TemplateGroup): boolean {
    return group.name === this.parentGroupName;
  }

  // Pathway Templates
  // Pathway Templates
  // Pathway Templates

  newTemplate(group?: TemplateGroup) {
    const initialState: { group?: TemplateGroup } = {};

    if (group) {
      initialState.group = cloneDeep(group);

      if (group.path === this.parentGroupName) {
        initialState.group.breadcrumb = [];
      }
    }


    const modalRef = this.modalService.show(PathwayTemplateModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );

    modalRef.content.onSubmitDone.subscribe(() => {
      this.refreshTemplatesAndGroups(group ?? undefined);
    });
  }

  selectTemplate(template: PathwayTemplate) {
    this.navigateTo(template);
  }

  // Pathway version details
  // Pathway version details
  // Pathway version details

  openVersionDetails(versionDetails: { template: PathwayTemplate, version: PathwayTemplateVersion }): void {
    this.navigateTo(versionDetails);
  }

  navigateTo(detail: PathwayTemplate | TemplateGroup | { template: PathwayTemplate, version: PathwayTemplateVersion }): void {
    if (detail instanceof TemplateGroup) {
      this.groupDetail = detail;
      this.templateDetail = undefined;
      this.versionDetail = undefined;

      this.currentView = DetailViews.GROUP;
    }

    else if (detail instanceof PathwayTemplate) {
      this.groupDetail = undefined;
      this.templateDetail = detail;
      this.versionDetail = undefined;

      this.currentView = DetailViews.TEMPLATE;
    }

    else if (detail?.template instanceof PathwayTemplate && detail?.version instanceof PathwayTemplateVersion) {
      this.groupDetail = undefined;
      this.templateDetail = detail.template;
      this.versionDetail = detail.version;
      this.currentView = DetailViews.VERSION
    }

    else {
      this.groupDetail = undefined;
      this.templateDetail = undefined;
      this.versionDetail = undefined;

      this.currentView = undefined
    }
  }
}
