import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EducationalMaterial } from '../../models/educational-material';
import { Phase } from '../../models/phase';
import { EducationalMaterialService } from '../../services/educational-material.service';

@Component({
  selector: 'app-add-educational-material-modal',
  templateUrl: './add-educational-material-modal.component.html'
})
export class AddEducationalMaterialModalComponent implements OnInit {
  @Output() onEducationalMaterialCreated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public educationalMaterial = new EducationalMaterial();
  public phase: Phase;
  public hospitalUid: string;
  public careModuleUid: string;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public educationalMaterialService: EducationalMaterialService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      order: ['', [Validators.required]],
      educational_material: new UntypedFormGroup({
        reference: new UntypedFormGroup({
          region: new UntypedFormControl('educational_material', Validators.required),
          key: new UntypedFormControl('', Validators.required)
        })
      })
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }
    this.validationVisible = false;
    this.isSaving = true;

    const data = {
      phase: this.phase.getPhaseTree(),
      educational_materials: [
        {
          reference: {
            key: this.form.get('educational_material.reference.key')?.value,
            region: this.form.get('educational_material.reference.region')?.value
          },
          order: this.form.get('order')?.value
        }
      ]
    };

    this.educationalMaterialService.createEducationalMaterial(this.hospitalUid, this.careModuleUid, data).subscribe(() => this.onCreateSuccess(), 
      (error) => this.isSaving = false);
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.add_educational_material.created'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onEducationalMaterialCreated.emit();
    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
