import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareModule } from '../../models/care-module';
import { HospitalCode } from '../../models/hospital-code';
import { HospitalCodeService } from '../../services/hospital-code.service';
import { Hospital } from '../../models/hospital';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'app-set-hospital-code',
  templateUrl: './set-hospital-code.component.html',
  styleUrls: ['./set-hospital-code.component.scss']
})
export class SetHospitalCodeComponent implements OnInit {
  @Output() onHospitalCodeSet = new EventEmitter<void>();

  public hospitalCode: HospitalCode;
  public hospitalUid: string;
  public hospital: Hospital;
  public careModule: CareModule;
  public validationVisible = false;
  public isSaving = false;

  public form = this.formBuilder.group({
    code: new FormControl<string>('', { nonNullable: true }),
    days_valid_indefinite: new FormControl<boolean>(false, { nonNullable: true }),
    days_valid: new FormControl<number | null>(null),
  });
  public hospitalCodeDaysValidMin = 7;
  public hospitalCodeDaysValidMax = 365;

  constructor(
    public formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    public hospitalCodeService: HospitalCodeService,
    public hospitalService: HospitalService,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getHospital();
  }

  getHospital() {
    this.hospitalService.getHospital(this.hospitalUid).subscribe((hospital: Hospital) => {
      this.hospital = hospital;
      this.formSetup();
    });
  }

  formSetup() {
    this.form.get('code')?.setValue(this.hospitalCode?.code);
    this.form.get('code')?.addValidators([Validators.required, Validators.maxLength(4), Validators.minLength(4)]);

    this.form.get('code')?.updateValueAndValidity();

    if (this.hospitalCode) {
      this.form.get('days_valid_indefinite')?.setValue(this.hospitalCode.days_valid_indefinite);
      this.form.get('days_valid')?.setValue((this.hospitalCode.days_valid && this.hospitalCode.days_valid > 0) ? this.hospitalCode.days_valid : null);
    } else {
      this.form.get('days_valid_indefinite')?.setValue(this.hospital.general_configuration.hospital_code_days_valid_indefinite);
      this.form.get('days_valid')?.setValue(this.hospital.general_configuration.hospital_code_days_valid ?? null);
    }

    if (!this.hospitalCode?.days_valid_indefinite) {
      this.form.get('days_valid')?.setValidators([Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)]);
    }

    this.form.get('days_valid_indefinite').valueChanges.subscribe(value => {
      if (!value) {
        this.form.get('days_valid')?.setValidators([Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)]);
      } else {
        this.form.get('days_valid')?.clearValidators();
      }

      this.form.get('days_valid')?.updateValueAndValidity();
    })

    this.form.get('days_valid')?.updateValueAndValidity();
    this.form.get('days_valid_indefinite')?.updateValueAndValidity();
  }


  onHandleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    if (this.hospitalCode) {
      // Update
      this.hospitalCode.code = this.form.get('code')?.value;
      this.hospitalCode.days_valid_indefinite = this.form.get('days_valid_indefinite')?.value;

      if (this.form.get('days_valid_indefinite')?.value) {
        delete this.hospitalCode.days_valid;
      } else {
        this.hospitalCode.days_valid = this.form.get('days_valid')?.value;
      }

      this.hospitalCodeService.update(this.hospitalCode, this.careModule.uid, this.hospitalUid).subscribe({
        next: () => {
          this.showSuccessToast();
          this.onHospitalCodeSet.emit();
          this.onHandleClose();
        }, error: () => this.isSaving = false
      });

    } else {
      // Create
      let hospitalCode: any = {
        code: this.form.get('code')?.value,
        care_module_uid: this.careModule.uid,
        days_valid_indefinite: this.form.get('days_valid_indefinite')?.value,
      }

      if (!this.form.get('days_valid_indefinite')?.value) {
        hospitalCode.days_valid = this.form.get('days_valid')?.value;
      }

      this.hospitalCodeService.create(
        this.hospitalUid,
        hospitalCode
      ).subscribe({
        next: () => {
          this.showSuccessToast();
          this.onHospitalCodeSet.emit();
          this.onHandleClose();
        }, error: () => this.isSaving = false
      });
    }
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.set_hospital_code.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}

