import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { UserType } from '../enum/UserType';

@Injectable({
  providedIn: 'root'
})
export class GDPRService extends ApiService {
  private static RightExport = 'export';
  private static RightRestrict = 'restriction';
  private static RightDelete = 'erasure';

  public static RestrictActionDo = 'ENFORCE';
  public static RestrictActionCancel = 'LIFT';

  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);

    this.platformUrl = environment.platformUrl;
  }

  public export(email: string, hospitalUid: string, type: UserType): Observable<any> {
    const data = {
      email: email
    };

    return this.doRequest(GDPRService.RightExport, hospitalUid, type, data);
  }

  public exportAll(email: string, hospitals: Array<string>, type: UserType): Observable<any> {
    let requests: Array<Observable<any>> = [];

    hospitals.forEach((hospitalUid) => {
      requests.push(this.export(email, hospitalUid, type));
    });

    return forkJoin(requests);
  }

  public restrict(email: string, action: string, hospitalUid: string, type: UserType): Observable<any> {
    const data = {
      email: email,
      action: action
    };

    return this.doRequest(GDPRService.RightRestrict, hospitalUid, type, data);
  }

  public restrictAll(email: string, action: string, hospitals: Array<string>, type: UserType): Observable<any> {
    let requests: Array<Observable<any>> = [];

    hospitals.forEach((hospitalUid) => {
      requests.push(this.restrict(email, action, hospitalUid, type));
    });

    return forkJoin(requests);
  }

  public delete(email: string, hospitalUid: string, type: UserType): Observable<any> {
    const data = {
      email: email
    };

    return this.doRequest(GDPRService.RightDelete, hospitalUid, type, data);
  }

  public deleteAll(email: string, hospitals: Array<string>, type: UserType): Observable<any> {
    let requests: Array<Observable<any>> = [];

    hospitals.forEach((hospitalUid) => {
      requests.push(this.delete(email, hospitalUid, type));
    });

    return forkJoin(requests);
  }

  private doRequest(right: string, hospitalUid: string, type: UserType, data: any): Observable<any> {
    const userType: string = (type === UserType.PATIENTS) ? 'patients' : 'hcps';

    return new Observable(observer => {
      this.authenticatedPost(`${this.platformUrl}/gdpr/right-of-${right}/hospitals/${hospitalUid}/${userType}`, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
