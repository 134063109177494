<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header header-title-only">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.platform_configuration.platform_configuration' | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container">
      <div *ngIf="!platformSupportedLanguages">
        <app-page-loader></app-page-loader>
      </div>

      <div *ngIf="platformSupportedLanguages">
        <div class="action-item-block mb-1">
          <div class="block-wrapper p-start-3 p-end-1 w-100">
            <div class="row align-items-start">
              <div class="col-auto icon-column">
                <span class="icon icon-lg" [inlineSVG]="'/assets/svg/planet.svg'"></span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h3>{{ 'pages.default.platform_configuration.platform_supported_languages' | translate }}</h3>
                    <p class="small text-muted mb-0">{{ 'pages.default.platform_configuration.available_languages' |
                      translate }}</p>
                    <p>{{ platformSupportedLanguages }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button class="btn block-action m-start-auto" (click)="editSupportedLanguages()">
            <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
