<div class="modal-header pb-0">
  <h4 *ngIf="isMajorVersion">{{ 'modals.add_pathway_template_version.major_title' | translate }}</h4>
  <h4 *ngIf="!isMajorVersion">{{ 'modals.add_pathway_template_version.minor_title' | translate }}</h4>

  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>

<div class="modal-body py-0">
  <p *ngIf="!isMajorVersion">{{'modals.add_pathway_template_version.disclaimer' | translate}}</p>
  
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled file">
      <label class="small d-block required">{{ 'form.labels.pathway_template' | translate }}</label>
      <input type="file" (change)="onFileChange($event)" />
      <app-feedback-field [field]="form.get('file')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label w-50">
      <input type="text" id="reason" class="form-control w-100" [placeholder]="'form.labels.reason' | translate"
        formControlName="reason">
      <label for="reason" [ngClass]="{'required': !isMajorVersion}">{{ 'form.labels.reason' | translate }}</label>
      <app-feedback-field [field]="form.get('reason')"></app-feedback-field>
    </div>
  </form>

  <div *ngIf="isMajorVersion">
    
    <hr />

    <div class="d-flex justify-content-between align-items-center mb-2">
      <p class="font-weight-bold mb-0">{{ 'modals.add_pathway_template_version.parameters' | translate }}</p>
      <button class="btn btn-link pr-0" (click)="openParameterModal()">
        <span class="icon-small mr-1" [inlineSVG]="'/assets/svg/add.svg'"></span>
        {{ 'modals.add_pathway_template_version.add_parameters' | translate }}
      </button>
    </div>

    <p *ngIf="!parameters?.length">
      {{ 'modals.add_pathway_template_version.no_parameters' | translate }}
    </p>

    <ul class="parameters list-unstyled" *ngIf="parameters?.length">
      <li>
        <div class="row bg-light p-1 no-gutters">
          <div class="col-2">
            <p class="small text-muted mb-0">{{ 'modals.add_pathway_template_version.key' | translate }}</p>
          </div>
          <div class="col-3">
            <p class="small text-muted mb-0">{{ 'modals.add_pathway_template_version.description' | translate }}</p>
          </div>
          <div class="col-2">
            <p class="small text-muted mb-0">{{ 'modals.add_pathway_template_version.type' | translate }}</p>
          </div>
          <div class="col">
            <p class="small text-muted mb-0">{{ 'modals.add_pathway_template_version.default_value' | translate }}</p>
          </div>
        </div>
      </li>

      <li *ngFor="let parameter of parameters">
        <div class="row bg-light pl-1 no-gutters my-1 align-items-center">
          <div class="col-2">
            <p class="text-truncate font-weight-bold mb-0">{{ parameter.key }}</p>
          </div>
          <div class="col-3">
            <p class="text-truncate mb-0">{{ parameter.description }}</p>
          </div>
          <div class="col-2">
            <p class="text-truncate mb-0">{{ parameter.data_type }}</p>
          </div>
          <div class="col-2">
            <p *ngIf="parameter.data_type === configurationsParameterType.DATE" class="mb-0 text-truncate">{{
              parameter.default_value | timeZoneDate }}</p>
            <p *ngIf="parameter.data_type === configurationsParameterType.DATE_TIME" class="mb-0 text-truncate">{{
              parameter.default_value | timeZoneDateTime }}</p>

            <p *ngIf="![configurationsParameterType.DATE, configurationsParameterType.DATE_TIME].includes(parameter.data_type)"
              class="mb-0 text-truncate">{{ parameter.default_value }}</p>
          </div>
          <div class="col-3 d-flex align-items-center justify-content-end">
            <button class="btn btn-link d-flex" (click)="removeParam(parameter)">
              {{ 'modals.add_pathway_template_version.remove' | translate }}
              <span [inlineSVG]="'/assets/svg/close.svg'"></span>
            </button>
            <button class="btn btn-secondary py-2" (click)="editParam(parameter)">
              <span [inlineSVG]="'/assets/svg/edit.svg'"></span>
            </button>
          </div>
        </div>
      </li>
    </ul>

  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3"
      [disabled]="!form.valid">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_pathway_template_version.add_template_version' | translate }}</span>
    </button>
  </div>
</div>
