import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CareModule } from '../../models/care-module';
import { CareModuleService } from '../../services/care-module.service';

@Component({
  selector: 'app-edit-cm-type',
  templateUrl: './edit-cm-type.component.html',
  styleUrls: ['./edit-cm-type.component.scss']
})
export class EditCmTypeComponent implements OnInit {
  public isSaving: boolean = false;
  public form: any;
  public careModule: CareModule;
  public hospitalUid: string;

  @Output() onCareModuleUpdated = new EventEmitter<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private careModuleService: CareModuleService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      type: new UntypedFormControl(this.careModule.type, []),
    });
  }

  onHandleSubmit() {
    this.isSaving = true;
    
    const data = cloneDeep(this.careModule);
    data.type = this.form.get('type').value;
    
    this.careModuleService.update(this.hospitalUid, data).subscribe(() => {
      this.onHandleClose();
      this.isSaving = false;
    }, () => this.isSaving = false);
  }

  onHandleClose() {
    this.bsModalRef.hide();
  }
}
