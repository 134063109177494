import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EducationalMaterial } from '../../models/educational-material';
import { EducationalMaterialPhase } from '../../models/educational-material-phase';
import { EducationalMaterialService } from '../../services/educational-material.service';

@Component({
  selector: 'app-edit-educational-material-modal',
  templateUrl: './edit-educational-material-modal.component.html'
})
export class EditEducationalMaterialModalComponent implements OnInit {
  @Output() onEducationalMaterialUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public educationalMaterial: EducationalMaterial;
  public educationalMaterialPhase: EducationalMaterialPhase;
  public hospitalUid: string;
  public careModuleUid: string;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public educationalMaterialService: EducationalMaterialService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      order: [this.educationalMaterial.order, [Validators.required]],
      educational_material: new UntypedFormGroup({
        reference: new UntypedFormGroup({
          region: new UntypedFormControl('educational_material', Validators.required),
          key: new UntypedFormControl(this.educationalMaterial.reference.key, Validators.required)
        })
      })
    });

    this.form.get('educational_material')?.get('reference')?.get('key')?.disable();
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }
    this.validationVisible = false;
    this.isSaving = true;

    const data = {
      phase: this.educationalMaterialPhase.phase,
      educational_materials: [
        {
          id: this.educationalMaterial.id,
          reference: {
            key: this.form.get('educational_material.reference.key')?.value,
            region: this.form.get('educational_material.reference.region')?.value
          },
          order: this.form.get('order')?.value
        },
        ...this.educationalMaterialPhase.educational_materials.filter((item) => item.id !== this.educationalMaterial.id)
      ]
    };

    this.educationalMaterialService.updateEducationalMaterial(this.hospitalUid, this.careModuleUid, this.educationalMaterialPhase.id, data).subscribe(() => this.onUpdateSuccess(),
      (error) => this.isSaving = false);
  }

  onUpdateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.edit_educational_material.updated'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onEducationalMaterialUpdated.emit();
    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
