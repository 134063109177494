import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import AdminRole from '../enum/AdminRole';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserAdminGuard  {
  constructor(
    private readonly authService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles = this.authService.getCurrentRoles();
    return roles.includes(AdminRole.ROLE_USER_ADMIN);
  }
}
