export interface PathwayConfigurationParameter {
  data_type: ConfigurationsParameterType;
  default_value: any;
  description: string;
  key: string;
}

export enum ConfigurationsParameterType {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE'
}

export class ConfigurationsParameterValidators {
  static readonly VALIDATOR_INTEGER = /^-?\d+$/;
  static readonly VALIDATOR_DOUBLE = /^-?[0-9]+\.[0-9]+$/;
}
