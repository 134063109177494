<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header">
    <div class="header-content">
      <div class="container">
        <h1>{{ 'pages.default.camunda_cockpit_links.title' | translate }}</h1>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <form class="d-inline-block p-3 bg-white" [formGroup]="form">
      <div class="row">
        <div class="col">
          <div class="filters-group">
            <div class="form-group styled placeholder">
              <input [placeholder]="'pages.default.camunda_cockpit_links.form.uid' | translate" class="form-control" formControlName="uid" type="text" required>
            </div>
          </div>
        </div>
        <div class="col col-auto">
          <button type="submit" class="btn btn-secondary btn-sm" [disabled]="!form.valid" [ngClass]="{'loader': isLoading}" (click)="generate()">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
            <span>{{ 'pages.default.camunda_cockpit_links.form.submit' | translate }}</span>
          </button>
        </div>
      </div>
    </form>

    <ng-container *ngIf="links && !isLoading">
      <hr />

      <table class="table admin-table">
        <thead>
          <tr>
            <th scope="col">Link</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let link of links | keyvalue">
            <td>{{ link.value }}</td>
            <td class="action">
              <a href="#copy" class="action-button" (click)="$event.preventDefault()" [cdkCopyToClipboard]="$any(link.value)">
                <span [inlineSVG]="'/assets/svg/copy.svg'"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
