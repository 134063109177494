import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export function atLeastOneOtherRequiredValidator(trigger: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const controls = formGroup.controls;
    const isAllSelected = controls[trigger]?.value;
    let hasAtLeastOneOther = false;

    for (const key in controls) {
      if (key !== trigger && controls[key].value) {
        hasAtLeastOneOther = true;
        break;
      }
    }

    if (isAllSelected && !hasAtLeastOneOther) {
      for (const key in controls) {
        if (key !== trigger) {
          controls[key].setErrors({ at_least_one_other_required: true });
        }
      }

      return { at_least_one_other_child_required: true };

    } else {
      for (const key in controls) {
        if (key !== trigger && controls[key].errors && controls[key].errors['at_least_one_other_required']) {
          controls[key].updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
      }

      return null;
    }
  };
}
