<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4 *ngIf="!template?.uid">{{ 'modals.pathway_template.add_pathway_template' | translate }}</h4>
  <h4 *ngIf="template?.uid">{{ 'modals.pathway_template.edit_pathway_template' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate"
        formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>
    <div class="form-group styled floating-label">
      <input type="text" id="description" class="form-control w-100"
        [placeholder]="'form.labels.description' | translate" formControlName="description">
      <label for="description" class="required">{{ 'form.labels.description' | translate }}</label>
      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
    </div>

    <div class="mt-3 mb-2">
      <label class="small d-block required">{{ 'form.labels.is_sub_process' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="is_sub_processTrue" formControlName="is_sub_process"
          [value]="true">
        <label class="custom-control-label" for="is_sub_processTrue">{{ 'form.labels.yes' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="is_sub_processFalse" formControlName="is_sub_process"
          [value]="false">
        <label class="custom-control-label" for="is_sub_processFalse">{{ 'form.labels.no' | translate }}</label>
      </div>
      <app-feedback-field [field]="form.get('is_sub_process')"></app-feedback-field>
    </div>
    
    <hr class="my-2">

    <div *ngIf="!form.value.group">
      <p class="small text-muted mb-1">{{ 'modals.pathway_template.no_folder_selected' | translate }}</p>
      <button class="btn btn-link pl-0" (click)="openFolderSelect($event)">{{
        'modals.pathway_template.select_folder' | translate }}</button>
    </div>

    <div *ngIf="form.value.group" class="bg-light row no-gutters justify-content-between align-items-center p-2">
      <div class="col-6 d-flex align-items-center">
        <h3 class="m-0" [inlineSVG]="'/assets/svg/folder.svg'"></h3>
        <p class="mb-0 text-truncate"> {{groupLabel}}</p>
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-link pr-0" (click)="openFolderSelect($event)">{{
          'modals.pathway_template.select_different_folder' | translate }}</button>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto d-flex">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

    <div *ngIf="!template?.uid">
      <button (click)="onHandleSubmitNewTemplate()" [ngClass]="{'loader': isSavingWithoutParameters}"
        class="btn btn-secondary m-start-3" [disabled]="!form.valid">
        <span class="loader"><em *ngIf="isSavingWithoutParameters" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'modals.pathway_template.add_template' | translate }}</span>
      </button>

      <button (click)="onHandleSubmitNewTemplate(true)" [ngClass]="{'loader': isSavingWithParameters}"
        class="btn btn-secondary m-start-3" [disabled]="!form.valid">
        <span class="loader"><em *ngIf="isSavingWithParameters" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'modals.pathway_template.add_template_and_parameters' | translate }}</span>
      </button>
    </div>

    <div *ngIf="template?.uid">
      <button (click)="onHandleSubmitEditTemplate()" [ngClass]="{'loader': isSavingWithoutParameters}"
        class="btn btn-secondary m-start-3" [disabled]="!form.valid">
        <span class="loader"><em *ngIf="isSavingWithoutParameters" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'modals.pathway_template.confirm_template' | translate }}</span>
      </button>
    </div>
  </div>
</div>
