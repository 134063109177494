<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.add_educational_material.add_educational_material' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div formGroupName="educational_material">
      <div formGroupName="reference">
        <div class="form-group styled floating-label">
          <input type="text" id="key" class="form-control w-100" [placeholder]="'form.labels.cms_reference' | translate" formControlName="key">
          <label for="key" class="required">{{ 'form.labels.cms_reference' | translate }}</label>
          <app-feedback-field [field]="form.get('educational_material.key')"></app-feedback-field>
        </div>
      </div>
    </div>
    <div class="form-group styled floating-label">
      <input type="number" id="order" class="form-control w-100" [placeholder]="'form.labels.order' | translate" formControlName="order">
      <label for="order" class="required">{{ 'form.labels.order' | translate }}</label>
      <app-feedback-field [field]="form.get('order')"></app-feedback-field>
    </div>
    <p class="text-muted">{{ 'modals.add_educational_material.info' | translate }}</p>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_educational_material.add_educational_material' | translate }}</span>
    </button>
  </div>
</div>
