export class PlatformConsent {
  public key: string;
  public category: string;
  public languages: Array<string> = [];
  public required_for_correlation_id: boolean;
  public version: number;
  public order: number;
  public enabled: boolean;

  constructor(item? :any) {
    if (item) {
      this.key = item.key;
      this.category = item.category;
      this.languages = item.languages;
      this.required_for_correlation_id = item.required_for_correlation_id;
      this.version = item.version;
      this.order = item.order;
      this.enabled = item.enabled;
    }
  }
}
