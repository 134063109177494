import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HomeFlowService } from '../../../services/home-flow.service';
import { MfaService } from './../../../services/mfa.service';

@Component({
  selector: 'app-mfa-code',
  templateUrl: './mfa-code.component.html'
})
export class MfaCodeComponent {
  public codeInput: string;
  public codeIncorrect: boolean;
  public codeEmpty: boolean;
  public isLoading: boolean;

  constructor(
    public router: Router,
    public mfaService: MfaService,
    public homeFlowService: HomeFlowService,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) { }

  submit() {
    if(this.isLoading) {
      return;
    }

    this.codeIncorrect = false;
    this.codeEmpty = false;

    if(!this.codeInput) {
      this.codeEmpty = true;
      return;
    }

    this.isLoading = true;

    this.mfaService.postCode(this.codeInput).subscribe(
      () => this.onCodeSubmissionSuccess(),
      (error) => this.onCodeSubmissionError(error)
    );
  }

  onCodeSubmissionSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isLoading = false;
    });
  }

  onCodeSubmissionError(error: any) {
    this.isLoading = false;

    if (error.status === 400) {
      this.codeIncorrect = true;
    }
  }

  submitRequestNewCode(event: MouseEvent) {
    event.preventDefault();

    this.toastrService.info(this.translate.instant('pages.flow.mfa_code.sent_new_code'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.mfaService.requestCode().subscribe(
      () => {},
      () => this.onRequestCodeError()
    );
  }

  onRequestCodeError() {
    this.toastrService.clear();
    this.toastrService.error(this.translate.instant('pages.flow.mfa_code.error_requesting_code'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  showFieldValidation() {
    return (this.codeIncorrect || (this.codeEmpty && !this.codeInput));
  }
}
