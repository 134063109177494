import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddApplicationModalComponent } from '../../../../app/modals/add-application-modal/add-application-modal.component';
import { EditApplicationModalComponent } from '../../../../app/modals/edit-application-modal/edit-application-modal.component';
import { Application } from '../../../../app/models/application';
import { ApplicationService } from '../../../../app/services/application.service';
import { GeneralService } from '../../../../app/services/general.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  public applications: Array<Application> = [];
  public isLoading: boolean = false;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  constructor(private applicationService: ApplicationService, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.loadApplications();
  }

  private loadApplications() {
    this.isLoading = true;
    this.applicationService.getApplications(this.currentPage - 1, this.currentPageSize).subscribe((result) => {
      this.applications = result.applications;
      this.isLoading = false;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements
      }
    });
  }

  onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.loadApplications();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.loadApplications();
    }
  }

  newApplication(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddApplicationModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );

    modalRef.content.onApplicationCreated.subscribe(() => this.loadApplications());
  }

  editApplication(event: MouseEvent, application: Application) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditApplicationModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          application
        }
      })
    );

    modalRef.content.onApplicationUpdated.subscribe(() => this.loadApplications());
  }
}
