import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareModule } from '../../models/care-module';
import { CareModuleService } from '../../services/care-module.service';
import { LanguageService } from '../../services/language.service';
import { LanguageCode } from '../../models/language-code';

@Component({
  selector: 'app-edit-cm-translation',
  templateUrl: './edit-cm-translation.component.html',
  styleUrls: ['./edit-cm-translation.component.scss']
})
export class EditCmTranslationComponent implements OnInit {
  @Output() onTranslationAdded = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public isSaving: boolean = false;
  public validationVisible: boolean = false;
  public languages: LanguageCode[];
  public hospitalUid: string;
  public careModule: CareModule;
  public translation: any;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public languageService: LanguageService,
    public careModuleService: CareModuleService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      language: new UntypedFormControl({ value: this.translation?.language, disabled: this.translation?.language === 'EN' }, Validators.required),
      name: [this.translation?.name, [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]],
      description: [this.translation?.description]
    });

    this.languageService.getLanguageCodes().subscribe((result) => {
      const keys = this.careModule.name.translations.map((item) => item.language);

      this.languages = result.filter((option) => {
        // You can select the current Language.
        if (option.key === this.translation.language) return true;

        // You should not be able to select an other, already used language.
        return !keys.includes(option.key);
      });
    });
  }

  public onHandleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    const language = this.translation.language;
    let data: CareModule = cloneDeep(this.careModule);
    let nameIndex = data.name.translations.findIndex(translation =>
      translation.language === language);
    let descriptionIndex = data.description.translations.findIndex(translation =>
      translation.language === language);

    // NAME
    if (this.form.get('name')?.dirty) {
      data.name.translations[nameIndex].translation = this.form.get('name')?.value;
    }

    //DESCRIPTION
    if (this.form.get('description')?.dirty) {
      // remove translation if present
      if (this.form.get('description')?.value === '') {
        data.description.translations = data.description.translations.filter(translation =>
          translation.language !== language);
      }

      // update translation
      if (descriptionIndex >= 0 && this.form.get('description')?.value !== '') {
        data.description.translations[descriptionIndex].translation = this.form.get('description')?.value;
      }

      // add translation
      if (descriptionIndex < 0 && this.form.get('description')?.value !== '') {
        const translation = {
          language: this.form.get('language')?.value,
          translation: this.form.get('description')?.value
        }

        data.description.translations.push(translation)
      }
    }

    // LANGUAGE
    if (this.form.get('language')?.dirty) {
      // update names
      data.name.translations[nameIndex].language = this.form.get('language')?.value;

      // upudate language of description if present
      if (descriptionIndex >= 0 && this.form.get('description')?.value !== '') {
        data.description.translations[descriptionIndex].language = this.form.get('language')?.value;
      }
    }

    this.careModuleService.update(this.hospitalUid, data).subscribe(() => {
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onTranslationAdded.emit();
    });
  }

  public onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_cm_translation.success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
