import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CareModuleType } from '../../models/care-module';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-care-module-team',
  templateUrl: './care-module-team.component.html'
})
export class CareModuleTeamComponent implements OnInit {
  @Input() hospitalUid: string;
  @Input() cmType: CareModuleType;

  @Input() caseManager: HealthCareProfessional;
  @Output() caseManagerChange = new EventEmitter<HealthCareProfessional>();

  @Input() clinicalLead: HealthCareProfessional;
  @Output() clinicalLeadChange = new EventEmitter<HealthCareProfessional>();

  @Input() teamValid: boolean;
  @Output() teamValidChange = new EventEmitter<boolean>();

  public hcps: HealthCareProfessional[] = [];
  public teamForm: FormGroup;
  public careModuleType = CareModuleType;

  public searchHcpListEvent = new EventEmitter<any>();

  constructor(
    public hcpService: HcpService,
  ) { }

  ngOnInit(): void {
    this.getHcps();

    this.teamFormSetup();

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });
  }

  getHcps(searchTerm?: string): void {
    this.hcpService.getPaged(this.hospitalUid, { last_name: searchTerm || '' }, 'last_name,asc').subscribe(result => {
      this.hcps = result.hcps;
    });
  }

  searchHcpList(searchTerm?: string): void {
    if (!searchTerm) {
      this.hcps = [];
    }

    this.getHcps(searchTerm);
  }

  teamFormSetup(): void {
    if (this.teamForm) {
      return;
    }

    this.teamForm = new FormGroup({
      clinicalLead: new FormControl(this.clinicalLead),
      caseManager: new FormControl(this.caseManager)
    });
    
    if(this.cmType === CareModuleType.EDUCATION) {
      this.teamForm.get('clinicalLead')?.setValidators(Validators.required);
      this.teamForm.get('caseManager')?.setValidators(Validators.required);
      this.teamForm.updateValueAndValidity();
    }
    
    if(this.teamForm.valid) {
      setTimeout(() => this.teamValidChange.emit(this.teamForm.valid));
    }

    this.teamForm.valueChanges.subscribe(() => {
      this.handleFormUpdates();
    })
  }

  handleFormUpdates(): void {
    this.clinicalLead = this.teamForm.get('clinicalLead')?.value;
    this.clinicalLeadChange.emit(this.clinicalLead);
    
    this.caseManager = this.teamForm.get('caseManager')?.value;
    this.caseManagerChange.emit(this.caseManager);
    
    this.teamValidChange.emit(this.teamForm.valid);
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
