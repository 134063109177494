import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { HomeFlowService } from "./../../../services/home-flow.service";
import { LoginService } from "./../../../services/login.service";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {
  public form: UntypedFormGroup;
  public isValidationVisible: boolean = false;
  public isAccountLocked: boolean = false;
  public isLoading: boolean = false;
  public credentialsIncorrect: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public loginService: LoginService,
    public homeFlowService: HomeFlowService
  ) { }

  ngOnInit() {
    this.formSetup();
  }

  formSetup() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  formSubmit() {
    this.isValidationVisible = false;
    this.isAccountLocked = false;

    if (this.isLoading) {
      return;
    }

    if(!this.form.valid) {
      this.isValidationVisible = true;
    } else {
      this.isValidationVisible = false;
      this.isLoading = true;

      this.loginService.login(this.form.value.email, this.form.value.password).subscribe(
        response => this.loginSuccessHandler(response), 
        error => this.loginErrorHandler(error));
    }
  }

  loginSuccessHandler(response: any) {
    this.homeFlowService.goNextStepOrDone(HomeFlowService.FlowLogin).subscribe(() => {
      this.isLoading = false;
    });
  }
  
  loginErrorHandler(response: any) {
    this.isLoading = false;

    if(!response) {
      return;
    }

    if(response?.error?.key) {
      const key = response?.error?.key;

      if(key == 'ERROR_IDENTITY_ACCOUNT_LOCKED') {
        this.markAccountLocked();
      } else {
        this.markCredentialsInvalid();
      }
    } else {
      this.markCredentialsInvalid();
    }
  }

  markCredentialsInvalid() {
    this.isLoading = false;
    this.isValidationVisible = true;
    this.credentialsIncorrect = true;
  }

  markAccountLocked() {
    this.isLoading = false;
    this.isValidationVisible = true;
    this.isAccountLocked = true;
  }
}