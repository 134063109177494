import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from './services/authentication.service';
import { GeneralService } from './services/general.service';
import { MaintenanceService } from './services/maintenance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isIeOrEdge: boolean = false;
  title = 'c4t_ops_console';

  constructor(
    public translate: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public modalService: BsModalService,
    public authenticationService: AuthenticationService,
    public generalService: GeneralService,
    public maintenanceService: MaintenanceService
    ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.authenticationService.onLogOut.subscribe(() => this.doLogOutRules());
    this.generalService.appInitializer();
    this.maintenanceService.appInitializer();
    this.checkFlow();
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  detectIeOrEdge(): void {
    this.isIeOrEdge = /msie\s|trident\/|edge\/|edg\//i.test(window.navigator.userAgent);
  }

  doLogOutRules() {
    // Navigate to root
    this.router.navigateByUrl('/');

    // close all modals
    const modalsCount = this.modalService.getModalsCount();
    for (let index = 1; index <= modalsCount; index++) {
      this.modalService.hide(index);
    }
    
    document.body.classList.remove('modal-open');
  }

  checkFlow() {
    this.route.queryParams.subscribe(params => {
      const queryParams: Params = this.route.snapshot.queryParams;
      const flow = queryParams['flow'] || '';
      const code = queryParams['code'] || '';

      switch(flow) { 
        case 'FORGOT_PASSWORD': { 
          this.router.navigate(['password-reset'], { queryParams: {code}});
          break; 
        }
        case 'ONBOARDING': {
          this.router.navigate(['onboarding'], { queryParams: {code}});
          break; 
        }
        default: 
          break;
     } 
    });
  }
}


