<div class="pathway-group-detail">
  <div class="layout-header-pusher header-filters-breadcrumbs"></div>

  <div class="layout-header header-filters-breadcrumbs">
    <div class="header-content">
      <div class="container">
        <ul class="breadcrumbs d-flex p-0 m-0">
          <li *ngFor="let crumb of group.breadcrumb; let last = last; let first = first"
            class="d-flex align-items-center">
            <button class="btn px-0 py-2" (click)="selectGroup(crumb)"
              [ngClass]="{'text-secondary': !last}">{{crumb.name}}</button>
            <span class="icon mx-1 " [inlineSVG]="'/assets/svg/chevron-right.svg'" *ngIf="!last"></span>
          </li>
        </ul>

        <div class="title-button mb-3">
          <h2 class="text-truncate">{{ group.name }}</h2>

          <div>
            <button id="button-add-new-template" class="btn btn-secondary btn-sm" (click)="newTemplate()">
              {{ 'pages.default.templates.new' | translate }}
            </button>
          </div>
        </div>

        <div class="filters-group">
          <div class="form-group styled floating-label">
            <input class="form-control w-100" [placeholder]="'form.labels.template_name' | translate"
              id="templateNameSearch" [(ngModel)]="filter.name" (ngModelChange)="filterUpdate.next($event)" required
              type="text">

            <label for="templateNameSearch">{{ 'form.labels.template_name' | translate }}</label>
          </div>

          <div class="divider"></div>

          <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
            <button aria-controls="dropdown-basic" class="btn btn-primary dropdown-toggle" dropdownToggle
              id="dropdown-btn-processes" type="button">
              <span>{{ selectedProcessType }}</span>
              <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
            </button>
            <ul *dropdownMenu aria-labelledby="dropdown-btn-processes" class="dropdown-menu"
              id="dropdown-basic-assignments" role="menu">
              <li role="menuitem" *ngFor="let processType of processTypes">
                <button class="btn dropdown-item" (click)="onProcessChange(processType.value)">
                  {{ processType.label }}
                </button>
              </li>


            </ul>
          </div>

          <div class="divider"></div>

          <div class="form-group styled floating-label">
            <input type="text" [(ngModel)]="filter.upload_date" (ngModelChange)="filterUpdate.next($event)"
              placeholder="Daterangepicker" class="form-control w-100"
              [placeholder]="('form.labels.upload_date' | translate)" bsDaterangepicker>
            <label>{{ 'form.labels.upload_date' | translate }}</label>
          </div>

          <div *ngIf="hasFiltersSet()" class="divider"></div>

          <button *ngIf="hasFiltersSet()" class="btn btn-link text-info py-0" type="button"
            id="tempalte-groups-reset-filters" (click)="resetFilters()">{{ 'action.reset' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container">

      <app-page-loader *ngIf="isLoading"></app-page-loader>

      <div *ngIf="!isLoading">
        <div *ngFor="let group of group?.groups">
          <button
            class="template-group btn w-100 d-flex justify-content-between border-bottom px-0 py-1 text-decoration-none text-body"
            (click)="selectGroup(group)">
            <p class="m-0 d-flex align-items-center">
              <span class="mr-1" [inlineSVG]="'/assets/svg/folder-red.svg'"></span>
              <span>{{ group?.name }}</span>
            </p>

            <span class="chevron-right" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          </button>
        </div>

        <div *ngFor="let template of group?.templates">
          <button
            class="template btn w-100  d-flex justify-content-between border-bottom px-0 py-1 text-decoration-none text-body"
            (click)="selectTemplate(template)">
            <p class="m-0 d-flex align-items-center text-truncate">
              <span class="mr-1" [inlineSVG]="'/assets/svg/template.svg'"></span>
              <span>{{ template.name }}</span>
            </p>

            <span class="chevron-right" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          </button>
        </div>

        <div *ngIf="group?.templates && !group?.templates?.length">
          <p class="text-center mt-4">{{ 'pages.default.templates.no_templates_found' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
