import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { Hospital } from '../../models/hospital';
import { ModuleService } from '../../services/module.service';
import { Module } from '../../models/module';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { atLeastOneOtherRequiredValidator } from '../../validators/at-least-one-other-validator';

@Component({
  selector: 'app-edit-hospital-features',
  templateUrl: './edit-hospital-features.component.html',
  styleUrls: ['./edit-hospital-features.component.scss']
})
export class EditHospitalFeaturesComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public hospital: Hospital;
  public isSaving: boolean = false;
  public modules: Array<Module>;
  public validationVisible = false;
  public form: FormGroup = this.formBuilder.group({
    messaging: new FormControl(false),
    appointment: new FormControl(false),
    analytics: new FormGroup({
      general: new FormControl(false),
      pathways_overview: new FormControl(false),
      patient_onboarding: new FormControl(false)
    }, atLeastOneOtherRequiredValidator('general')),
  });

  constructor(
    public bsModalRef: BsModalRef,
    private moduleService: ModuleService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initialiseForm();
  }

  initialiseForm() {
    this.form.setValue({
      messaging: this.isModuleActive('MESSAGING'),
      appointment: this.isModuleActive('APPOINTMENT'),
      analytics: {
        general: this.isModuleActive('ANALYTICS'),
        pathways_overview: this.isModuleActive('ANALYTICS', 'PATHWAYS_OVERVIEW'),
        patient_onboarding: this.isModuleActive('ANALYTICS', 'PATIENT_ONBOARDING')
      }
    });

    this.setupAnalyticsSubscriptions();
  }

  isModuleActive(moduleName: string, featureName?: string): boolean {
    const module = this.modules?.find(m => m.name === moduleName);

    if (!module) return false;

    if (featureName) {
      return module.features?.find(f => f.name.toUpperCase() === featureName.toUpperCase())?.enabled ?? false;
    }

    return module.enabled;
  }

  setupAnalyticsSubscriptions(): void {
    const analytics = this.form.get('analytics');
    const generalControl = analytics.get('general');
    const controls = ['pathways_overview', 'patient_onboarding' ];

    generalControl.valueChanges.subscribe(value => {
      this.handleGeneralControl(value);
      // if (!value) {
      //   controls.forEach(control => {
      //     const ctrl = analytics.get(control);
      //     if (ctrl.value) {
      //       ctrl.setValue(false, { emitEvent: false });
      //       ctrl.updateValueAndValidity({ emitEvent: false });
      //     }
      //   });
      // }
    });

    controls.forEach(control => {
      analytics.get(control).valueChanges.subscribe(value => {
        this.handleAnalyticsControls(value);
        // if (value && !generalControl.value) {
        //   generalControl.setValue(true, { emitEvent: false });
        //   generalControl.updateValueAndValidity({ emitEvent: false });
        // }
      });
    });
  }

  handleGeneralControl(value: boolean) {
    const analytics = this.form.get('analytics');
    const controls = ['pathways_overview', 'patient_onboarding' ];

    if (!value) {
      controls.forEach(control => {
        const ctrl = analytics?.get(control);
        if (ctrl?.value) {
          ctrl.setValue(false, { emitEvent: false });
          ctrl.updateValueAndValidity({ emitEvent: false });
        }
      });
    }
  }

  handleAnalyticsControls(value: boolean) {
    const generalControl = this.form.get('analytics.general');

    if (value && !generalControl?.value) {
      generalControl?.setValue(true, { emitEvent: false });
      generalControl?.updateValueAndValidity({ emitEvent: false });
    }
  }

  formSubmit() {
    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    const analyticsModuleStatus: boolean = this.form.get('analytics.general')?.value;

    delete this.form.get('analytics')?.value.general;

    const analyticsFeatures: Array<{ name: string, enabled: boolean }> = [
      {
        name: 'pathways_overview',
        enabled: this.form.get('analytics.pathways_overview')?.value
      },
      {
        name: 'patient_onboarding',
        enabled: this.form.get('analytics.patient_onboarding')?.value
      },
    ];

    forkJoin([
      this.moduleService.setModuleStatus(this.hospital.uid, 'messaging', this.form.get('messaging')?.value),
      this.moduleService.setModuleStatus(this.hospital.uid, 'appointment', this.form.get('appointment')?.value),
      this.moduleService.setFeaturedModuleStatus(this.hospital.uid, 'analytics', analyticsModuleStatus, analyticsFeatures)
    ]).subscribe({
      next: () => {
        this.onHospitalUpdated.emit();
        this.isSaving = false;
        this.onHandleClose();
      }, error: () => this.isSaving = false
    })
  };

  onHandleClose() {
    this.bsModalRef.hide();
  }
}
