<div class="pathway-template-detail" *ngIf="isLoading">
  <app-page-loader *ngIf="isLoading"></app-page-loader>
</div>

<div class="pathway-template-detail" *ngIf="!isLoading">
  <div class="layout-header-pusher header-filters" [ngClass]="template.group ? 'header-filters' : 'header-title-only'">
  </div>

  <div class="layout-header" [ngClass]="template.group ? 'header-filters' : 'header-title-only'">
    <div class="header-content">
      <div class="container">
        <button class="btn btn-link p-start-0" (click)="selectGroup(template.group)" *ngIf="template?.group">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          {{ 'pages.default.template.back_to_folder' | translate }}
        </button>

        <div class="title-button mb-2">
          <h2 class="text-truncate pb-0">{{ template.name }}</h2>
          <div class="d-flex">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container">
      <h3>{{ 'pages.default.template.details' | translate }}</h3>
      <div class="action-item-block mb-4">
        <div class="block-wrapper p-start-3 p-end-1 w-100">
          <div class="row align-items-start">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ template.name }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="tiny m-0">{{ 'form.labels.description' | translate }}</label>
                  <p id="description">{{ template.description }}</p>
                </div>
              </div>

              <label class="tiny m-0">{{ 'form.labels.subprocess' | translate }}</label>
              <p>
                <span *ngIf="template?.is_sub_process" class="icon"
                  [inlineSVG]="'/assets/svg/check-free.svg'"></span>
                <span *ngIf="!template?.is_sub_process" class="icon"
                  [inlineSVG]="'/assets/svg/false.svg'"></span>
              </p>
            </div>
          </div>
        </div>

        <a class="block-action m-start-auto" href="" (click)="onEditTemplate($event, template)">
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-2">
        <h3 class="mb-0">{{ 'pages.default.template.major_versions' | translate }}</h3>

        <button class="btn btn-secondary" (click)="addTemplateVersion()">
          {{ 'pages.default.template.add_new_version' | translate }}
        </button>
      </div>

      <ul class="major-versions list-unstyled">
        <li class="list-header row no-gutters text-muted small p-1 p-start-2 mb-1">
          <div class="col-2">{{ 'pages.default.template.version' | translate }}</div>
          <div class="col-5">{{ 'pages.default.template.reason' | translate }}</div>
          <div class="col-3">{{ 'pages.default.template.created_on' | translate }}</div>
        </li>
      </ul>

      <ul class="major-versions list-unstyled">
        <li *ngFor="let version of template.versions" class="mb-1">
          <div class="row bg-white no-gutters p-start-2 align-items-center">
            <div class="col-2">
              <p class="mb-0 text-truncate">{{ version.major_version }}</p>
            </div>
            <div class="col-5">
              <p class="mb-0 text-truncate">
                <span *ngIf="!version.reason || version.reason === ''">-</span>
                {{ version.reason }}
              </p>
            </div>
            <div class="col-3">
              <p class="mb-0 text-truncate">{{ version.created_at | timeZoneDate }}</p>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <button class="btn btn-secondary py-2" (click)="navigateToVersion(version)">
                <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
