<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <h2>{{ 'modals.gdpr_admin.restrict.title' | translate }}</h2>
  <p class="m-0 mb-4">{{ 'modals.gdpr_admin.restrict.description' | translate }}</p>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label required">
      <input [placeholder]="'modals.gdpr_admin.restrict.form.email' | translate" class="form-control w-100" formControlName="email" type="text">
      <label for="inputEmail" class="required">{{ 'modals.gdpr_admin.restrict.form.email' | translate }}</label>
    </div>
  
    <hr />
    
    <label class="small d-block required">{{ 'modals.gdpr_admin.export.form.user_type' | translate }}</label>
    
    <div class="custom-control custom-radio mb-1">
      <input type="radio" class="custom-control-input" id="patient-type" formControlName="userType" [value]="userType.PATIENTS">
      <label class="custom-control-label" for="patient-type">{{ 'modals.gdpr_admin.export.form.patient' | translate }}</label>
    </div>
    
    <div class="custom-control custom-radio mb-1">
      <input type="radio" class="custom-control-input" id="hcp-type" formControlName="userType" [value]="userType.HCPS">
      <label class="custom-control-label" for="hcp-type">{{ 'modals.gdpr_admin.export.form.hcp' | translate }}</label>
    </div>
    
    <app-feedback-field [field]="form.get('userType')"></app-feedback-field>
    
    <hr />

    <div class="form-group styled floating-label required">
      <ng-select 
        formControlName="hospitals"
        (change)="onAddHospital()" 
        (search)="searchHospitalEvent.next($event)"
        [clearable]="true" 
        [items]="hospitals"
        [placeholder]="'modals.gdpr_admin.restrict.form.hospital' | translate" 
        [searchFn]="customSearchFn"
        class="users-select"
      >

        <ng-template let-item="item" ng-label-tmp>
          <span>{{item?.name}}</span>
        </ng-template>

        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          <div class="wrapper">
            <div class="user-info">
              <p class="title">{{item?.name}}</p>
              <p class="sub-text">{{item?.uid}}</p>
            </div>
            <div *ngIf="isHospitalSelected(item)" class="status small text-muted">
              <span>{{ 'action.already_added' | translate }}</span>
            </div>
            <div *ngIf="!isHospitalSelected(item)" class="status text-secondary">
              <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
              <span>{{ 'action.add' | translate }}</span>
            </div>
          </div>
        </ng-template>
        
      </ng-select>
    </div>

    <div *ngIf="hospitalsSelected.length">
      <div *ngFor="let item of hospitalsSelected" class="participant-item">
        <div class="meta">
          <p class="title">{{ item?.name }}</p>
          <p class="description">{{item?.uid}}</p>
        </div>
        <a (click)="onRemoveHospital($event, item)" class="status text-secondary" href="#remove">
          <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </a>
      </div>
    </div>
    
    <div class="form-group styled mt-3">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="confirm" formControlName="confirm">
        <label class="custom-control-label font-weight-normal" for="confirm">{{ 'modals.gdpr_admin.export.form.confirm' | translate }}</label>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleClose()">{{ 'action.cancel' | translate}}</button>
    <button class="btn btn-secondary m-start-1" [ngClass]="{'loader': isLiftLoading}" (click)="handleLift()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLiftLoading"></em></span>
      <span>{{ 'modals.gdpr_admin.restrict.action.lift' | translate }}</span>
    </button>
    <button class="btn btn-secondary m-start-1" [ngClass]="{'loader': isRestrictLoading}" (click)="handleRestrict()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isRestrictLoading"></em></span>
      <span>{{ 'modals.gdpr_admin.restrict.action.restrict' | translate }}</span>
    </button>
  </div>
</div>
