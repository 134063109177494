<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_registration_code' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled toggle" id="hospital-code-days-valid-indefinite">
      <label class="toggle-label mb-0">{{ 'form.labels.expiry_date' | translate }}</label>
      <app-toggle-switch formControlName="hospital_code_days_valid_indefinite" [inverse]="true" ></app-toggle-switch>
    </div>
    
    <div class="form-group styled floating-label" *ngIf="!form.get('hospital_code_days_valid_indefinite')?.value">  
      <input type="number" id="hospital_code_days_valid" class="form-control w-100" [placeholder]="'form.labels.hospital_code_days_valid' | translate" formControlName="hospital_code_days_valid">
      <label for="hospital_code_days_valid" class="required">{{ 'form.labels.hospital_code_days_valid' | translate }}</label>
      <app-feedback-field [field]="form.get('hospital_code_days_valid')" [meta]="{field_value: {min: hospitalCodeDaysValidMin, max: hospitalCodeDaysValidMax}}"></app-feedback-field>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
