import { TranslationInterface, TranslationItem } from "../interfaces/translation.interface";
import { EducationalMaterialPhase } from "./educational-material-phase";
import { HospitalCode } from "./hospital-code";

export enum CareModuleType {
  CLINICAL = 'CLINICAL',
  EDUCATION = 'EDUCATION'
}
export class CareModule {
  public uid: string;
  public name: TranslationInterface;
  public description: TranslationInterface;
  public hospital_code: HospitalCode;
  public pathway_template_uid: string;
  public pathway_template_version: number;
  public educational_material_phases: Array<EducationalMaterialPhase> = [];
  public original: any;
  public configuration_parameters: any;
  public default_case_manager_uid: string;
  public default_clinical_lead_uid: string;
  public type: CareModuleType;

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.original = item;

    this.uid = item.uid;
    this.name = item.name;
    this.description = item.description;
    this.pathway_template_uid = item.pathway_template_uid;
    this.configuration_parameters = item.configuration_parameters;
    this.pathway_template_version = item.pathway_template_version;
    this.default_case_manager_uid = item.default_case_manager_uid;
    this.default_clinical_lead_uid = item.default_clinical_lead_uid
    this.type = item.type;
    this.default_case_manager_uid = item.default_case_manager_uid;
    this.default_clinical_lead_uid = item.default_clinical_lead_uid;

    if (item.care_module_educational_materials && item.care_module_educational_materials.length > 0) {
      item.care_module_educational_materials.forEach((cmem: any) => {
        this.educational_material_phases.push(new EducationalMaterialPhase(cmem));
      });
    }
  }

  get defaultName(): string {
    return this.getTranslationFor('EN', this.name.translations) ?? '';
  }

  get defaultDescription(): string {
    return this.getTranslationFor('EN', this.description.translations) ?? '';
  }

  getTranslationFor(language: string, translations: Array<TranslationItem>): string {
    let response = '';

    translations.forEach(translation => {
      if (translation.language === language) {
        response = translation.translation;
      }
    });
    return response
  }
} 
