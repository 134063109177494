import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorService } from '../../services/error.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HospitalService } from '../../services/hospital.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Hospital } from '../../models/hospital';
import { GDPRService } from '../../services/gdpr.service';
import { UserType } from '../../enum/UserType';

@Component({
  selector: 'app-gdpr-export-modal',
  templateUrl: './gdpr-export-modal.component.html'
})
export class GDPRExportModalComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Output() public completed = new EventEmitter();

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    userType: new FormControl('', [Validators.required]),
    hospitals: new FormControl(''),
    confirm: new FormControl(false, [Validators.requiredTrue]),
  });
  public isLoading = false;
  public validationVisible = false;

  public hospitals: Array<Hospital> = [];
  public hospitalsSelected: Array<Hospital> = [];

  public userType = UserType;

  public searchHospitalEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public bsModalRef: BsModalRef,
    public hospitalService: HospitalService,
    public gdprService: GDPRService,
    public errorService: ErrorService,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getHospitals();

    this.searchHospitalEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHospitals(result?.term);
    });
  }

  getHospitals(term: string = ''): void {
    this.hospitalService.getHospitals({ name: term }, 'name,asc', 0, 50).subscribe(result => {
      this.hospitals = result.hospitals;
    });
  }

  searchHospitals(searchTerm?: string): void {
    if (!searchTerm) {
      this.hospitals = [];
    }

    this.getHospitals(searchTerm);
  }

  handleSubmit(): void {
    if (this.hospitalsSelected.length == 0) {
      this.form.controls['hospitals'].setErrors({ 'incorrect': true });
      this.form.controls['hospitals'].markAsTouched();
    } else {
      this.form.controls['hospitals'].setErrors(null);
    }

    if (!this.form.valid || this.hospitalsSelected.length == 0) {
      this.validationVisible = true;
      return;
    } else {
      this.validationVisible = false;
    }

    this.isLoading = true;

    this.gdprService.exportAll(
      this.form.get('email')?.value,
      this.hospitalsSelected.map(i => i.uid),
      this.form.get('userType').value
    ).subscribe({
      next: () => {
        this.successHandler();
      }, error: () => this.errorHandler()
    });
  }

  successHandler(): void {
    this.completed.emit();
    this.isLoading = false;
    this.bsModalRef.hide();

    this.toastrService.info(this.translate.instant('modals.gdpr_admin.export.success'), '', {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  errorHandler(): void {
    this.isLoading = false;
  }

  customSearchFn(term: string, item: any): boolean {
    return true; // always return, searching is done at the backend
  }

  handleClose(): void {
    return this.bsModalRef.hide();
  }

  onAddHospital(): void {
    if (!this.form.get('hospitals')?.value) {
      return;
    }

    if (this.hospitalsSelected.find(item => item.uid === this.form.get('hospitals')?.value?.uid) !== undefined) {
      this.clearHospitalSelect();
      return;
    }

    this.hospitalsSelected.push(this.form.get('hospitals').value);

    this.clearHospitalSelect();
    this.getHospitals();
  }

  clearHospitalSelect() {
    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }
  }

  isHospitalSelected(hospital: Hospital): boolean {
    const filtered = this.hospitalsSelected?.filter(item => item.uid === hospital.uid);
    return filtered?.length > 0;
  }

  onRemoveHospital(event: Event, hospital: Hospital): void {
    event.preventDefault();

    const index = this.hospitalsSelected.indexOf(hospital);

    if (index >= 0) {
      this.hospitalsSelected.splice(index, 1);
    }
  }
}
