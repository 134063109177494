import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flow-layout',
  templateUrl: './flow-layout.component.html'
})
export class FlowLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
