import { TranslationInterface } from "../interfaces/translation.interface";

export class EducationalMaterial {
  public id: string;
  public order: number;
  public reference: TranslationInterface;
  public translatedName: string = '';

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.id = item.id;
    this.order = item.order;
    this.reference = item.reference;
  }
}
