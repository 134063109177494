import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HospitalService } from "../../../app/services/hospital.service";
import { Hospital } from "../../models/hospital";

@Component({
  selector: 'app-edit-hospital-general-information-modal',
  templateUrl: './edit-hospital-general-information-modal.component.html'
})
export class EditHospitalGeneralInformationModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService
  ) { }
  
  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      active: [String(this.hospital.active), [Validators.required]],
      name: [this.hospital.name, [Validators.required]],
      site: new UntypedFormGroup({
        name: new UntypedFormControl(this.hospital.site?.name, Validators.required),
        url: new UntypedFormControl(this.hospital.site?.url, Validators.required)
      }),
      logo: new UntypedFormGroup({
        key: new UntypedFormControl(this.hospital.logo?.key),
        region: new UntypedFormControl('hospital_logo')
      }),
      metadata: new UntypedFormGroup({
        key: new UntypedFormControl(this.hospital.metadata?.key),
        region: new UntypedFormControl('hospital')
      }),
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl(this.hospital.phone_number?.code, Validators.required),
        number: new UntypedFormControl(this.hospital.phone_number?.number, Validators.required)
      }),
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);

    clonedHospital.active = this.form.get('active')?.value;
    clonedHospital.name = this.form.get('name')?.value;
    clonedHospital.site.name = this.form.get('site.name')?.value;
    clonedHospital.site.url = this.form.get('site.url')?.value;
    clonedHospital.logo.key = this.form.get('logo.key')?.value;
    clonedHospital.metadata.key = this.form.get('metadata.key')?.value;
    clonedHospital.phone_number.code = this.form.get('phone_number.code')?.value;
    clonedHospital.phone_number.number = this.form.get('phone_number.number')?.value;


    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public  showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
