import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Module } from '../models/module';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { ModuleFeature } from '../models/module-feature';
import { ModuleFeaturePermission } from '../models/module-feature-permission';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public getModule(hospitalUid: string, module: 'messaging' | 'appointment' | 'analytics'): Observable<Module | undefined> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/modules/${module}`;

      this.authenticatedGet(url).subscribe({
        next: result => {
          observer.next(new Module(result));
          observer.complete();
        }, error: () => {
          observer.next(undefined);
          observer.complete();
        }
      });
    });
  }

  public setModuleStatus(hospitalUid: string, moduleName: 'messaging' | 'appointment' | 'analytics', status: boolean): Observable<void> {
    return new Observable(observer => {

      this.getModule(hospitalUid, moduleName).subscribe((module) => {
        // Update
        if (module) {
          module.enabled = status;

          this.updateModule(hospitalUid, moduleName, module).subscribe({
            complete: () => {
              observer.next();
              observer.complete();
            }
          });
        } else {
          // Create new if status === true
          if (status) {
            this.createDefaultModule(hospitalUid, moduleName, status).subscribe({
              next: () => {
                observer.next();
                observer.complete();
              }, error: () => {
                observer.next();
                observer.complete();
              }
            });
          } else {
            observer.next();
            observer.complete();
          }
        }
      });
    });
  }

  public setFeaturedModuleStatus(
    hospitalUid: string,
    moduleName: 'messaging' | 'appointment' | 'analytics',
    moduleStatus: boolean,
    features: Array<{ name: string, enabled: boolean }>
  ): Observable<void> {
    return new Observable(observer => {
      this.getModule(hospitalUid, moduleName).subscribe((module) => {

        // Update module
        if (module) {
          module.enabled = moduleStatus;

          // update features
          if (module.features?.length) {
            module.features.forEach((f: ModuleFeature) => {
              const match = features.find(feature => feature.name.toUpperCase() === f.name.toUpperCase());

              if (match) {
                f.enabled = match.enabled

                features = features.filter(feature => feature.name.toUpperCase() !== f.name.toUpperCase())
              }
            });
          }

          // create new features when not all features were used
          if (features.length) {
            features.forEach(feature => {
              const newFeature = new ModuleFeature({
                name: feature.name,
                enabled: feature.enabled
              });
              module.features.push(newFeature);
            })
          }

          this.updateModule(hospitalUid, moduleName, module).subscribe({  
            complete: () => {
              observer.next();
              observer.complete();
            }
          });
        } else if (moduleStatus) {
          // Create new if moduleStatus === true
          this.createModuleWithFeatures(hospitalUid, moduleName, moduleStatus, features).subscribe({
            next: () => {
              observer.next();
              observer.complete();
            }, error: () => {
              observer.next();
              observer.complete();
            }
          });

        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }

  public createDefaultModule(hospitalUid: string, moduleName: string, status: boolean): Observable<Module> {
    return new Observable(observer => {
      const data = {
        'enabled': status,
        'reminder_in_days': 2,
        'features': [
          {
            'name': 'WRITE',
            'enabled': true,
            'permissions': [
              ModuleFeaturePermission.default
            ]
          }
        ]
      } as Module;

      this.createModule(hospitalUid, moduleName, data).subscribe({
        next: result => {
          observer.next(result);
          observer.complete();
        }, error: error => {
          observer.error(error);
          observer.complete();
        }
      })
    });
  }

  public createModuleWithFeatures(
    hospitalUid: string,
    moduleName: string,
    moduleStatus: boolean,
    features: Array<{ name: string, enabled: boolean }>
  ): Observable<Module> {
    return new Observable(observer => {
      const featuresArray = [];

      features.forEach(feature => {
        const f = new ModuleFeature({
          name: feature.name,
          enabled: feature.enabled,
        });

        featuresArray.push(f);
      });

      const module = new Module({
        name: moduleName,
        enabled: moduleStatus,
        features: featuresArray
      });

      this.createModule(hospitalUid, moduleName, module).subscribe({
        next: result => {
          observer.next(result);
          observer.complete();
        }, error: error => {
          observer.error(error);
          observer.complete();
        }
      })
    });
  }

  public createModule(hospitalUid: string, moduleName: string, module: Module): Observable<Module> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/modules/${moduleName}`;

      this.authenticatedPost(url, module).subscribe({
        next: (result: HttpResponse<any>) => {
          observer.next(new Module(result.body));
          observer.complete();
        }, error: error => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }

  public updateModule(hospitalUid: string, moduleName: string, data: any): Observable<Module> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/modules/${moduleName}`;

      this.authenticatedPut(url, data).subscribe({
        next: result => {
          observer.next(new Module(result));
          observer.complete();
        }, error: error => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }
}
