import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import PaginationInterface from '../interfaces/pagination.interface';
import { Application } from '../models/application';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public search(field: 'name' | 'uid', term: string): Observable<Array<Application>> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/platform/applications?${field}=${term}&page=0&size=25`;
      this.authenticatedGet(url).subscribe(result => {
        const applications = this.mapApplications(result.items);
        observer.next(applications);
        observer.complete();
      });
    });
  }

  public getApplications(page: number = 0, size: number = 25): Observable<{applications: Array<Application>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/platform/applications?${paramsString}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const applications = this.mapApplications(result.items);
        observer.next({
          applications: applications,
          pagination: result.pagination
        });
        observer.complete();
      });
    });
  }

  public getApplication(appUid: string): Observable<Application> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/platform/applications/${appUid}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospital = this.mapApplication(result);
        observer.next(hospital);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public createApplication(form: any) {
    const roles: Array<string> = [];

    if (form.role_patient && form.role_patient !== null) {
      roles.push('ROLE_PATIENT');
    }

    if (form.role_hcp && form.role_hcp !== null) {
      roles.push('ROLE_HCP');
    }

    if (form.role_cc && form.role_cc !== null) {
      roles.push('ROLE_CC');
    }

    const data = {
      name: form.name,
      secret: form.secret,
      type: form.type,
      frontend_url: form.application_url,
      accessible_by: [{
        roles
      }],
      session_time_out_minutes: form.session_time_out_minutes,
      active: form.active,
      minimum_version: form.minimum_required_version
    };

    return new Observable(observer => {
      this.authenticatedPost(`${this.platformUrl}/platform/applications`, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public update(application: Application) {
    return new Observable(observer => {
      this.authenticatedPut(`${this.platformUrl}/platform/applications/${application.uid}`, application).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public updateApplication(form: any, uid: string): Observable<any> {
    const roles: Array<string> = [];

    if (form.role_patient && form.role_patient !== null) {
      roles.push('ROLE_PATIENT');
    }

    if (form.role_hcp && form.role_hcp !== null) {
      roles.push('ROLE_HCP');
    }

    if (form.role_cc && form.role_cc !== null) {
      roles.push('ROLE_CC');
    }

    const data = {
      uid,
      name: form.name,
      secret: form.secret,
      type: form.type,
      frontend_url: form.application_url,
      accessible_by: [{
        roles
      }],
      session_time_out_minutes: form.session_time_out_minutes,
      active: form.active,
      minimum_version: form.minimum_required_version
    };

    return new Observable(observer => {
      this.authenticatedPut(`${this.platformUrl}/platform/applications/${uid}`, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public mapApplications(list: any[]): Array<Application> {
    const applications = new Array();
    list.forEach(item => applications.push(this.mapApplication(item)));
    return applications;
  }

  public mapApplication(input: any): Application {
    return new Application(input);
  }
}
