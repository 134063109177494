import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { Faq } from "../models/faq";

@Injectable({
  providedIn: 'root'
})
export class FaqService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getFaqs(hospitalUid: string, careModuleUid: string): Observable<{faqs: Array<Faq>}> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/faqs`;
      this.authenticatedGet(url).subscribe(result => {
        const faqs = this.mapFaqs(result);
        faqs.sort((a:Faq, b:Faq) => (Number(a.order) - Number(b.order)))
        observer.next({
          faqs: faqs
        });
        observer.complete();
      });
    });
  }

  public createFaq(hospitalUid: string, careModuleUid:string, data: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/faqs`;
      this.authenticatedPost(url, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public updateFaq(hospitalUid: string, careModuleUid:string, faqUid:string,data: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/faqs/${faqUid}`;
      this.authenticatedPut(url, data).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public removeFaq(hospitalUid: string, careModuleUid:string, faqUid:string) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}/faqs/${faqUid}`;
      this.authenticatedDelete(url).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  private mapFaqs(list: any[]): Array<Faq> {
    const faqs = new Array();
    if(!list || !Array.isArray(list)) {
      return [];
    }
    list.forEach(item => {
      faqs.push(this.mapFaq(item));
    });
    return faqs;
  }

  private mapFaq(input: any): Faq {
    return new Faq(input);
  }
}