import { Component } from '@angular/core';

@Component({
  selector: 'app-flow-welcome',
  templateUrl: './flow-welcome.component.html'
})
export class FlowWelcomeComponent {

  constructor() { }

}
