import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PathwayTemplate } from '../../models/pathway-template';
import { PathwayTemplateErrorResponse } from '../../models/pathway-template-error-response';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-pathway-template-error-modal',
  templateUrl: './pathway-template-error-modal.component.html'
})
export class PathwayTemplateErrorModalComponent {
  @Output() onNewFileSelected = new EventEmitter<PathwayTemplate>();
  @Output() onCloseAfterWarnings = new EventEmitter();

  public isSaving: boolean = false;
  public warningsIsCollapsed: boolean = true;
  public errorsIsCollapsed: boolean = true;

  public error: PathwayTemplateErrorResponse;
  public pathwayTemplate: PathwayTemplate;

  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
  ) { }

  get hasErrors(): boolean {
    return this.error?.errors?.length > 0;
  }

  get hasWarnings(): boolean {
    return this.error?.warnings?.length > 0;
  }

  exportList(): void {
    var options = {
      headers: [
        'Component ID',
        'Component type',
        'Validation rule',
        'Type',
        'Reason',
        'Reason key'
      ],
    };

    new AngularCsv(this.error.results, `Error-Report-${this.pathwayTemplate.name}`, options);
  }

  onHandleUploadNewFile(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.readAsText(file);
      reader.onload = (evt: any) => {
        const data = Base64.encode(evt.target.result);

        this.pathwayTemplate.file = data;

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();

        this.onNewFileSelected.emit(this.pathwayTemplate);
        this.onHandleClose();
      };
    }
  }

  onHandleClose(): void {
    if (this.hasWarnings && !this.hasErrors) {
      this.bsModalRef.hide();
      this.onCloseAfterWarnings.emit();
    } else {
      this.bsModalRef.hide();
    }
  }
}
