import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected store: any;

  public static KeyHcpOnboardingBoolean = "hcp_onboarding_boolean";
  public static KeyConsentSelected = "consent_selected";
  public static KeyLearningMaterialSelected = "learning_material_selected";
  public static HelpcenterSectionSelected = "helpcenter_section_selected";
  public static HelpcenterArticleSelected = "helpcenter_article_selected";
  public static HelpcenterFaqSelected = "helpcenter_faq_selected";
  public static HelpcenterFaqCategorySelected = "helpcenter_faq_category_selected";

  constructor() {
    this.clearAll();
  }

  set(slug:string, data: any) {
    return this.store[slug] = data;
  }

  get(slug:string) {
    return this.store[slug];
  }

  clear(slug:string) {
    return delete this.store[slug];
  }

  clearAll() {
    this.store = {};
    return this.store;
  }

  clearHcpOnboardingKeys() {
    this.clear(DataService.KeyHcpOnboardingBoolean);
  }
}
