import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PathwayTemplate } from '../../models/pathway-template';
import {
  ConfigurationsParameterType,
  ConfigurationsParameterValidators,
  PathwayConfigurationParameter
} from '../../interfaces/pathway-configuration-parameter.interface';
import { PathwayTemplateVersion } from '../../models/pathway-template-version';
import { GeneralService } from '../../services/general.service';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { SelectPathwayFolderModalComponent } from '../../modals/select-pathway-folder-modal/select-pathway-folder-modal.component';

@Component({
  selector: 'app-care-module-pathway',
  templateUrl: './care-module-pathway.component.html'
})
export class CareModulePathwayComponent implements OnInit {
  @Input() pathwayTemplate: PathwayTemplate;
  @Output() pathwayTemplateChange = new EventEmitter<PathwayTemplate>();

  @Input() pathwayTemplateValid: boolean;
  @Output() pathwayTemplateValidChange = new EventEmitter<boolean>();

  @Input() configurationParameters: PathwayConfigurationParameter[];
  @Output() configurationParametersChange = new EventEmitter<PathwayConfigurationParameter[]>();

  @Input() currentConfigurationParameters: any;

  public isLoadingParameters: boolean;
  public validationVisible: boolean;

  public configurationsParameterType = ConfigurationsParameterType;
  public paramForm: FormGroup;
  public configurationParametersClone: PathwayConfigurationParameter[];

  constructor(
    public modalService: BsModalService,
    public pathwayTemplateService: PathwayTemplateService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    if (this.pathwayTemplate) {
      this.getVersions(this.pathwayTemplate);
      
      if (this.pathwayTemplate.major_version) {
        this.getConfigurationParameters();
      }
    }

    if (this.configurationParameters) {
      this.configurationParametersClone = cloneDeep(this.configurationParameters);
      this.setupParamForm(this.configurationParameters);
    }
  }

  openPathwaySelector(): void {
    const modalRef = this.modalService.show(SelectPathwayFolderModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
      })
    );

    modalRef.content.onTemplateSelected.subscribe((pathwayTemplate: PathwayTemplate) => {
      this.pathwayTemplate = pathwayTemplate;
      this.pathwayTemplateChange.emit(this.pathwayTemplate);

      this.getVersions(this.pathwayTemplate);

    });
  }

  isLatestVersion(): boolean {
    const majorVersions = this.pathwayTemplate?.versions?.map(version => Number(version.major_version));

    if (majorVersions && Number(this.pathwayTemplate?.major_version) === Math.max(...majorVersions)) {
      return true;
    }
    return false;
  }

  getVersions(pathwayTemplate: PathwayTemplate): void {
    if (!this.pathwayTemplate?.versions?.length) {
      this.pathwayTemplateService.getVersions(pathwayTemplate.uid).subscribe((versions: PathwayTemplateVersion[]) => {
        this.pathwayTemplate.versions = versions;

        if (this.pathwayTemplate?.versions?.length && !this.pathwayTemplate?.major_version && this.pathwayTemplate.getLatestMajorVersion !== null) {
          this.pathwayTemplate.major_version = this.pathwayTemplate.getLatestMajorVersion;
          this.getConfigurationParameters();
        }
        this.pathwayTemplateChange.emit(this.pathwayTemplate);
      });
    }
  }
  
  handleVersionChange() {
    this.pathwayTemplateChange.emit(this.pathwayTemplate);
    this.getConfigurationParameters();
  }

  getConfigurationParameters(showLoader: boolean = true): void {
    if (showLoader) {
      this.isLoadingParameters = true;
    }

    this.pathwayTemplateService.getVersionDetails(this.pathwayTemplate.uid, this.pathwayTemplate.major_version).subscribe((majorVersion: PathwayTemplateVersion) => {
      this.configurationParameters = majorVersion.configuration_parameters;
      this.configurationParametersClone = cloneDeep(this.configurationParameters);

      this.mapCurrentConfigurationParameters();
      this.configurationParametersChange.emit(this.configurationParameters);

      if (this.configurationParameters?.length) {
        this.setupParamForm(this.configurationParameters);
      } else {
        this.pathwayTemplateValid = true;
        this.pathwayTemplateValidChange.emit(true);
      }

      this.isLoadingParameters = false;

    }, () => this.isLoadingParameters = false);
  }

  mapCurrentConfigurationParameters() {
    if (this.currentConfigurationParameters && typeof this.currentConfigurationParameters === 'object' && Object.keys(this.currentConfigurationParameters).length) {
      for (const [key, value] of Object.entries(this.currentConfigurationParameters)) {
        const param = this.configurationParameters.find((item => item.key === key));

        if (param) {
          param.default_value = value;
        }
      } 
    }
  }

  setupParamForm(parameters: PathwayConfigurationParameter[]): void {
    this.paramForm = new FormGroup({});

    parameters?.forEach((param: PathwayConfigurationParameter) => {
      const control = new FormControl(param.default_value, Validators.required);

      if (param.data_type === this.configurationsParameterType.INTEGER) {
        control.setValidators([Validators.pattern(ConfigurationsParameterValidators.VALIDATOR_INTEGER), Validators.required]);
        control.updateValueAndValidity();
      }

      if (param.data_type === this.configurationsParameterType.DOUBLE) {
        control.setValidators([Validators.pattern(ConfigurationsParameterValidators.VALIDATOR_DOUBLE), Validators.required]);
        control.updateValueAndValidity();
      }

      if (param.data_type === this.configurationsParameterType.DATE_TIME) {
        control.setValue(moment.utc(param.default_value).toDate());
      }

      this.paramForm.addControl(param.key, control);
      control.updateValueAndValidity();
    });

    this.paramForm.updateValueAndValidity();

    if (this.paramForm.valid) {
      setTimeout(() => {
        this.pathwayTemplateValidChange.emit(this.paramForm.valid);
        this.updateParameters();
      });
    }

    this.paramForm.valueChanges.subscribe(() => {
      this.pathwayTemplateValidChange.emit(this.paramForm.valid);

      if (this.paramForm.status === 'VALID') {
        this.validationVisible = false;
        this.updateParameters();
      }

      if (this.paramForm.status === 'INVALID') {
        this.validationVisible = true;
      }
    });
  }

  resetChanges(): void {
    if (this.configurationParametersClone) {
      this.paramForm.reset();
      this.setupParamForm(this.configurationParametersClone);
      this.configurationParameters = this.configurationParametersClone;
      this.configurationParametersChange.emit(this.configurationParameters);
    }
  }

  mapFormToParameters(
    configurationParameters: PathwayConfigurationParameter[],
    defaultValues: Array<{ key: string, value: any }>
  ): PathwayConfigurationParameter[] {
    configurationParameters?.forEach((parameter: PathwayConfigurationParameter) => {
      const match = defaultValues.find(defaultValue => defaultValue.key === parameter.key);

      if (match) {
        if (parameter.data_type === this.configurationsParameterType.DATE_TIME) {
          parameter.default_value = moment.utc(match.value).format();
        } else {
          parameter.default_value = match.value;
        }
      }
    });

    return configurationParameters;
  }

  updateParameters(): void {
    const defaultValuesArray: Array<{ key: string, value: any }> =
      Object.entries(this.paramForm.value).map(([key, value]) => ({ key, value }));

    this.configurationParameters = this.mapFormToParameters(this.configurationParameters, defaultValuesArray);
    this.configurationParametersChange.emit(this.configurationParameters);
  }
}
