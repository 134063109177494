import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService extends ApiService {
  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    private router: Router,
  ) {
    super(http, authenticationService);
  }

  appInitializer() {
    return new Promise((resolve, reject) => {
      this.getMaintenanceStatus().subscribe((result) => {
        if (result.unavailable) {
          this.router.navigateByUrl('/maintenance');
        }

        resolve(true);
      });
    });
  }

  getMaintenanceStatus(): Observable<any> {
    return new Observable(observer => {
      const id = 'blt8e2b1280da14f7f7'; // one single fixed entry item
      const url = environment.cmsUrl + '/v3/content_types/maintenance/entries';

      this.cmsGet(url, false, [id]).subscribe(result => {
        const entry = result['entries'] ? result['entries'][0] : undefined;

        observer.next(entry);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
