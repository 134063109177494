import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { PasswordPolicy } from "../../../app/models/password-policy";
import { AdminUsersService } from "../../../app/services/admin-users.service";
import { UserService } from "../../../app/services/user.service";
import { MatchingFieldsValidator } from "../../../app/validators/matching-fields-validator";
import { PasswordPolicyValidator } from "../../../app/validators/password-policy-validator";

@Component({
  selector: 'app-edit-password-modal',
  templateUrl: './edit-password.component.html'
})
export class EditPasswordComponent implements OnInit {

  public form: any;
  public validationVisible = false;
  public showRolesValidationMessage = false;
  public isSaving = false;
  public policy: PasswordPolicy;
  public isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public adminUserService: AdminUsersService,
    public userService: UserService
  ) { }
  
  ngOnInit(): void {
    this.userService.passwordPolicy().subscribe(policy => this.onPolicySuccess(policy));
    
    this.formSetup();
  }

  formSetup() {
    this.form = this.formBuilder.group({
      new_password: new UntypedFormControl('', [Validators.required]),
      new_password_verification: new UntypedFormControl('', [Validators.required]),
      old_password: new UntypedFormControl('', [Validators.required])
    }, {
      validators: [MatchingFieldsValidator.validate('new_password', 'new_password_verification', 'mismatch')]
    });

    this.setPolicyValidator();
  }

  setPolicyValidator() {
    if(this.form && this.policy)  {
      this.form.get('new_password')?.setValidators([
        Validators.required,
        PasswordPolicyValidator.validate(this.policy)
      ])
    }
  }

  onPolicySuccess(policy: PasswordPolicy) {
    this.policy = policy;
    this.setPolicyValidator();
  }

  formSubmit() {
    if(!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.setPassword();
    }
  }

  setPassword() {
    this.isLoading = true;
    this.userService.changePassword(this.form.value.old_password, this.form.value.new_password, this.form.value.new_password_verification).subscribe(
      () => this.onSetPasswordSuccess(),
      error => this.onSetPasswordError(error)
    );
  }

  onSetPasswordSuccess() {
    this.isLoading = false;

    this.toastrService.info(this.translate.instant('modals.change_password.pw_change_success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  onSetPasswordError(response: any) {
    this.isLoading = false;

    if(response.error && response.error.errors[0].key) {
      const key = response.error.errors[0].key

      // Cases for the newly provided password
      if(key === 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA' || key === 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {
        this.validationVisible = true;
        
        this.form.get('new_password')?.reset();
        this.form.get('new_password_verification')?.reset();

        if(key == 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA') {
          this.form.get('new_password')?.setErrors({
            password_policy: true
          });
        }

        if(key == 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {
          this.form.get('new_password')?.setErrors({
            password_already_used: true
          });
        }

      // Case for the old provided password
      } else if(key === 'ERROR_IDENTITY_PASSWORD_INVALID') {
        this.validationVisible = true;
        
        this.form.get('old_password')?.reset();
        this.form.get('old_password')?.setErrors({ password_invalid: true });
      }
    }
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
