export class Application {
  public uid: string;
  public name: string;
  public active: boolean;
  public frontend_url: string;
  public minimum_version: string;
  public consents: Array<string> = [];
  public type: string;
  public session_time_out_minutes: number;
  public secret: string;
  public accessible_by: Array<{ uid: string, roles: Array<string> }> = [];

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.uid = item.uid;
    this.name = item.name;
    this.active = item.active;
    this.frontend_url = item.frontend_url;
    this.minimum_version = item.minimum_version;
    this.consents = item.consents;
    this.type = item.type;
    this.session_time_out_minutes = item.session_time_out_minutes;
    this.secret = item.secret;
    this.accessible_by = item.accessible_by;
  }

  public isAccecibleBy(role: "ROLE_PATIENT" | "ROLE_CC" | "ROLE_HCP") {
    let match = false;

    this.accessible_by.forEach((item) => {
      item.roles.forEach((value) => {
        if (value === role) {
          match = true;
        }
      });
    });

    return match;
  }

  public accesibleByString() {
    let roles = "";

    this.accessible_by.forEach((item) => {
      item.roles.forEach((value) => {
        roles += value + ", ";
      });
    });

    return roles.slice(0, -2);
  }
}
