<div class="name-step">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="mt-3">
      <label class="small d-block required">{{ 'form.labels.type' | translate }}</label>

      <div class="custom-control custom-radio mb-1">
        <input type="radio" class="custom-control-input" id="clinical" formControlName="type" [value]="CareModuleType.CLINICAL">
        <label class="custom-control-label" for="clinical">{{ 'form.labels.clinical' | translate }}</label>
      </div>

      <div class="custom-control custom-radio mb-1">
        <input type="radio" class="custom-control-input" id="educational" formControlName="type" [value]="CareModuleType.EDUCATION">
        <label class="custom-control-label" for="educational">{{ 'form.labels.educational' | translate }}</label>
      </div>
      <app-feedback-field [field]="form.get('type')"></app-feedback-field>
    </div>


    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate"
        formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="description" class="form-control w-100"
        [placeholder]="'form.labels.description' | translate" formControlName="description">
      <label for="description" class="required">{{ 'form.labels.description' | translate }}</label>
      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
    </div>
  </form>
</div>
