import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static RoleMfaRequired = "ROLE_MFA_REQUIRED";
  public static RoleConsentRequired = "ROLE_CONSENT_REQUIRED";
  public static RolePwChangeRequired = "ROLE_PW_CHANGE_REQUIRED";
  public static RolePlatform="ROLE_PLATFORM";
  public static RoleUserAdmin="ROLE_USER_ADMIN";
  public static RoleHotline="ROLE_HOTLINE";
  public static RoleOrgAdmin="ROLE_ORG_ADMIN";

  public authenticationSubject: BehaviorSubject<any>;
  public accessTokenValid = true;

  @Output() onLogOut: EventEmitter<any> = new EventEmitter();
  
  public static isFinalRole(role: string) {
    return ([
      AuthenticationService.RolePlatform,
      AuthenticationService.RoleUserAdmin,
      AuthenticationService.RoleHotline,
      AuthenticationService.RoleOrgAdmin
    ]).includes(role);
  }
  
  constructor() {
    const authenticationData: string = localStorage.getItem('authentication') || '{}';
    this.authenticationSubject = new BehaviorSubject<any>(JSON.parse(authenticationData));
  }

  public get authentication() {
    return this.authenticationSubject.value;
  }

  setAuthenticationData(data: any): boolean {
    if (data && data['access_token']) {
      const d = JSON.stringify(data);
      localStorage.setItem('authentication', d);
      this.authenticationSubject.next(d);
    }
    return true;
  }

  getAuthenticationData(): string | null {
    return localStorage.getItem('authentication');
  }

  getAccessToken(): string {
    const authenticationData = JSON.parse(this.getAuthenticationData() || '{}');
    return authenticationData['access_token'];
  }
  
  getRefreshToken(): string {
    const authenticationData = JSON.parse(this.getAuthenticationData() || '{}');
    return !authenticationData ? '' : authenticationData.refresh_token;
  }

  getCurrentRoles(): string[] {
    const authentication_data = JSON.parse(this.getAuthenticationData() || '{}');
    if (!authentication_data) {
      return [];
    }
    const data = this.getPayloadFromToken(authentication_data['access_token']);
    if (!data) {
      return [];
    }
    const roles = data['authorities'];
    return roles;
  }

  getPayloadFromToken(token: string): any {
    const parts = token?.split('.');
    if (parts && parts[1]) {
      return JSON.parse(atob(parts[1]) || '{}');
    }
  }

  getCurrentFirstRole(): string {
    const roles = this.getCurrentRoles();

    if (roles && roles.length) {
      return roles[0];
    } else {
      return '';
    }
  }
  
  hasFinalRole() {
    const roles = this.getCurrentRoles();
    let hasFinalRole = false;

    roles.forEach(role => {
      if(AuthenticationService.isFinalRole(role)) {
        hasFinalRole = true;
      }
    });

    return hasFinalRole;
  }

  destroyAuth() {
    localStorage.removeItem('authentication');
    this.authenticationSubject.next(null);
  }

  logout() {
    // destroy the auth
    this.destroyAuth();

    this.onLogOut.emit();
  }
}
