<ng-select
  [class]="selectClass"
  [ngClass]="{ 'ng-select-as-filter' : asFilter }"
  
  [placeholder]="placeholder"
  [clearable]="clearable"
  [searchable]="searchable"
  [multiple]="multiple"
  [items]="usedItems"
  [loading]="(!usedItems && !disabled)"
  [searchFn]="searchFn"

  [(ngModel)]="value"
  
  (ngModelChange)="onNgModelChange($event)"
  (search)="searchHandler($event)"
  (change)="changeHandler($event)"
  (close)="closeHandler($event)"
  
  [disabled]="disabled"
  
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
>
  <ng-template ng-label-tmp let-item="item">
    <span *ngIf="item">{{item.label}}</span>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <span class="ng-select-multiple-value" *ngIf="multiple && items?.length == 1">
      {{ items[0].label }}
    </span>

    <span class="ng-select-multiple-value" *ngIf="multiple && items?.length > 1">
      <span>{{items.length}} selected</span>
    </span>
    
    <span class="ng-value" *ngIf="!multiple && items?.length">
      {{ items[0].label }}
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <span *ngIf="item">
      <span class="custom-control custom-checkbox custom-control-inline align-middle m-end-0" *ngIf="showCheckboxes && multiple">
        <input class="custom-control-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" >
        <label class="custom-control-label" for="item-{{index}}"></label>
      </span>

      <span>{{item.label}}</span>
    </span>
  </ng-template>
</ng-select>
