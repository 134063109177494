import { AbstractControl, ValidatorFn } from '@angular/forms';

export class MatchingFieldsValidator {
  static validate(controlName: string, matchControlName: string, errorKey: any): ValidatorFn {
    return (controls: AbstractControl) => {
      let control: any = controls.get(controlName);
      let matchControl: any = controls.get(matchControlName);

      if (control.value !== matchControl.value) {
        let error: any = {};
        error[errorKey] = true;
        return error;
      } else {
        return null;
      }
    };
  }
}
