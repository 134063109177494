import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Output() public onChoice: EventEmitter<boolean> = new EventEmitter();
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() showYesLoading: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() yes: string;
  @Input() no: string;
  @Input() data: any;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(){
  }

  handleYes() {
    this.onChoice.emit(true);
    this.bsModalRef.hide()
  }

  handleNo() {
    return this.bsModalRef.hide();
  }
  
  handleClose() {
    this.onClose.emit();
    return this.bsModalRef.hide();
  }
}
