import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import PaginationInterface from "../interfaces/pagination.interface";
import { HospitalCode } from "../models/hospital-code";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class HospitalCodeService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getHospitalCodes(hospitalUid: string, page: number = 0, size: number = 25): Observable<{ hospital_codes: Array<HospitalCode>, pagination: PaginationInterface }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/self-registration/hospital-codes?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospital_codes = this.mapHospitalCodes(result.items);
        observer.next({
          hospital_codes: hospital_codes,
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public update(hospitalCode: HospitalCode, careModuleUid: string, hospitalUid: string) {
    const url = `${this.platformUrl}/hospitals/${hospitalUid}/self-registration/hospital-codes/${hospitalCode.uid}`;

    const data = {
      code: hospitalCode.code,
      care_module_uid: careModuleUid,
      days_valid: hospitalCode.days_valid,
      days_valid_indefinite: hospitalCode.days_valid_indefinite
    };

    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public create(
    hospitalUid: string,
    hospitalCode: {
      code: string,
      days_valid?: number,
      care_module_uid: string,
      days_valid_indefinite: boolean
    }) {
    const url = `${this.platformUrl}/hospitals/${hospitalUid}/self-registration/hospital-codes`;

    return new Observable(observer => {
      this.authenticatedPost(url, hospitalCode).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  private mapHospitalCodes(list: any[]): Array<HospitalCode> {
    const hospital_codes = new Array();
    list.forEach(item => {
      hospital_codes.push(this.mapHospitalCode(item));
    });
    return hospital_codes;
  }

  private mapHospitalCode(input: any): HospitalCode {
    return new HospitalCode(input);
  }
}
