import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CareModule } from '../../models/care-module';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { CareModuleService } from '../../services/care-module.service';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-edit-cm-type',
  templateUrl: './edit-default-medical-team.component.html',
  styleUrls: ['./edit-default-medical-team.scss']
})
export class EditDefaultMedicalTeamComponent implements OnInit {
  public isSaving: boolean = false;
  public form: FormGroup;
  public careModule: CareModule;
  public hospitalUid: string;
  public hcps: HealthCareProfessional[] = [];
  public validationVisible = false;

  @Output() onCareModuleUpdated = new EventEmitter<void>();

  public searchHcpListEvent = new EventEmitter<any>();

  constructor(
    public careModuleService: CareModuleService,
    public bsModalRef: BsModalRef,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      default_case_manager_uid: new FormControl(this.careModule.default_case_manager_uid),
      default_clinical_lead_uid: new FormControl(this.careModule.default_clinical_lead_uid),
    });
    
    if(this.careModule.type === 'EDUCATION') {
      this.form.get('default_case_manager_uid')?.setValidators([Validators.required]);
      this.form.get('default_clinical_lead_uid')?.setValidators([Validators.required]);
    }
    
    this.getHcps().subscribe();

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });
  }

  onHandleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    this.careModule.default_case_manager_uid = this.form.get('default_case_manager_uid')?.value;
    this.careModule.default_clinical_lead_uid = this.form.get('default_clinical_lead_uid')?.value;

    this.careModuleService.update(this.hospitalUid, this.careModule).subscribe(() => {
      this.onHandleClose();
      this.isSaving = false;
      this.onCareModuleUpdated.emit();
    }, () => this.isSaving = true);
  }

  onHandleClose() {
    this.bsModalRef.hide();
  }

  searchHcpList(searchTerm?: string): void {
    if (!searchTerm) {
      this.hcps = [];
    }

    this.getHcps(searchTerm).subscribe(response => {
      this.hcps = response;
    });
  }

  getHcps(searchTerm?: string): Observable<HealthCareProfessional[]> {
    const hospitalUid = this.hospitalUid;
    return new Observable(observer => {
      this.hcpService.getPaged(hospitalUid, { last_name: searchTerm || '' }, 'last_name,asc').subscribe(result => {
        this.hcps = result.hcps;
        observer.next(result.hcps);
        observer.complete();
      });
    });
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
