import { DateFormat } from './date-format';

export class DateFormatMap{
  public dateFormats: Array<DateFormat>;

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.dateFormats = new Array();
    
    for(const key in item) {
      this.dateFormats.push(new DateFormat(key, item[key]));
    }
  }

  get(key: string): DateFormat{
    const values = this.dateFormats.filter(item => {
      return item.key === key;
    });

    if(values && values.length) {
      return values[0];
    } else {
      return this.dateFormats[0];
    }
  }
}