import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import PaginationInterface from "../interfaces/pagination.interface";
import { CareCoordinator } from "../models/care-coordinator";
import { environment } from "./../../environments/environment";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class CareCoordinatorService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public getCareCoordinators(hospitalUid: string, page: number = 0, size: number = 25): Observable<{care_coordinators: Array<CareCoordinator>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/ccs?${paramsString}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const care_coordinators = this.mapCareCoordinators(result.items);
        observer.next({
          care_coordinators: care_coordinators,
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public get(hospitalUid: string, careCoordinatorUid: string) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/ccs/${careCoordinatorUid}`;
  
      this.authenticatedGet(url).subscribe(result => {
        const cc = this.mapCareCoordinator(result);
        observer.next(cc);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public create(hospitalUid: string, params: any) {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/ccs`;
    return new Observable(observer => {
      this.authenticatedPost(url, params).subscribe((result: HttpResponse<any>) => {
        observer.next(new CareCoordinator(result.body));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public update(hospitalUid: string, careCoordinatorUid: string, params: any) {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/ccs/${careCoordinatorUid}`;
    return new Observable(observer => {
      this.authenticatedPut(url, params).subscribe(result => {
        observer.next(new CareCoordinator(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
  
  public changeEmail(hospitalUid: string, careCoordinatorUid: string, params: {new_email: string, reason: string}) {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/hcps/${careCoordinatorUid}/change-email`;
    return new Observable(observer => {
      this.authenticatedPost(url, params).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  private mapCareCoordinators(list: any[]): Array<CareCoordinator> {
    const care_coordinators = new Array();
    if(!list || !Array.isArray(list)) {
      return [];
    }
    list.forEach(item => care_coordinators.push(this.mapCareCoordinator(item)));
    return care_coordinators;
  }

  private mapCareCoordinator(input: any): CareCoordinator {
    return new CareCoordinator(input);
  }
}
