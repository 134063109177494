<div class="layout-header-pusher header-back-tabs"></div>
<div class="layout-page-wrapper">
  <div class="layout-header  header-back-tabs">
    <div class="header-content">
      <div class="container">
        <p>
          <a [routerLink]="['/hospitals', hospitalUid, 'care_modules', careModuleUid, 'settings']" class="text-secondary font-weight-bold d-flex align-items-center">
            <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
            <span class="align-middle">{{ 'action.back_to_care_module' | translate }}</span>
          </a>
        </p>
      </div>
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.care_module.translations.title' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <button class="btn btn-sm btn-secondary" (click)="addTranslation($event)">{{ 'pages.default.care_module.translations.add' | translate }}</button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <app-care-module-tabs  [active]="'settings'" [hospital_uid]="hospitalUid" [care_module]="careModule"></app-care-module-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <table class="table admin-table">
        <thead>
          <tr>
            <th scope="col">{{ 'pages.default.care_module.translations.language' | translate }}</th>
            <th scope="col">{{ 'pages.default.care_module.translations.name' | translate }}</th>
            <th scope="col">{{ 'pages.default.care_module.translations.description' | translate }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let translation of translations; let index = index;">
            <td>{{translation.language}}</td>
            <td>{{translation.name}}</td>
            <td>{{translation.description}}</td>
            <td class="action">
              <a href="#" class="action-button" (click)="editTranslation($event, translation, index)">
                <span [inlineSVG]="'/assets/svg/edit-white.svg'"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
