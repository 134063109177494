import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocaleInterface } from '../interfaces/locale.interface';
import { PasswordPolicy } from '../models/password-policy';
import { Profile } from '../models/profile';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  public static StorageKeyProfile = 'C4T_profile';
  public static StorageKeyPermissions = 'C4T_permissions';

  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public localeService: LocaleService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public getProfile(): Observable<Profile> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/profile`;
      this.authenticatedGet(url).subscribe(result => {
        const profile = new Profile(result);

        localStorage.setItem(UserService.StorageKeyProfile, JSON.stringify(profile));

        observer.next(profile);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public updateProfile(profile: Profile) {
    if (!profile?.mobile_number?.code || !profile.mobile_number.number) {
      delete profile.mobile_number;
    }

    return new Observable((observer) => {
      this.authenticatedPut(`${this.platformUrl}/user/profile`, profile).subscribe(result => {
        const updatedProfile = new Profile(result);

        if (updatedProfile.locale) {
          updatedProfile.locale.language = profile.locale.language; //since this comes back incorrectly from time to time.
          this.localeService.setLocalePreferences(updatedProfile.locale);
        }

        localStorage.setItem(UserService.StorageKeyProfile, JSON.stringify(updatedProfile));
        observer.next(updatedProfile);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      }
      );
    });
  }

  public changePassword(old_password: string, new_password: string, new_password_verification: string): Observable<any> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/change-password`;
      const params = {
        old_password,
        new_password,
        new_password_verification
      };
      this.authenticatedPut(url, params).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  public changeEmail(new_email: string): Observable<any> {
    const url = this.platformUrl + '/user/change-email';
    const params = { new_email };

    return this.authenticatedPost(url, params);
  }

  public verifyChangeEmailCode(verification_code: string): Observable<any> {
    const url = this.platformUrl + '/user/change-email';
    const params = { verification_code };

    return this.authenticatedPut(url, params);
  }

  public passwordPolicy(): Observable<any> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/password-policy`;
      this.authenticatedGet(url).subscribe(result => {
        const policy = new PasswordPolicy(result);
        observer.next(policy);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
