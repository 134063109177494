import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isDateValid } from 'ngx-bootstrap/chronos';

export class DateValidator {
  static validate(): ValidatorFn {
    return (control: AbstractControl) => {
      if(control.value) {
        if(!isDateValid(new Date(control.value))) {
          return { date_invalid: true};
        }
      }
      
      return {};
    };
  }
}
