import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { PathwayConfigurationParameter } from "../../interfaces/pathway-configuration-parameter.interface";
import { CareModuleType } from "../../models/care-module";
import { HealthCareProfessional } from "../../models/health-care-professional";
import { PathwayTemplate } from "../../models/pathway-template";
import { CareModuleService } from "../../services/care-module.service";
import { GeneralService } from "../../services/general.service";
import { PathwayTemplateService } from "../../services/pathway-template-service.service";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";

enum Steps {
  NAME = "NAME",
  TEMPLATE = "TEMPLATE",
  TEAM = "TEAM"
}
@Component({
  selector: 'app-add-care-module-modal',
  templateUrl: './add-care-module-modal.component.html'
})
export class AddCareModuleModalComponent implements OnInit {
  @Output() onCareModuleCreated = new EventEmitter<void>();
  public hospitalUid: string;
  public isSaving: boolean = false;
  public stepsEnum = Steps;
  public steps: Steps[] = Object.values(Steps);
  public currentStep: Steps = this.stepsEnum.NAME;

  // Name step
  public name: string;
  public description: string;
  public type: CareModuleType;
  public nameStepValid: boolean;

  // Template step
  public pathwayTemplate: PathwayTemplate;
  public pathwayTemplateValid: boolean;
  public configurationParameters: PathwayConfigurationParameter[];

  // Team step
  public caseManager: HealthCareProfessional;
  public clinicalLead: HealthCareProfessional;
  public teamValid: boolean

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public templateService: PathwayTemplateService,
    public careModuleService: CareModuleService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  onHandleStep(direction: 'backward' | 'forward' = 'forward'): void {
    const stepIndex = this.steps.findIndex(step => step === this.currentStep);

    if (direction === 'forward' && stepIndex < this.steps.length) {
      this.currentStep = this.steps[stepIndex + 1];
    }

    if (direction === 'backward' && stepIndex !== 0) {
      this.currentStep = this.steps[stepIndex - 1];
    }
  }

  showBackButton(): boolean {
    if (this.currentStep !== this.stepsEnum.NAME) {
      return true;
    }
    return false;
  }

  showNextButton(): boolean {
    if (this.currentStep === this.stepsEnum.NAME) {
      return true;
    }

    if (this.currentStep === this.stepsEnum.TEMPLATE) {
      return true;
    }

    return false;
  }

  showSubmitButton(): boolean {
    if (this.currentStep === this.stepsEnum.TEAM) {
      return true;
    }

    return false;
  }

  isButtonEnabled(): boolean {
    if (this.currentStep === this.stepsEnum.NAME) {
      if (this.nameStepValid) {
        return true;
      }
    }

    if (this.currentStep === this.stepsEnum.TEMPLATE) {
      if (this.pathwayTemplateValid && this.pathwayTemplate?.major_version) {
        return true;
      }
    }

    if (this.currentStep === this.stepsEnum.TEAM) {
      if (this.teamValid) {
        return true;
      }
    }

    return false;
  }

  mappedPayload() {
    let configuration_parameters: any = {};
    
    this.configurationParameters?.forEach((param: PathwayConfigurationParameter) => {
      configuration_parameters[param.key] = param.default_value;
    });
    
    let payload: any = {
      name: {
        translations: [{
          language: 'EN',
          translation: this.name
        }]
      },
      description: {
        translations: [{
          language: 'EN',
          translation: this.description
        }]
      },
      pathway_template_uid: this.pathwayTemplate?.uid,
      pathway_template_version: this.pathwayTemplate?.major_version,
      configuration_parameters: configuration_parameters,
      type: this.type
    }

    if (this.caseManager?.uid) payload['default_case_manager_uid'] = this.caseManager.uid;
    if (this.clinicalLead?.uid) payload['default_clinical_lead_uid'] = this.clinicalLead.uid;

    return payload;
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    
    this.careModuleService.create(this.hospitalUid, this.mappedPayload()).subscribe(() => {
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onCareModuleCreated.emit();
    }, () => {
      this.isSaving = false;
    });
  }

  onHandleClose(): void {
    if (this.nameStepValid || this.pathwayTemplateValid) {
      const modalRef = this.modalService.show(ConfirmModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered',
          initialState: {
            title: this.translateService.instant('modals.add_care_module.confirm_cancel.title'),
            description: this.translateService.instant('modals.add_care_module.confirm_cancel.description'),
            yes: this.translateService.instant('modals.add_care_module.confirm_cancel.yes'),
            no: this.translateService.instant('modals.add_care_module.confirm_cancel.no')
          }
        })
      );

      modalRef.content.onChoice.subscribe(() => {
        modalRef.hide();
        this.bsModalRef.hide();
      });
    } else {
      this.bsModalRef.hide();
    }
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.add_care_module.success'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  public customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  public getStepIndex(): number {
    return this.steps.findIndex(step => step === this.currentStep) + 1;
  }
}
