import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { DialCode } from "../../../app/models/dial-code";
import { Hospital } from "../../../app/models/hospital";
import { GeneralService } from "../../../app/services/general.service";
import { HospitalService } from "../../../app/services/hospital.service";

@Component({
  selector: 'app-edit-hospital-contact-modal',
  templateUrl: './edit-hospital-contact-modal.component.html'
})
export class EditHospitalContactModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;
  public dialCodeOptions: DialCode[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public generalService: GeneralService
  ) { }
  
  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.generalService.getDialCodes(),
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.dialCodeOptions = data[0];
  };

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl(this.hospital.phone_number?.code, Validators.required),
        number: new UntypedFormControl(this.hospital.phone_number?.number, Validators.required)
      }),
      email: [this.hospital.email, [Validators.required]],
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);

    clonedHospital.phone_number.code = this.form.get('phone_number.code')?.value;
    clonedHospital.phone_number.number = this.form.get('phone_number.number')?.value;
    clonedHospital.email = this.form.get('email')?.value;


    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public  showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
