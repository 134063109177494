import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { PathwayTemplate } from '../../models/pathway-template';
import { TemplateGroup } from '../../models/template-group';
import { PathwayTemplateVersion } from '../../models/pathway-template-version';
import { GeneralService } from '../../services/general.service';
import { LocaleService } from '../../services/locale.service';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { UserService } from '../../services/user.service'
import { AddPathwayTemplateVersionModalComponent } from '../../modals/add-pathway-template-version-modal/add-pathway-template-version-modal.component';
import { PathwayTemplateModalComponent } from '../../modals/pathway-template-modal/pathway-template-modal.component';

@Component({
  selector: 'app-pathway-template-detail',
  templateUrl: './pathway-template-detail.component.html'
})
export class PathwayTemplateDetailComponent {
  private _template: PathwayTemplate;

  @Input() set template(template: PathwayTemplate) { this.getPathwayTemplate(template); }
  @Output() templateChange = new EventEmitter<PathwayTemplate>();

  get template(): PathwayTemplate {
    return this._template;
  }

  @Output() backToGroup = new EventEmitter<TemplateGroup>();
  @Output() openVersionDetails = new EventEmitter<{ template: PathwayTemplate, version: PathwayTemplateVersion }>();
  @Output() refreshViews = new EventEmitter();

  public isLoading: boolean = false;

  constructor(
    public modalService: BsModalService,
    public generalService: GeneralService,
    public pathwayTemplateService: PathwayTemplateService,
    public userService: UserService,
    public localeService: LocaleService,
  ) { }

  private getPathwayTemplate(template: PathwayTemplate, showLoader: boolean = true) {
    if (showLoader) {
      this.isLoading = true;
    }

    forkJoin([
      this.pathwayTemplateService.getTemplate(template.uid),
      this.pathwayTemplateService.getVersions(template.uid)
    ]).subscribe((data: [PathwayTemplate, PathwayTemplateVersion[]]) => {
      this._template = data[0];
      // Using input group scope becaue PF handles groups different
      this._template.group = template.group
      this._template.versions = data[1];

      this.isLoading = false;
    });
  }

  onEditTemplate(event: MouseEvent, template: PathwayTemplate) {
    event.preventDefault();
    const modal = this.modalService.show(PathwayTemplateModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState: {
          template,
          group: this.template?.group
        }
      })
    );

    modal.content.onSubmitDone.subscribe(() => {
      this.getPathwayTemplate(this.template, false);
      this.refreshViews.emit(this.template.group ?? undefined);
    })
  }

  selectGroup(group: TemplateGroup) {
    this.backToGroup.emit(group)
  }

  addTemplateVersion(): void {
    const modalRef = this.modalService.show(AddPathwayTemplateVersionModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xxl',
        initialState: {
          pathwayTemplate: this.template
        }
      })
    );

    modalRef.content.onMajorVersionCreated.subscribe(() => {
      this.getPathwayTemplate(this.template, false);
    });
  }

  navigateToVersion(version: PathwayTemplateVersion): void {
    this.openVersionDetails.emit({ template: this.template, version });
  }
}
