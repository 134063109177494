import { Component, Input } from '@angular/core';
import { CareModule } from '../../models/care-module';

@Component({
  selector: 'app-care-module-tabs',
  templateUrl: './care-module-tabs.component.html'
})
export class CareModuleTabsComponent {
  @Input() care_module?: CareModule;
  @Input() hospital_uid?: string;
  @Input() active : string;
  
  constructor() { }
}
