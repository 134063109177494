import { Injectable } from "@angular/core";
import moment from "moment";
import { LocaleInterface } from "../interfaces/locale.interface";
import { DateFormat } from "../models/date-format";
import { GeneralService } from "./general.service";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(
    public generalService: GeneralService
  ) {

  }
  
  setLocalePreferences(locale: LocaleInterface) {
    const dateFormat = this.generalService.dateFormatMap.get(locale.date_format);

    if (!locale.time_zone) {
      locale.time_zone = 'Europe/Brussels';
    }

    localStorage.setItem('locale', JSON.stringify(locale));
    localStorage.setItem('localeDateFormat', JSON.stringify(dateFormat));
  }
  
  getLocalePreferences(): {locale: LocaleInterface, dateFormat: DateFormat} {
    let locale: LocaleInterface = {} as LocaleInterface;
    let localeDateFormat: DateFormat = {} as DateFormat;
    
    const localeString = localStorage.getItem('locale');
    const localeDateString = localStorage.getItem('localeDateFormat');
    
    if(typeof localeString === "string") {
      locale = JSON.parse(localeString) as LocaleInterface;
    }
    
    if(typeof localeDateString === "string") {
      localeDateFormat = JSON.parse(localeDateString) as DateFormat;
    }

    return {
      locale,
      dateFormat: localeDateFormat
    };
  }
  
  transformFormatToMomentFormat(format: string) {
    // Legend

    // 'd' stands for '1'
    // 'dd' stands for '01'
    // 'ddd' stands for 'mon-su'
    // 'dddd' stands for 'monday-sunday'

    // 'm' stands for '1-12'
    // 'mm' stands for '01-12'
    // 'mmm' stands for 'jan-dec'
    // 'mmmm' stands for 'january-december'

    // 'yy' stands for '20'
    // 'yyyy' stands for '2020'

    if(!format) {
      return '';
    }

    format = format.replace('yyyy', 'YYYY');
    format = format.replace('yy', 'YY');

    format = format.replace(/m/g, 'M');

    format = format.replace(/d/g, 'D');
    format = format.replace(/DDDD/, 'dddd');
    format = format.replace(/DDD/, 'ddd');

    return format;
  }
  
  dateWithTimeZone(date: Date): Date {
    date = new Date(date);
    const timeZone = this.getLocalePreferences().locale.time_zone;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return moment.tz(`${year}-${month}-${day} ${hour}:${minutes}`, 'YYYY-MM-DD hh:mm', timeZone).toDate()
  }
  
  dateWithoutTimeZone(value: any): Date {
    let conv_date = new Date();

    const preferences = this.getLocalePreferences();
    const moment_date = moment(new Date(value)).tz(preferences.locale.time_zone);

    if (moment_date) {
      const date_string = moment_date.toString();
      const date_string_converted = date_string.substring(0,date_string.lastIndexOf(":"));
      conv_date = new Date(date_string_converted);
    }

    return conv_date;
  }
}
