import { Component, OnInit, Input } from '@angular/core';
import { PasswordPolicy } from './../../models/password-policy';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-password-policy-rules',
  templateUrl: './password-policy-rules.component.html',
  styleUrls: ['./password-policy-rules.component.scss']
})
export class PasswordPolicyRulesComponent implements OnInit {
  @Input()
  set policy(policy: PasswordPolicy) {
    this._policy = policy;
  }
  get policy(): PasswordPolicy {
    return this._policy;
  }
  private _policy: PasswordPolicy;
  
  @Input() passwordControl: UntypedFormControl;
  @Input() validationVisible: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

  isPolicyBreach(errorString: string):boolean {
    return (this.validationVisible && this.passwordControl && this.passwordControl.errors && this.passwordControl.errors[errorString]) as boolean;
  }
}
