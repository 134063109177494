import { Host, Optional, SkipSelf } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { GeneralService } from '../../services/general.service';
import { DateValidator } from '../../validators/date-validator';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateInputComponent),
    multi: true
  }]
})
export class DateInputComponent implements OnInit {
  private control: AbstractControl | null | undefined;

  public editDateValue: Date | null;
  public value: string | null;

  public onChange: any = () => { };
  public onTouched: any = () => { };
  public disabled: boolean;

  @Input() name: string;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  
  @Output() change: EventEmitter<string> = new EventEmitter();

  constructor(
    public generalService: GeneralService,

    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer?.control?.get(this.formControlName);

      // Add date validator to invalidate form when 'Invalid date'
      const currentValidators: ValidatorFn[] = [];
      if (this.control?.validator) currentValidators.push(this.control?.validator);
      currentValidators.push(DateValidator.validate());

      this.control?.setValidators(currentValidators)
    }
  }

  writeValue(value?: string): void {
    if (value) {
      this.editDateValue = new Date(value);
      this.value = value;
    } else {
      this.editDateValue = null;
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateNgModelChange(date: Date) {
    this.tryCompileValue()
  }

  tryCompileValue() {
    const value: string =
      (this.editDateValue !== null) ?
        this.generalService.dateToString(this.editDateValue) : '';

    if (value !== this.value) {
      this.onTouched();
    }

    this.value = value;
    this.onChange(this.value);
  }

  public get editDateValid(): boolean {
    if (this.control?.errors?.required && !this.editDateValue) {
      return false;
    } else if (this.control?.errors?.date_invalid) {
      return false;
    }

    return true;
  }

  public get isValid(): boolean {
    return this.editDateValid;
  }
}
