import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HcpFunction } from "../models/hcp-function";
import { HealthCareProfessional } from "../models/health-care-professional";
import { AuthenticationService } from "./authentication.service";
import { CmsService } from "./cms.service";
import PaginationInterface from '../interfaces/pagination.interface';
import { TranslateService } from '@ngx-translate/core';
import { ApiService, PagingDto } from './api.service';
import { DataService } from './data.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class HcpService extends ApiService {
  private platformUrl: string;

  constructor(
    http: HttpClient, 
    authenticationService: AuthenticationService, 
    public cmsService: CmsService,
    public dataService: DataService,
    public translateService: TranslateService,
    public generalService: GeneralService
    ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  public static StorageKeyCurrentHcp = 'C4T_current_hcp';
  public static StorageKeyCurrentCareModules = 'C4T_current_care_modules';

  public getPaged(
    hospitalUid: string,
    filters: any = {},
    sort = 'last_name,asc',
    page = 0,
    size = 30
  ): Observable<{hcps: HealthCareProfessional[],pagination: PaginationInterface}> {

    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      /*
      ** Filter - Last Name - Start
      */
      let last_name = filters.last_name || filters.hcpLastName
      if (last_name) {
        paramBits.push(`last_name=${last_name}`);
      }
      /*
      ** Filter - Last Name - End
      */


      /*
      ** Filter - Status - Start
      */
      if (filters?.status) {
        paramBits.push(`status=${filters.status}`);
      }
      /*
      ** Filter - Status - end
      */


      /*
      ** Filter - Function Uid(s) - Start
      */
      let function_key = filters.function_key || filters.function_keys || filters.functionkeys;
      if (function_key) {
        if(Array.isArray(function_key)) {
          function_key.forEach(cmu => {
            paramBits.push(`function=${cmu}`);
          });
        } else {
          paramBits.push(`function=${function_key}`);
        }
      }
      /*
      ** Filter - Function Uid(s) - End
      */


      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/hcps?${paramsString}`;
      this.authenticatedGet(url).subscribe(result => {
        const hcps: HealthCareProfessional[] = [];
        for (const item of result?.items) {
          const hcp = new HealthCareProfessional(item);
          hcps.push(hcp);
        }

        observer.next({hcps, pagination: result?.pagination});
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
  
  public getFunctions(hospitalUid: string): Observable<HcpFunction[]> {
    const urlParts = [];
    const urlParams = [];

    urlParts.push(`${this.platformUrl}/hospitals/${hospitalUid}/functions`);

    urlParams.push(`page=0`);
    urlParams.push(`size=999`);

    urlParts.push(`?${urlParams.join('&')}`);
    const url = urlParts.join('');

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const items: any[] = result['items'];
        const functions: any[] = [];

        items.forEach(item => {
          const hcpFunction = new HcpFunction(item);
          
          functions.push(hcpFunction);
        });

        this.getFunctionTranslations().subscribe((translations) => {
          functions.forEach((func: any) => {
            const translatedLabel = translations.find((item) => item.key === func.key);

            if (translatedLabel) {
              func.label = translatedLabel.value;
            }
          });

          functions.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          observer.next(functions);
          observer.complete();
        });  

      });
    });
  }
  
  public get(hospitalUid: string, hcpUid: string): Observable<HealthCareProfessional> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/hcps/${hcpUid}`;
      this.authenticatedGet(url).subscribe(result => {
        const hcp = new HealthCareProfessional(result);
        observer.next(hcp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  private getFunctionTranslations(): Observable<Array<{ key: string; value: string; }>> {
    return new Observable(observer => {
      this.cmsService.getAllTranslationsByRegion('business_function_keys').subscribe(translations => {
        observer.next(translations);
        observer.complete();
      });
    });
  }
}
