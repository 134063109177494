<div class="modal-body">
  <button type="button" class="close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <h2>{{ 'modals.add_folder.title' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label">
      <input type="text" id="folderName" class="form-control w-100"
        [placeholder]="'form.labels.folder_name' | translate" formControlName="folderName" name="folderName"
        #folderName>
      <label for="folderName" class="required">{{ 'form.labels.folder_name' | translate }}</label>
      <app-feedback-field [field]="form.get('folderName')"></app-feedback-field>
    </div>
  </form>

  <p>{{ 'modals.add_folder.disclaimer' | translate }}</p>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="confirm-modal-cancel" (click)="handleClose()">{{ 'action.cancel' | translate
      }}</button>
    <button class="btn btn-secondary m-start-3" id="confirm-modal-save"
      [ngClass]="{'disabled': form.get('folderName')?.value ===  ''}" (click)="handleSave()">{{ 'action.save' |
      translate }}</button>
  </div>
</div>
