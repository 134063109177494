<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>Default team</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- default_clinical_lead -->
    <div class="form-group" [ngClass]="{'required': careModule.type === 'EDUCATION'}">
      <app-select [placeholder]="'Clinical lead'"
        [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
        [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
        [bindValue]="'uid'" formControlName="default_clinical_lead_uid" [asFilter]="false" [showCheckboxes]="false">
      </app-select>
      <app-feedback-field [field]="form.get('default_clinical_lead_uid')"></app-feedback-field>
    </div>

    <!-- default_case_manager -->
    <div class="form-group" [ngClass]="{'required': careModule.type === 'EDUCATION'}">
      <app-select [placeholder]="'Case manager'"
        [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
        [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
        [bindValue]="'uid'" formControlName="default_case_manager_uid" [asFilter]="false" [showCheckboxes]="false">
      </app-select>
      <app-feedback-field [field]="form.get('default_case_manager_uid')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>Update</span>
    </button>
  </div>
</div>

