import { Host, Optional, SkipSelf, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { LocaleService } from '../../services/locale.service';
import { UserService } from '../../services/user.service';
import { DateValidator } from '../../validators/date-validator';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    }
  ]
})
export class DateTimeInputComponent implements OnInit {
  private control: AbstractControl | null | undefined;

  public editDateValue: Date | null;
  public editTimeValue: Date | null;

  public value: any;

  public onChange: any = () => { };
  public onTouched: any = () => { };

  public disabled: boolean;

  public time_24_hours: boolean;

  @Input() name: String;
  @Input() formControlName: string;
  @Output() change: EventEmitter<string> = new EventEmitter();

  constructor(
    public userService: UserService,
    public localeService: LocaleService,

    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    this.time_24_hours = this.localeService.getLocalePreferences().locale?.time_24_hours;

    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer?.control?.get(this.formControlName);

      // Add date validator to invalidate form when 'Invalid date'
      const currentValidators: ValidatorFn[] = [];
      if (this.control?.validator) currentValidators.push(this.control?.validator);
      currentValidators.push(DateValidator.validate());

      this.control?.setValidators(currentValidators)
    }
  }

  writeValue(value?: Date): void {
    if (value) {
      const dateWithoutTimeZone: Date = this.localeService.dateWithoutTimeZone(value);
      this.editDateValue = dateWithoutTimeZone;
      this.editTimeValue = dateWithoutTimeZone;

      this.value = this.localeService.dateWithTimeZone(value);
    } else {
      this.editDateValue = null;
      this.editTimeValue = null;

      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateNgModelChange(date: Date) {
    this.tryCompileValue();
  }

  onTimeNgModelChange(date: Date) {
    this.tryCompileValue();
  }

  tryCompileValue() {
    let value: any;

    if (this.editDateValue && this.editTimeValue) {
      if (!this.value) {
        value = new Date();
      }

      this.editTimeValue.setDate(this.editDateValue.getDate());
      this.editTimeValue.setMonth(this.editDateValue.getMonth());
      this.editTimeValue.setFullYear(this.editDateValue.getFullYear());

      value = this.localeService.dateWithTimeZone(this.editTimeValue);
    } else if (this.editDateValue) {
      value = '';
    } else if (this.editTimeValue) {
      value = '';
    } else {
      value = null;
    }

    if (String(value) !== String(this.value)) { //Object to object evaluation does't work. Stringify it is! To only mark the comp as touched, this looks safe enough.
      this.onTouched();

      this.value = value;
      this.onChange(this.value);
    }
  }

  public get editDateValid(): boolean {
    if (this.control?.errors?.required && !this.editDateValue) {
      return false;
    } else if (!this.control?.errors?.required && this.editTimeValue && !this.editDateValue) {
      return false
    } else if (this.control?.errors?.date_invalid) {
      return false;
    }

    return true;
  }

  public get editTimeValid(): boolean {
    if (this.control?.errors?.required && !this.editTimeValue) {
      return false;
    } else if (!this.control?.errors?.required && this.editDateValue && !this.editTimeValue) {
      return false
    }

    return true;
  }

  public get isValid(): boolean {
    return this.editDateValid && this.editTimeValid;
  }
}
