import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddAdminUserComponent } from '../../../../app/modals/add-admin-user/add-admin-user.component';
import { EditAdminUserComponent } from '../../../../app/modals/edit-admin-user/edit-admin-user.component';
import { AdminUser } from '../../../../app/models/admin-user';
import { AdminUsersService } from '../../../../app/services/admin-users.service';
import { GeneralService } from '../../../../app/services/general.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html'
})
export class AdminUsersComponent implements OnInit {
  public adminUsers: Array<AdminUser> = [];
  public isLoading: boolean = false;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  constructor(private adminUserService: AdminUsersService, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.getAdminUsers();
  }

  getAdminUsers() {
    this.isLoading = true;
    this.adminUsers = [];
    this.totalPages = 0;
    this.totalElements = undefined;

    this.adminUserService.getAdminUsers(this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.isLoading = false;
      this.adminUsers = result.adminUsers;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }
    });
  }

  onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getAdminUsers();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getAdminUsers();
    }
  }

  newAdminUser(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddAdminUserComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );

    modalRef.content.onAdminUserCreated.subscribe(() => this.getAdminUsers());
  }

  editAdminUser(event: MouseEvent, user: AdminUser) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditAdminUserComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          adminUser: user
        }
      })
    );

    modalRef.content.onUserUpdated.subscribe(() => this.getAdminUsers());
  }
}
