import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PathwayTemplate } from '../../models/pathway-template';
import { PathwayTemplateVersion } from '../../models/pathway-template-version';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';
import { ConfigurationsParameterType } from '../../interfaces/pathway-configuration-parameter.interface'
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddPathwayTemplateVersionModalComponent } from '../../modals/add-pathway-template-version-modal/add-pathway-template-version-modal.component';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-pathway-template-version-detail',
  templateUrl: './pathway-template-version-detail.component.html'
})
export class PathwayTemplateVersionDetailComponent {
  private _templateVersion: PathwayTemplateVersion;
  public configurationsParameterType = ConfigurationsParameterType;

  @Input() set templateVersion(version: PathwayTemplateVersion) {
    this.getVersionDetail(version);
  }

  get templateVersion(): PathwayTemplateVersion {
    return this._templateVersion;
  }

  @Input() pathwayTemplate: PathwayTemplate;

  @Output() backToTemplate = new EventEmitter<PathwayTemplate>();

  public isLoading: boolean = false;

  constructor(
    public pathWayTemplateService: PathwayTemplateService,
    public modalService: BsModalService
  ) { }

  getVersionDetail(version: PathwayTemplateVersion, showLoader: boolean = true): void {
    if (showLoader) {
      this.isLoading = true;
    }

    this.pathWayTemplateService.getVersionDetails(version.templateUid, version.major_version).subscribe((version: PathwayTemplateVersion) => {
      this._templateVersion = version;

      this._templateVersion.configuration_parameters.map(param => {
        if (param.data_type === ConfigurationsParameterType.DATE || param.data_type === ConfigurationsParameterType.DATE_TIME) {
          param.default_value = new Date(param.default_value);
        }

        return param;
      });

      this.isLoading = false;
    });
  }

  returnToTemplate(): void {
    this.backToTemplate.emit(this.pathwayTemplate);
  }

  addMinorVersion(): void {
    const modalRef = this.modalService.show(AddPathwayTemplateVersionModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState: {
          pathwayTemplate: this.pathwayTemplate,
          majorVersion: this.templateVersion?.major_version
        }
      })
    );

    modalRef.content.onMajorVersionCreated.subscribe(() => {
      this.getVersionDetail(this.templateVersion, false);
    });
  }
}
