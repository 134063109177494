import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Application } from '../../../app/models/application';
import { ApplicationService } from '../../../app/services/application.service';

@Component({
  selector: 'edit-add-application-modal',
  templateUrl: './edit-application-modal.component.html'
})
export class EditApplicationModalComponent implements OnInit {
  @Output() onApplicationUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public application: Application = new Application();
  public showRolesValidationMessage = false;
  public types = [{
    label: "Mobile",
    value: "MOBILE"
  }, {
    label: "Web",
    value: "WEB"
  }];
  
  public sessionTimeOutMinutesMin = 5;
  public sessionTimeOutMinutesMax = 525600;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public applicationService: ApplicationService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      active: [String(this.application.active), [Validators.required]],
      name: [this.application.name, [Validators.required, Validators.min(1)]],
      application_url: [this.application.frontend_url, [Validators.required, Validators.min(1)]],
      type: [this.application.type, [Validators.required]],
      role_patient: [this.application.isAccecibleBy('ROLE_PATIENT')],
      role_hcp: [this.application.isAccecibleBy('ROLE_HCP')],
      role_cc: [this.application.isAccecibleBy('ROLE_CC')],
      minimum_required_version: [this.application.minimum_version, [Validators.required, Validators.min(1), Validators.max(50)]],
      session_time_out_minutes: [this.application.session_time_out_minutes, [Validators.required, Validators.min(this.sessionTimeOutMinutesMin), Validators.max(this.sessionTimeOutMinutesMax)]],
      secret: [this.application.secret, [Validators.required, Validators.min(1)]],
      app_uid: [this.application.uid]
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    const values = this.form.value;
    if (!values.role_patient && !values.role_hcp && !values.role_cc) {
      this.showRolesValidationMessage = true;
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    this.applicationService.updateApplication(this.form.value, this.application.uid).subscribe(() => this.onCreateSuccess(), 
      (error) => this.isSaving = false);
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.edit_application.updated'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.onApplicationUpdated.emit();
    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
