import { Component } from '@angular/core';

@Component({
  selector: 'app-flow-copyrights',
  templateUrl: './flow-copyrights.component.html',
  styleUrls: ['./flow-copyrights.component.scss']
})
export class FlowCopyrightsComponent {
  public thisYear: string;

  constructor() {
    this.thisYear = new Date().getFullYear().toString();
  }
}
