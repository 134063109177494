import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Application } from 'src/app/models/application';
import { AddApplicationToHospitalModalComponent } from '../../../../app/modals/add-application-to-hospital-modal/add-application-to-hospital-modal.component';
import { Hospital } from '../../../../app/models/hospital';
import { GeneralService } from '../../../../app/services/general.service';
import { HospitalService } from '../../../../app/services/hospital.service';

@Component({
  selector: 'app-hospital-applications',
  templateUrl: './hospital-applications.component.html',
  styleUrls: ['./hospital-applications.component.scss']
})
export class HospitalApplicationsComponent implements OnInit {
  public hospital: Hospital| undefined;  
  public hospitalUid: string;

  public applications: Array<Application> = [];
  public isLoading: boolean = false;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public hospitalService: HospitalService,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.getHospital();
      this.loadApplications();
    });
  }

  public onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.loadApplications();
    }
  }

  public onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.loadApplications();
    }
  }

  private loadApplications() {
    this.isLoading = true;
    this.hospitalService.getApplications(this.hospitalUid, this.currentPage - 1, this.currentPageSize).subscribe(result => {
      console.log('applications', result.applications);
      this.applications = result.applications;
      
      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements
      }

      this.isLoading = false;
    });
  }

  private getHospital() {
    this.hospitalService.getHospital(this.hospitalUid).subscribe(hospital => this.hospital = hospital);
  }

  public addApplication(event: MouseEvent) {
    event.preventDefault();
    const modalref  = this.modalService.show(AddApplicationToHospitalModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          linkedApplications: this.applications
        }
      })
    );

    modalref.content.onAppsAdded.subscribe(() => {
      this.loadApplications();
    });
  }
}
