import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LocaleService } from '../services/locale.service';


@Pipe({ name: 'timeZoneDate', pure: false })
export class TimeZoneDatePipe implements PipeTransform {
  constructor(
    private readonly localeService: LocaleService,
  ) { }

  transform(date: string, format?: string): string | null {
    const timeZone = this.localeService.getLocalePreferences().locale?.time_zone;
    let dateFormat = this.localeService.getLocalePreferences().dateFormat?.format;

    if (format) {
      dateFormat = format;
    }

    if (!dateFormat) {
      return null;
    }

    let mDate = moment(date);
    if (!mDate.isValid()) {
      mDate = moment(new Date(date));
    }

    dateFormat = this.localeService.transformFormatToMomentFormat(dateFormat);

    if (timeZone) {
      return mDate.tz(timeZone).format(dateFormat);
    } else {
      return mDate.format(dateFormat);
    }
  }
}
