import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Application } from '../../models/application';
import { PlatformConsent } from '../../models/platform-consent';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-remove-application-consent',
  templateUrl: './remove-application-consent.component.html',
  styleUrls: ['./remove-application-consent.component.scss']
})
export class RemoveApplicationConsentComponent implements OnInit {
  @Output() onApplicationUpdated = new EventEmitter<void>();

  public application: Application;
  public consent: PlatformConsent;

  public isSaving: boolean = false;
  
  constructor(
    public bsModalRef: BsModalRef,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
  }

  public remove() {
    this.isSaving = true;
    this.application.consents = this.application.consents.filter((item) => item !== this.consent.key);

    this.applicationService.update(this.application).subscribe(() => {
      this.onApplicationUpdated.emit();
      this.bsModalRef.hide();
    }, error => {
      console.debug('error', error);
      this.isSaving = false;
    });
  }

  public onHandleClose() {
    this.bsModalRef.hide();
  }
}
