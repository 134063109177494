<div class="row">
  <div class="col">
    <div class="form-group styled floating-label mb-0">
      <input type="text" id="inputDate" class="form-control w-100" [ngClass]="{'ng-invalid-important': !editDateValid}"
        bsDatepicker autocomplete="off" [(ngModel)]="editDateValue" (ngModelChange)="onDateNgModelChange($event)"
        [disabled]="disabled">
      <label for="inputDate">{{ 'form.labels.date' | translate }}</label>
    </div>
  </div>
  <div class="col">
    <div class="form-group mb-0">
      <timepicker id="timePickerField" class="with-label" [ngClass]="{'ng-invalid-important': !editTimeValid}"
        [showMeridian]="!time_24_hours" [showSpinners]="false" dir="ltr" [(ngModel)]="editTimeValue"
        (ngModelChange)="onTimeNgModelChange($event)" [disabled]="disabled"></timepicker>
      <label for="timePickerField">{{ 'form.labels.time' | translate }}</label>
    </div>
  </div>
</div>
