import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CareModuleType } from '../../models/care-module';

@Component({
  selector: 'app-care-module-name',
  templateUrl: './care-module-name.component.html'
})
export class CareModuleNameComponent implements OnInit {
  @Input() name: string;
  @Output() nameChange = new EventEmitter<string>();

  @Input() description: string;
  @Output() descriptionChange = new EventEmitter<string>();

  @Input() type: CareModuleType;
  @Output() typeChange = new EventEmitter<CareModuleType>();

  @Input() nameStepValid: boolean;
  @Output() nameStepValidChange = new EventEmitter<boolean>();

  public CareModuleType = CareModuleType;
  public form: FormGroup;
  public validationVisible = false;

  constructor() { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = new FormGroup({
      name: new FormControl(this.name, [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]),
      description: new FormControl(this.description, [Validators.required]),
      type: new FormControl(this.type, Validators.required),
    });

    this.form.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.validationVisible = false;
        
        this.name = this.form.get('name')?.value;
        this.nameChange.emit(this.name);

        this.description = this.form.get('description')?.value;
        this.descriptionChange.emit(this.description);

        this.type = this.form.get('type')?.value;
        this.typeChange.emit(this.type);
        
      } else if(this.form.get('name')?.value && this.form.get('description')?.value && this.form.get('type')?.value) {
        this.validationVisible = true;
      }
      
      this.nameStepValidChange.emit(this.form.valid);
    });
  }
}
