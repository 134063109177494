import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { IEnvironment } from '../../interfaces/environment.interface';

@Component({
  selector: 'app-select-environment',
  templateUrl: './select-environment.component.html',
  styleUrls: ['./select-environment.component.scss']
})
export class SelectEnvironmentComponent implements OnInit {
  public environments: Array<IEnvironment> = [];
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.environments = environment.environments;
  }

}
