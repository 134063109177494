<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_admin_user.title' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label">
      <input type="text" id="first_name" class="form-control w-100" [placeholder]="'form.labels.first_name' | translate" formControlName="first_name">
      <label for="first_name" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>
    <div class="form-group styled floating-label">
      <input type="text" id="last_name" class="form-control w-100" [placeholder]="'form.labels.last_name' | translate" formControlName="last_name">
      <label for="last_name" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>
    <div class="form-group styled floating-label">
      <input  [attr.disabled]="true" type="text" id="email" class="form-control w-100" [placeholder]="'form.labels.email' | translate" formControlName="email">
      <label for="email" class="required">{{ 'form.labels.email_address' | translate }}</label>
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>
    <div class="form-group styled mt-4">
      <h3>{{ 'modals.add_admin_user.roles' | translate }}</h3>
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="checkboxOrgAdmin" formControlName="role_org_admin">
        <label class="custom-control-label" for="checkboxOrgAdmin">{{ 'form.labels.org_admin' | translate }}</label>
      </div>
    </div>
    <div class="form-group styled">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="checkboxUserAdmin" formControlName="role_user_admin">
        <label class="custom-control-label" for="checkboxUserAdmin">{{ 'form.labels.user_admin' | translate }}</label>
      </div>
    </div>
    <div class="form-group styled">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="checkboxHotline" formControlName="role_hotline">
        <label class="custom-control-label" for="checkboxHotline">{{ 'form.labels.hotline' | translate }}</label>
      </div>
    </div>
    <div *ngIf="showRolesValidationMessage">
      <div class="text-danger">{{ 'modals.add_admin_user.select_role_error' | translate }}</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
