export class HospitalConsent {
  public key: string;
  public category: string;
  public languages: Array<string> = [];
  public is_patient_consent: boolean;
  public is_hcp_consent: boolean;
  public version: number;
  public order: number;
  public enabled: boolean;

  constructor(item? :any) {
    if (item) {
      this.key = item.key;
      this.category = item.category;
      this.languages = item.languages;
      this.is_patient_consent = item.is_patient_consent;
      this.is_hcp_consent = item.is_hcp_consent;
      this.version = item.version;
      this.order = item.order;
      this.enabled = item.enabled;
    }
  }
}
