<div class="modal-header pb-0">
  <h4>{{ 'modals.edit_cm_template.title' | translate }}</h4>

  <button type="button" class="close" id="template-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>

<div class="modal-body">
  <app-care-module-pathway [(pathwayTemplate)]="pathwayTemplate" [(pathwayTemplateValid)]="pathwayTemplateValid"
    [(configurationParameters)]="configurationParameters" [currentConfigurationParameters]="currentConfigurationParameters"></app-care-module-pathway>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' |
      translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving, 'disabled': !buttonEnabled}"
      class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.edit_cm_template.save' | translate }}</span>
    </button>
  </div>
</div>
