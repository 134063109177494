import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CareModalEditTemplateComponent } from '../../../modals/care-modal-edit-template/care-modal-edit-template.component';
import { EditCmTypeComponent } from '../../../modals/edit-cm-type/edit-cm-type.component';
import { EditDefaultMedicalTeamComponent } from '../../../modals/edit-default-medical-team/edit-default-medical-team.component';
import { SetHospitalCodeComponent } from '../../../modals/set-hospital-code/set-hospital-code.component';
import { CareModule } from '../../../models/care-module';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { HospitalCode } from '../../../models/hospital-code';
import { PathwayTemplate } from '../../../models/pathway-template';
import { CareModuleService } from '../../../services/care-module.service';
import { GeneralService } from '../../../services/general.service';
import { HcpService } from '../../../services/hcp.service';
import { HospitalCodeService } from '../../../services/hospital-code.service';
import { HospitalService } from '../../../services/hospital.service';
import { PathwayTemplateService } from '../../../services/pathway-template-service.service';

@Component({
  selector: 'app-care-module-settings',
  templateUrl: './care-module-settings.component.html',
  styleUrls: ['./care-module-settings.component.scss']
})
export class CareModuleSettingsComponent implements OnInit {
  public hospitalUid: string;
  public careModuleUid: string;
  public careModule: CareModule;
  public isLoading = true;
  public template: PathwayTemplate;
  public hospitalCode?: HospitalCode;
  public paramCount: string;

  public defaultCaseManager?: HealthCareProfessional;
  public defaultClinicalLead?: HealthCareProfessional;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public hospitalService: HospitalService,
    public modalService: BsModalService,
    public careModuleService: CareModuleService,
    public pathwayTemplateService: PathwayTemplateService,
    public hospitalCodeService: HospitalCodeService,
    private hcpService: HcpService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.careModuleUid = params.careModuleUid;
      this.getCareModule();
      this.getHospitalCode();
    });
  }

  private getCareModule() {
    this.careModuleService.getCareModule(this.hospitalUid, this.careModuleUid)
      .subscribe(careModule => { 
        this.careModule = careModule;
        this.paramCount = String(Object.keys(this.careModule.configuration_parameters).length);
        this.getTemplate();
        this.getHcps();
      });
  }

  private getHcps() {
    if (this.careModule.default_case_manager_uid) {
      this.hcpService.get(this.hospitalUid, this.careModule.default_case_manager_uid).subscribe(hcp => this.defaultCaseManager = hcp);
    }

    if (this.careModule.default_clinical_lead_uid) {
      this.hcpService.get(this.hospitalUid, this.careModule.default_clinical_lead_uid).subscribe(hcp => this.defaultClinicalLead = hcp);
    }
  }

  private getHospitalCode() {
    this.hospitalCodeService.getHospitalCodes(this.hospitalUid, 0, 999).subscribe(result => {
      this.hospitalCode = result.hospital_codes.find((item) => item.care_module_uid === this.careModuleUid);
    });
  }

  private getTemplate() {
    this.pathwayTemplateService.getTemplate(this.careModule.pathway_template_uid).subscribe((template) => {
      this.template = template;
      this.isLoading = false;
    });
  }

  public editTemplate() {
    this.template.major_version = this.careModule.pathway_template_version;

    const modalRef = this.modalService.show(CareModalEditTemplateComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xxl',
        initialState: {
          hospitalUid: this.hospitalUid,
          pathwayTemplate: this.template,
          careModule: this.careModule
        }
      })
    );

    modalRef.content.onCareModuleUpdated.subscribe(() => {
      this.getCareModule();
    });
  }

  public setHospitalCode(event: MouseEvent) {
    event.preventDefault();

    const modalRef = this.modalService.show(SetHospitalCodeComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModule: this.careModule,
          hospitalCode: this.hospitalCode
        }
      })
    );

    modalRef.content.onHospitalCodeSet.subscribe(() => {
      this.getHospitalCode();
    });
  }

  public editCareModuleType(event: MouseEvent) {
    event.preventDefault();

    const modalRef = this.modalService.show(EditCmTypeComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModule: this.careModule
        }
      })
    );

    modalRef.content.onCareModuleUpdated.subscribe(() => {
      this.getCareModule();
    });
  }

  public editDefaultMedicalTeam(event: MouseEvent) {
    event.preventDefault();

    const modalRef = this.modalService.show(EditDefaultMedicalTeamComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careModule: this.careModule
        }
      })
    );

    modalRef.content.onCareModuleUpdated.subscribe(() => {
      this.getCareModule();
    });
  }
}
