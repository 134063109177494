<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part mb-0">
      <app-flow-welcome></app-flow-welcome>
      
      <div class="environment-indicator-nagative">
        <app-environment-indicator [readOnly]="true"></app-environment-indicator>
      </div>
    </div>

    <div class="content-part">
      <app-page-loader></app-page-loader>
    </div>

    <div class="footer-part">
    </div>
  </div>
</div>

<div class="layout-body fixed with-background"></div>
