import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import AdminRole from "../../../app/enum/AdminRole";
import { AdminUser } from "../../../app/models/admin-user";
import { AdminUsersService } from "../../../app/services/admin-users.service";

@Component({
  selector: 'app-edit-admin-user-modal',
  templateUrl: './edit-admin-user.component.html'
})
export class EditAdminUserComponent implements OnInit {
  @Output() onUserUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public showRolesValidationMessage = false;
  public isSaving = false;
  public adminUser: AdminUser = new AdminUser();
  public role_org_admin: boolean;
  public role_user_admin: boolean;
  public role_hotline: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public adminUserService: AdminUsersService
  ) { }
  
  ngOnInit(): void {
    this.role_org_admin = this.adminUser.isOrgAdmin();
    this.role_user_admin = this.adminUser.isUserAdmin();
    this.role_hotline = this.adminUser.isHotline();
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      first_name: new UntypedFormControl(this.adminUser.first_name, [Validators.required, Validators.min(1), Validators.max(50)]),
      last_name: new UntypedFormControl(this.adminUser.last_name, [Validators.required, Validators.min(1), Validators.max(50)]),
      email: new UntypedFormControl(this.adminUser.email, [Validators.required, Validators.min(1), Validators.email]),
      role_org_admin: new UntypedFormControl(this.role_org_admin),
      role_user_admin: new UntypedFormControl(this.role_user_admin),
      role_hotline: new UntypedFormControl(this.role_hotline)
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    const values = this.form.value;
    if (!values.role_org_admin && !values.role_user_admin && !values.role_hotline) {
      this.showRolesValidationMessage = true;
      this.validationVisible = true;
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.showRolesValidationMessage = false;
    this.validationVisible = false;
    this.isSaving = true;

    this.adminUser.first_name = this.form.get('first_name')?.value;
    this.adminUser.last_name = this.form.get('last_name')?.value;

    if (this.role_hotline) {
      this.adminUser.addRole(AdminRole.ROLE_HOTLINE);
    } else {
      this.adminUser.removeRole(AdminRole.ROLE_HOTLINE);
    }

    if (this.role_org_admin) {
      this.adminUser.addRole(AdminRole.ROLE_ORG_ADMIN);
    } else {
      this.adminUser.removeRole(AdminRole.ROLE_ORG_ADMIN);
    }

    if (this.role_user_admin) {
      this.adminUser.addRole(AdminRole.ROLE_USER_ADMIN);
    } else {
      this.adminUser.removeRole(AdminRole.ROLE_USER_ADMIN);
    }
    
    this.adminUserService.update(this.adminUser).subscribe(() => {
      this.showSuccessToast();
      this.onHandleClose();
      this.onUserUpdated.emit();
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_admin_user.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
