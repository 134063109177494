import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { DialCode } from "../../models/dial-code";
import { HcpFunction } from "../../models/hcp-function";
import { CareCoordinatorService } from "../../services/care-coordinator.service";
import { GeneralService } from "../../services/general.service";
import { HcpService } from "../../services/hcp.service";
import { LanguageService } from "../../services/language.service";
import { LanguageCode } from "../../models/language-code";

@Component({
  selector: 'add-care-coordinator-modal',
  templateUrl: './add-care-coordinator.component.html'
})
export class AddCareCoordinatorModalComponent implements OnInit {
  @Output() onCareCoordinatorCreated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public showRolesValidationMessage = false;
  public isSaving = false;
  public dialCodeOptions: DialCode[] = [];
  public languageOptions: LanguageCode[];
  public functionOptions: HcpFunction[] = [];
  public hospitalUid: string;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public careCoordinatorService: CareCoordinatorService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public hcpService: HcpService
  ) { }
  
  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.generalService.getDialCodes(),
      this.languageService.getLanguageCodes(),
      this.hcpService.getFunctions(this.hospitalUid)
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.dialCodeOptions = data[0];
    this.languageOptions = data[1];
    this.functionOptions = data[2];
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      gender: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      preferred_name: ['', []],
      last_name: ['', [Validators.required]],
      language: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      function: ['', [Validators.required]],
      job_title: ['', [Validators.required]],
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl('', [Validators.required]),
        number: new UntypedFormControl('', [Validators.required])
      })
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.showRolesValidationMessage = false;
    this.validationVisible = false;
    this.isSaving = true;

    let values = this.form.value;

    if (values.preferred_name === '') {
      delete values.preferred_name;
    }

    this.careCoordinatorService.create(this.hospitalUid, this.form.value)
      .subscribe(
        () => this.onSuccess(),
        () => this.isSaving = false
      );
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public onSuccess(): void {
    this.onCareCoordinatorCreated.emit();
    this.bsModalRef.hide();
    this.toastrService.info(this.translate.instant('modals.add_cc.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
