import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { IEnvironment } from '../../interfaces/environment.interface';
import { SelectEnvironmentComponent } from '../../modals/select-environment/select-environment.component';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-environment-indicator',
  templateUrl: './environment-indicator.component.html',
  styleUrls: ['./environment-indicator.component.scss']
})
export class EnvironmentIndicatorComponent implements OnInit {
  @Input() readOnly: boolean;
  
  private environments: Array<IEnvironment> = [];
  public currentEnvironment: IEnvironment;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    this.environments = environment.environments;
    this.setCurrentEnvironment();
  }

  private setCurrentEnvironment() {
    const result = this.environments.find((item) => item.paths.includes(window.location.origin));

    if (result) {
      this.currentEnvironment = result;
    } else {
      this.currentEnvironment = this.environments[0];
    }
  }

  public openSelector(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(SelectEnvironmentComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }
}
