import { ModuleFeaturePermission } from "./module-feature-permission"

export class ModuleFeature {
  name: string;
  enabled: boolean;
  permissions: Array<ModuleFeaturePermission>;

  constructor(input?: {
    name: string,
    enabled: boolean,
    permissions?: Array<any>
  }) {
    if (input) {
      this.name = input.name;
      this.enabled = input.enabled;
      
      if (input.permissions) {
        this.permissions = input.permissions?.map(permission => new ModuleFeaturePermission(permission));  
      } else {
        // At time of development all permissions are default 
        // Please expand when neccesary.
        this.permissions = [ModuleFeaturePermission.default];
      }
      
    }
  }
}
