import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../../services/general.service';
import { GDPRExportModalComponent } from '../../../modals/gdpr-export-modal/gdpr-export-modal.component';
import { GDPRRestrictModalComponent } from '../../../modals/gdpr-restrict-modal/gdpr-restrict-modal.component';
import { GDPRDeleteModalComponent } from '../../../modals/gdpr-delete-modal/gdpr-delete-modal.component';

@Component({
  selector: 'app-gdpr-admin',
  templateUrl: './gdpr-admin.component.html'
})
export class GDPRAdminComponent {
  public isLoading: boolean = false;

  constructor(
    public modalService: BsModalService) {
  }

  export(event: Event): void {
    event.preventDefault();
    
    const modalRef = this.modalService.show(GDPRExportModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  restrict(event: Event): void {
    event.preventDefault();
    
    const modalRef = this.modalService.show(GDPRRestrictModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  delete(event: Event): void {
    event.preventDefault();

    const modalRef = this.modalService.show(GDPRDeleteModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }
}
