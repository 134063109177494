export interface PathwayTemplateFilter {
  group: string,
  name: string,
  sub_process_filter_type: SubProcessFilterType | '',
  upload_date: string
}

export enum SubProcessFilterType {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
  ONLY = 'ONLY'
}
