import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../src/environments/environment";
import PaginationInterface from "../interfaces/pagination.interface";
import { Application } from "../models/application";
import { Hospital } from "../models/hospital";
import { ApiService } from "./api.service";
import { ApplicationService } from "./application.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class HospitalService extends ApiService {
  private platformUrl: string;

  constructor(
    http: HttpClient, 
    authenticationService: AuthenticationService, 
    private applicationService: ApplicationService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getHospitals(filters: any = {}, sort = 'name,desc',  page: number = 0, size: number = 25): Observable<any> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      if (filters.hasOwnProperty('uid') && filters['uid']) {
        paramBits.push(`uid=${filters.uid}`);
      }

      if (filters.hasOwnProperty('name') && filters['name']) {
        paramBits.push(`name=${filters.name}`);
      }

      if (filters.hasOwnProperty('city') && filters['city']) {
        paramBits.push(`address.city=${filters.city}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospitals = this.mapHospitals(result['items']);
        observer.next({hospitals, pagination: result.pagination});
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  getHospital(uid: string): Observable<Hospital> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${uid}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospital = this.mapHospital(result);
        observer.next(hospital);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createHospital(
    data: any
  ):Observable<Hospital> {
    const url = `${environment.platformUrl}/hospitals`;
    return new Observable(observer => {

      // remove empty fields
      if (data && data.address && data.address.bus == '') {
        delete data.address['bus'];
      }
      
      if (data && data.general_configuration?.hospital_code_days_valid_indefinite) {
        delete data.general_configuration.hospital_code_days_valid;
      }
 
      data.active = (/true/i).test(data.active);

      data['datasource_configuration'] = {};
      data['integration_configuration'] = {};
      data['datasource_configuration']['external'] = false;
      data['integration_configuration']['external'] = false;

      this.authenticatedPost(url, data).subscribe((result: HttpResponse<any>) => {
        observer.next(new Hospital(result.body));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updateHospital(hospital: Hospital): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital.uid}`;
    return new Observable(observer => {
      this.authenticatedPut(url, hospital).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  addApplication(appUid: string, hospitalUid: string) {
    return new Observable(observer => {
      this.authenticatedPost(`${this.platformUrl}/hospitals/${hospitalUid}/applications`, [appUid]).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  getApplications(hospitalUid: string, page: number = 0, size: number = 25): Observable<{applications: Array<Application>, pagination: PaginationInterface}> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');

      this.authenticatedGet(`${this.platformUrl}/hospitals/${hospitalUid}/applications?${paramsString}`).subscribe((result) => {
        console.debug('result', result);
        const applications = this.applicationService.mapApplications(result.items);
        observer.next({
          applications,
          pagination: result.pagination
        });
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  mapHospitals(list: any[]): Array<Hospital> {
    const hospitals = new Array();
    list.forEach(item => {
      hospitals.push(this.mapHospital(item));
    });
    return hospitals;
  }

  mapHospital(input: any): Hospital {
    return new Hospital(input);
  }
}

