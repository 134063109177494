export class DateFormat {
  public key: string;
  public value: string;
  public label: string;
  public format: string;

  constructor(key: any, value: any) {
    this.key = this.value = key;
    this.label = this.format = value;
  }
}