import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddApplicationConsentComponent } from '../../../modals/add-application-consent/add-application-consent.component';
import { EditApplicationConsentComponent } from '../../../modals/edit-application-consent/edit-application-consent.component';
import { RemoveApplicationConsentComponent } from '../../../modals/remove-application-consent/remove-application-consent.component';
import { Application } from '../../../models/application';
import { PlatformConsent } from '../../../models/platform-consent';
import { ApplicationService } from '../../../services/application.service';
import { ConsentService } from '../../../services/consent.service';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-application-consents',
  templateUrl: './application-consents.component.html',
  styleUrls: ['./application-consents.component.scss']
})
export class ApplicationConsentsComponent implements OnInit {
  public application: Application;
  public applicationUid: string;
  public consents: Array<PlatformConsent> = [];
  public isLoading: boolean = true;

  constructor(
    public applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute,
    public consentService: ConsentService,
    public modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.applicationUid = params.applicationUid;
      this.loadData();
    });
  }

  public loadData() {
    this.isLoading = true;
    this.applicationService.getApplication(this.applicationUid).subscribe(app => this.applicationLoaded(app));
  }

  private applicationLoaded(application: Application) {
    this.application = application;
    this.consents = [];

    console.debug('app', application);

    if (application.consents.length === 0) {
      this.isLoading = false;
    }

    application.consents.forEach((consentKey) => {
      this.consentService.getPlatformConsent(consentKey).subscribe((consent) => {
        this.consents.push(consent);
        if (this.consents.length === this.application.consents.length) {
          this.isLoading = false;
        }
      });
    });
  }

  public editConsent(event: MouseEvent, consent: PlatformConsent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditApplicationConsentComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          consent,
          application: this.application
        }
      })
    );

    modalRef.content.onApplicationUpdated.subscribe(() => this.loadData());
  }

  public addConsent(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddApplicationConsentComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          application: this.application
        }
      })
    );

    modalRef.content.onApplicationUpdated.subscribe(() => this.loadData());
  }

  public removeConsent(event: MouseEvent, consent: PlatformConsent) {
    event.preventDefault();
    const modalRef = this.modalService.show(RemoveApplicationConsentComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          consent,
          application: this.application
        }
      })
    );

    modalRef.content.onApplicationUpdated.subscribe(() => this.loadData());
  }
}
