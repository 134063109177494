import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Output() onError: EventEmitter<any> = new EventEmitter();

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
  }

  basicAuthGet(url: string, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const authorization = btoa(`${environment.auth_username}:${environment.auth_password}`);
      
      const headers = new HttpHeaders({
        Authorization: `Basic ${authorization}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.get(url, {headers}).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  basicAuthPost(url:string , params: any, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const authorization = btoa(`${environment.auth_username}:${environment.auth_password}`);
      const headers = new HttpHeaders({
        Authorization: `Basic ${authorization}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });
      
      this.http.post(url, params, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  basicAuthPut(url: string, params: any, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const authorization = btoa(`${environment.auth_username}:${environment.auth_password}`);
      const headers = new HttpHeaders({
        Authorization: `Basic ${authorization}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.put(url, params, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  authenticatedPost(url: string, params: any, apiVersion: number = 1): Observable<HttpResponse<any>> {
    return new Observable<HttpResponse<any>>(observer => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.post(url, params, {headers:headers, observe: 'response'}).subscribe((result: HttpResponse<any>)  => {
        this.authenticationService.setAuthenticationData(result.body);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  authenticatedGet(url: string, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.get(url, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  authenticatedPatch(url: string, params: any, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.patch(url, params, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  authenticatedPut(url: string, params: any, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {   
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.put(url, params, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        console.error(error);
        observer.error(error);
        observer.complete();
      });
    });
  }

  authenticatedDelete(url: string, apiVersion: number = 1): Observable<any> {
    return new Observable(observer => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
        accept: environment.accept_header.replace('.v1', `.v${apiVersion}`),
        'content-type':  environment.content_type_header.replace('.v1', `.v${apiVersion}`)
      });

      this.http.delete(url, {headers}).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  cmsGet(url: string, version?: number | false, query?: string[]): Observable<any> {
    return new Observable(observer => {
      const params: any = {
        environment: environment.cmsEnvironment,
        locale: 'en',
        include_fallback: true,
        include_count: true
      };
      
      if (version) {
        params.version = String(version);
      }

      if (query) {
        params.query = `{"uid":{"$in":["${query.join('","')}"]}}`;
      }

      const httpOptions = {
        headers: new HttpHeaders({
          api_key:  environment.cmsApiKey,
          access_token: environment.cmsAccessToken
        }),
        params
      };

      this.http.get(url, httpOptions).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public  getAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authenticationService.getAccessToken()}`
    });
  }
}

export interface PagingDto<T> {
  items: T[];
  pagination: {
    current_page: number,
    total_pages: number;
    total_elements: number;
    page_size: number;
  };
}
