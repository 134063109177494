import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditContactInformationComponent } from '../../../modals/edit-contact-information/edit-contact-information.component';
import { EditPasswordComponent } from '../../../modals/edit-password/edit-password.component';
import { EditPersonalInformationComponent } from '../../../modals/edit-personal-information/edit-personal-information.component';
import { EditPreferencesComponent } from '../../../modals/edit-preferences/edit-preferences.component';
import { Profile } from '../../../models/profile';
import { GeneralService } from '../../../services/general.service';
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  public profile: Profile;

  constructor(
    public modalService: BsModalService,
    private userService: UserService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.userService.getProfile().subscribe((result) => this.profile = result);
  }

  editPassword(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditPasswordComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  editPersonalInformation(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditPersonalInformationComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          profile: this.profile
        }
      })
    );

    modalRef.content.onInformationUpdated.subscribe(() =>  this.loadData());
  }

  editContactInformation(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditContactInformationComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          profile: this.profile
        }
      })
    );

    modalRef.content.onInformationUpdated.subscribe(() =>  this.loadData());
  }

  editPreferences(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(EditPreferencesComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          profile: this.profile
        }
      })
    );

    modalRef.content.onInformationUpdated.subscribe(() =>  this.loadData());
  }
}
