import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Hospital } from '../../../../app/models/hospital';
import { HospitalService } from '../../../../app/services/hospital.service';
import { AddCareCoordinatorModalComponent } from '../../../modals/add-care-coordinator/add-care-coordinator.component';
import { EditCareCoordinatorModalComponent } from '../../../modals/edit-care-coordinator/edit-care-coordinator.component';
import { CareCoordinator } from '../../../models/care-coordinator';
import { CareCoordinatorService } from '../../../services/care-coordinator.service';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-hospital-care-coordinators',
  templateUrl: './hospital-care-coordinators.component.html'
})
export class HospitalCareCoordinatorsComponent implements OnInit {
  public care_coordinators: Array<CareCoordinator>  =  [];
  public hospital: Hospital| undefined;  
  public hospitalUid: string;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  public isLoading = false;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public hospitalService: HospitalService,
    public modalService: BsModalService,
    public careCoordinatorService: CareCoordinatorService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.getHospital();
      this.getCareCoordinators();
    });
  }

  private getHospital() {
    this.hospitalService.getHospital(this.hospitalUid, ).subscribe(hospital => this.hospital = hospital);
  }

  private getCareCoordinators() {
    this.isLoading = true;
    this.careCoordinatorService.getCareCoordinators(this.hospitalUid, this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.isLoading = false;
      this.care_coordinators = result.care_coordinators;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }
    })
  }

  onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getCareCoordinators();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getCareCoordinators();
    }
  }

  public addCareCoordinator(event: MouseEvent) {
    event.preventDefault();
    const modalRef = this.modalService.show(AddCareCoordinatorModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
        }
      })
    );

    modalRef.content.onCareCoordinatorCreated.subscribe(() => this.getCareCoordinators());
  }

  public editCareCoordinator(event: MouseEvent, careCoordinator: CareCoordinator) {
    event.preventDefault();

    const modalRef = this.modalService.show(EditCareCoordinatorModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          hospitalUid: this.hospitalUid,
          careCoordinatorUid: careCoordinator.uid
        }
      })
    );

    modalRef.content.onCareCoordinatorUpdated.subscribe(() => this.getCareCoordinators());
  }
}
