import { TranslationInterface } from "../interfaces/translation.interface";
import { EducationalMaterial } from "./educational-material";

export class Phase {
  public id: string;
  public name: TranslationInterface;
  public order: number;
  public sub_phase_definitions: Array<Phase>;
  public educational_materials: Array<EducationalMaterial> = [];
  public mappingKey: string;
  public parent: Phase;
  public translatedName: string = '';
  public careModuleEducationalMaterialsId: string;


  constructor(data?: any, parent?: Phase) {
    if (parent) {
      this.parent = parent;
      this.mappingKey = parent.mappingKey + '.' + data.id;
    } else {
      this.mappingKey = data.id;
    }
    
    if (data) {
      this.mapFromJson(data);
    }
  }

  getPhaseTree() {
    let keepGoing = true;
    let phase: Phase = this;
    let tree: any = {
      id: this.id
    };

    if (!this.parent) {
      return tree;
    }

    while (keepGoing) {
      if (phase.parent) {
        tree = {
          id: phase.parent.id,
          sub_phase_selection: tree
        };

        phase = phase.parent;
      } else {
        keepGoing = false;
      }
    }

    return tree;
  }

  private mapFromJson(data: any) {
    this.id = data.id;
    this.order = data.order;
    this.name = data.name;
    
    this.sub_phase_definitions = [];
    if (data.sub_phase_definitions && data.sub_phase_definitions.length > 0) {
      data.sub_phase_definitions.forEach((item: any) => {
        this.sub_phase_definitions.push(new Phase(item, this));
      });
    }
  }

  countEducationalMaterials() {
    let count = this.educational_materials.length;

    for (let subPhase of this.sub_phase_definitions) {
      count += subPhase.countEducationalMaterials();
    }

    return count;
  }
}
