import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import PaginationInterface from "../interfaces/pagination.interface";
import { TranslationInterface } from "../interfaces/translation.interface";
import { CareModule } from "../models/care-module";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class CareModuleService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getCareModules(hospitalUid: string, page: number = 0, size: number = 25): Observable<{ care_modules: Array<CareModule>, pagination: PaginationInterface }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const care_modules = this.mapCareModules(result.items);
        observer.next({
          care_modules: care_modules,
          pagination: result.pagination
        });
        observer.complete();
      });
    });
  }


  public create(hospitalUid: string, payload: any) {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules`;

      this.authenticatedPost(url, payload, 3).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }
  
  // Add cl and cm to changes!!!!!!!!
  public createOLD(values: any, templateUid: string, hospitalUid: string, parameters: any) {
    const data = {
      'name': {
        'translations': [{
          'language': 'EN',
          'translation': values.name
        }]
      },
      'description': {
        'translations': [{
          'language': 'EN',
          'translation': values.description
        }]
      },
      'pathway_template_uid': templateUid,
      'configuration_parameters': parameters,
      'type': values.type,
      'default_case_manager_uid': values.default_case_manager_uid,
      'default_clinical_lead_uid': values.default_clinical_lead_uid
    };

    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules`;

      this.authenticatedPost(url, data, 3).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  public update(hospitalUid: string, careModule: CareModule) {
    return new Observable(observer => {
      let data: any = cloneDeep(careModule);
      
      delete data.original;
      delete data.educational_material_phases;
      delete data.care_module_educational_materials;
      delete data.sequence;
      delete data.uid;
      delete data.description.key;
      delete data.description.region;
      delete data.name.key;
      delete data.name.region;

      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModule.uid}`;

      this.authenticatedPut(url, data, 3).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  getCareModule(hospitalUid: string, careModuleUid: string): Observable<CareModule> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}`;
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapCareModule(result));
        observer.complete();
      });
    })
  }

  public getPhaseNameTranslation(translation: TranslationInterface) {
    return new Observable(observer => {
      const url = `${environment.cmsUrl}/v3/content_types/${translation.region}/entries`;

      this.cmsGet(url).subscribe(result => {
        let translationResult = translation.key;
        result.entries.forEach((page: any) => {
          for (const [key, value] of Object.entries(page.caremodules)) {
            if (key === translation.key) {
              // @ts-ignore
              translationResult = value;
            }
          }
        });

        observer.next(translationResult);
        observer.complete();
      },
        error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  private mapCareModules(list: any[]): Array<CareModule> {
    const care_modules = new Array();
    if (!list || !Array.isArray(list)) {
      return [];
    }
    list.forEach(item => {
      care_modules.push(this.mapCareModule(item));
    });
    return care_modules;
  }

  private mapCareModule(input: any): CareModule {
    return new CareModule(input);
  }
}
