<div class="pathway-template-detail" *ngIf="isLoading">
  <app-page-loader *ngIf="isLoading"></app-page-loader>
</div>

<div class="pathway-template-version-detail" *ngIf="!isLoading">
  <div class="layout-header-pusher header-filters">
  </div>

  <div class="layout-header header-filters">
    <div class="header-content">
      <div class="container">
        <button class="btn btn-link p-start-0" (click)="returnToTemplate()">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          {{ 'pages.default.template_version.back_to_template' | translate }}
        </button>

        <div class="title-button mb-2">
          <h2 class="text-truncate pb-0">{{ pathwayTemplate.name }}: {{ 'pages.default.template_version.version' |
            translate }} {{ templateVersion.major_version }}</h2>
          <div class="d-flex">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container">
      <h3>{{ 'pages.default.template_version.details' | translate }}</h3>
      <div class="action-item-block mb-4">
        <div class="block-wrapper p-start-3 p-end-1 w-100">
          <div class="row align-items-start">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h3>{{ 'pages.default.template_version.major_version' | translate }} {{ templateVersion.major_version
                    }}</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <label class="tiny m-0">{{ 'form.labels.reason' | translate }}</label>
                  <p id="description">
                    <span *ngIf="!templateVersion.reason || templateVersion.reason === ''">-</span>
                    {{ templateVersion.reason }}
                  </p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'pages.default.template_version.created_on' | translate }}</label>
                  <p id="description">{{ templateVersion.created_at | timeZoneDate }}</p>
                </div>

                <div class="col-4">
                  <label class="tiny m-0">{{ 'pages.default.template_version.latest_minor_version' | translate
                    }}</label>
                  <p id="description">{{ templateVersion.getMinorVersion(templateVersion.minor_version) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h3>{{ 'pages.default.template_version.parameters' | translate }}</h3>

      <ul class="parameters-haed list-unstyled">
        <li class="list-header row no-gutters text-muted small p-1 p-start-2 mb-1">
          <div class="col-3">{{ 'pages.default.template_version.key' | translate }}</div>
          <div class="col-5">{{ 'pages.default.template_version.type' | translate }}</div>
          <div class="col-4">{{ 'pages.default.template_version.default_value' | translate }}</div>
        </li>
      </ul>

      <ul class="parameters list-unstyled">
        <li *ngFor="let param of templateVersion.configuration_parameters" class="mb-1">
          <div class="row bg-white no-gutters p-start-2 align-items-center py-1">
            <div class="col-3">
              <p class="mb-0 text-truncate">{{ param.key }}</p>
            </div>
            <div class="col-5">
              <p class="mb-0 text-truncate">{{ param.data_type }}</p>
            </div>
            <div class="col-4">
              <p *ngIf="param.data_type === configurationsParameterType.DATE" class="mb-0 text-truncate">{{
                param.default_value | timeZoneDate }}</p>
              <p *ngIf="param.data_type === configurationsParameterType.DATE_TIME" class="mb-0 text-truncate">{{
                param.default_value | timeZoneDateTime }}</p>

              <p *ngIf="![configurationsParameterType.DATE, configurationsParameterType.DATE_TIME].includes(param.data_type)"
                class="mb-0 text-truncate">{{ param.default_value }}</p>
            </div>
          </div>
        </li>
      </ul>

      <p class="text-center text-muted" *ngIf="!templateVersion.configuration_parameters?.length">
        {{ 'pages.default.template_version.no_parameters' | translate }}
      </p>

      <hr />

      <div class="d-flex justify-content-between align-items-center mb-2">
        <h3 class="mb-0">{{ 'pages.default.template_version.minor_versions' | translate }}</h3>
        <button class="btn btn-secondary b-end-0" (click)="addMinorVersion()">
          {{ 'pages.default.template_version.add_minor_version' | translate }}
        </button>
      </div>

      <ul class="minor-versions list-unstyled">
        <li class="list-header row no-gutters text-muted small p-1 p-start-2 mb-1">
          <div class="col-3">{{ 'pages.default.template.version' | translate }}</div>
          <div class="col-9">{{ 'pages.default.template.reason' | translate }}</div>
        </li>
      </ul>

      <ul class="minor-versions list-unstyled">
        <li *ngFor="let version of templateVersion.version_history" class="mb-1">
          <div class="row bg-white no-gutters p-start-2 align-items-center py-1">
            <div class="col-3">
              <p class="mb-0 text-truncate">{{ templateVersion.getMinorVersion(version.minor_version) }}</p>
            </div>
            <div class="col-9">
              <p class="mb-0 text-truncate">
                <span *ngIf="!version.reason || version.reason === ''">-</span>
                {{ version.reason }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <p class="text-center text-muted" *ngIf="!templateVersion.version_history?.length">
        {{ 'pages.default.template_version.no_minor_versions' | translate }}
      </p>
    </div>
  </div>
</div>
