import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PathwayTemplate } from '../../models/pathway-template';
import { TemplateGroup } from '../../models/template-group';
import { PathwayTemplateService } from '../../services/pathway-template-service.service';

@Component({
  selector: 'app-select-pathway-folder-modal',
  templateUrl: './select-pathway-folder-modal.component.html'
})
export class SelectPathwayFolderModalComponent implements OnInit {
  @Output() onTemplateSelected = new EventEmitter<PathwayTemplate>();
  @ViewChild("searchTemplateName") searchTemplateNameInput: ElementRef;

  public groups: TemplateGroup[];
  public templates: PathwayTemplate[];
  public selectedTemplate: PathwayTemplate | undefined;
  public selectedGroup: TemplateGroup | undefined;

  public loadingTemplatesFor: string | undefined;

  public isLoading: boolean;
  public isSaving: boolean;
  public filter = {
    group: '',
    name: '',
    sub_process_filter_type: 'EXCLUDE'
  }

  public searchTemplateListEvent = new EventEmitter<string>();

  constructor(
    public pathwayTemplateService: PathwayTemplateService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.loadGroupsAndTemplates();

    this.searchTemplateListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.filter.name = result;

      this.getTemplates().subscribe(templates => {
        if (this.filter.group && this.selectedGroup) {
          templates = this.mapGroupToTemplates(templates, this.selectedGroup);
          this.groups = this.mapGroups(this.groups, templates);
        } else {
          this.templates = templates;
        }
      });
    });
  }

  public loadGroupsAndTemplates(): void {
    this.isLoading = true;

    forkJoin([
      this.getTemplates(),
      this.getGroups()
    ]).subscribe((result: [PathwayTemplate[], TemplateGroup[]]) => {
      this.templates = result[0];
      this.groups = result[1];

      this.isLoading = false;

      setTimeout(() => this.searchTemplateNameInput?.nativeElement.focus());
    });
  }

  getGroups(): Observable<TemplateGroup[]> {
    return new Observable(observer => {
      this.pathwayTemplateService.getGroups().subscribe((groups: TemplateGroup[]) => {
        observer.next(groups);
        observer.complete();
      });
    });
  }

  getTemplates(): Observable<PathwayTemplate[]> {
    return new Observable(observer => {
      this.loadingTemplatesFor = this.filter.group;

      this.pathwayTemplateService.getTemplates(this.filter, 'name,desc').subscribe(result => {
        this.loadingTemplatesFor = undefined;

        observer.next(result.templates);
        observer.complete();
      });
    });
  }

  mapGroups(groups: TemplateGroup[], templates: PathwayTemplate[]): TemplateGroup[] {
    for (let i = 0; i < groups.length; i++) {
      const filteredTemplates = templates.filter(template => template.group.path === groups[i].path);

      if (filteredTemplates) {
        groups[i].templates = filteredTemplates;
      }

      if (groups[i].groups?.length) {
        groups[i].groups = this.mapGroups(groups[i].groups, templates);
      }

    }

    return groups;
  }

  mapGroupToTemplates(templates: PathwayTemplate[], group: TemplateGroup): PathwayTemplate[] {
    // Because PF is handling groups and paths different;
    templates.forEach(template => {
      template.group = group;
    });
    
    return templates;
  }

  openGroup(group: TemplateGroup, event?: MouseEvent): void {
    event?.preventDefault();

    this.selectGroup(group);

    this.getTemplates().subscribe((templates: PathwayTemplate[]) => {
      templates = this.mapGroupToTemplates(templates, group);
      group.templates = templates;

      group.isOpen = true;
    });
  }

  selectGroup(group: TemplateGroup) {
    this.selectedGroup = group;
    this.filter.group = group.path;
    this.selectedTemplate = undefined;
  }

  selectTemplate(template: PathwayTemplate, event?: MouseEvent) {
    event?.preventDefault();

    this.selectedTemplate = template;
  }

  isSelected(selected: string): boolean {
    if (this.selectedGroup?.path === selected) {
      return true;
    }

    if (this.selectedTemplate?.uid === selected) {
      return true;
    }

    return false;
  }

  toggleGroup(group: TemplateGroup, event?: MouseEvent): void {
    event?.preventDefault();

    if (group.isOpen) {
      group.toggleOpen();
      this.selectedGroup = undefined;
      this.filter.group = '';
    } else {
      this.openGroup(group);
    }
  }

  onHandleSubmit(): void {
    if (!this.selectedTemplate || this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.onTemplateSelected.emit(this.selectedTemplate);
    this.bsModalRef.hide();
  }
}
