import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { Hospital } from "../..//models/hospital";
import { CountryService } from "../../../app/services/country.service";
import { HospitalService } from "../../../app/services/hospital.service";

@Component({
  selector: 'app-edit-hospital-address-modal',
  templateUrl: './edit-hospital-address-modal.component.html'
})
export class EditHospitalAddressModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;
  public countries: any[];

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public countryService: CountryService
  ) { }
  
  ngOnInit(): void {
    
    this.formSetup();
    
    forkJoin([
      this.countryService.getCountriesFromCms(),
    ]).subscribe(data => this.onDataLoaded(data));
    
  }

  onDataLoaded(data: any) {
    this.countries = data[0];
  };

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      address: new UntypedFormGroup({
        street: new UntypedFormControl(this.hospital.address.street, Validators.required),
        number: new UntypedFormControl(this.hospital.address.number, Validators.required),
        postal_code: new UntypedFormControl(this.hospital.address.postal_code, Validators.required),
        bus: new UntypedFormControl(this.hospital.address.bus),
        city: new UntypedFormControl(this.hospital.address.city, Validators.required),
        country: new UntypedFormControl(this.hospital.address.country, Validators.required)
      })
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);
    clonedHospital.address.street = this.form.get('address.street')?.value;
    clonedHospital.address.number = this.form.get('address.number')?.value;
    clonedHospital.address.postal_code = this.form.get('address.postal_code')?.value;
    if (this.form.get('address.bus')?.value) {
      clonedHospital.address.bus = this.form.get('address.bus')?.value;
    }
    clonedHospital.address.city = this.form.get('address.city')?.value;
    clonedHospital.address.country = this.form.get('address.country')?.value;

    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
