import AdminRole from '../enum/AdminRole';

export class AdminUser {
  public uid: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public roles: Array<AdminRole> = [];

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.uid = item.uid;
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.email = item.email;
    this.roles = [];
    item.roles.forEach((role: string) => {
      this.roles.push(AdminRole[role as keyof typeof AdminRole]);
    });
  }

  public isOrgAdmin() {
    return this.roles.includes(AdminRole.ROLE_ORG_ADMIN);
  }

  public isUserAdmin() {
    return this.roles.includes(AdminRole.ROLE_USER_ADMIN);
  }

  public isHotline() {
    return this.roles.includes(AdminRole.ROLE_HOTLINE);
  }

  public removeRole(role: AdminRole) {
    this.roles = this.roles.filter((value) => value !== role);
  }

  public addRole(role: AdminRole) {
    if (!this.roles.find((value) => value === role)) {
      this.roles.push(role);
    }
  }
}
