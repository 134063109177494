import { ModuleFeature } from "./module-feature";

export class Module {
  public name: string;
  public enabled: boolean;
  public features: Array<ModuleFeature> = [];
  public reminder_in_days?: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  private fillFromJson(item) {
    this.enabled  = item.enabled;
    this.name = item.name;
    this.features = item.features?.map(feature => new ModuleFeature(feature));

    if (item.reminder_in_days) {
      this.reminder_in_days = item.reminder_in_days;
    } else {
      delete this.reminder_in_days;
    }
  }
}
