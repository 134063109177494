import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-edit-personal-information',
  templateUrl: './edit-personal-information.component.html',
  styleUrls: ['./edit-personal-information.component.scss']
})
export class EditPersonalInformationComponent implements OnInit {
  @Output() onInformationUpdated = new EventEmitter<void>();
  
  public form: any;
  public profile?: Profile;
  public isSaving: boolean = false;
  public validationVisible: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      first_name: new UntypedFormControl(this.profile?.first_name, [Validators.required]),
      last_name: new UntypedFormControl(this.profile?.last_name, [Validators.required]),
      profile_picture: new UntypedFormControl(this.profile?.profile_picture, []),
    });
  }

  handleConfirm() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.handleSubmission();
    }
  }

  handleSubmission() {
    if (! this.profile) return;

    this.isSaving = true;

    this.profile.first_name = this.form.get('first_name').value;
    this.profile.last_name = this.form.get('last_name').value;
    this.profile.profile_picture = this.form.get('profile_picture').value;

    if (this.profile.profile_picture && this.profile.profile_picture.data) {
      this.profile.profile_picture.data = this.profile.profile_picture.data.split(',')[1];
    }

    this.userService.updateProfile(this.profile).subscribe(() => {
      this.bsModalRef.hide();
      this.onInformationUpdated.emit();
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }
}
