import { EducationalMaterial } from "./educational-material";

export class EducationalMaterialPhase {
  public id: string;
  public educational_materials: Array<EducationalMaterial> = [];
  public mappingKey: string = '';
  public phase: any;
  public originalPhase: any;

  constructor(item?: any) {
    if (item) {
      this.originalPhase = item.phase;
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.id = item.id;
    this.phase = item.phase;

    if (item.educational_materials && item.educational_materials.length > 0) {
      item.educational_materials.forEach((em: any) => {
        this.educational_materials.push(new EducationalMaterial(em));
      });
    }

    this.createMappingKey();
  }

  private createMappingKey() {
    this.recurse(this.phase);
  }

  private recurse(phase: any) {
    if (this.mappingKey == '') {
      this.mappingKey = phase.id;
    } else {
      this.mappingKey = this.mappingKey + '.' + phase.id;
    }
    
    if (phase.sub_phase_selection) {
      this.recurse(phase.sub_phase_selection);
    }
  }
}
