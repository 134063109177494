import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { AddCareModuleModalComponent } from "../../../modals/add-care-module-modal/add-care-module-modal.component";
import { CareModule } from "../../../models/care-module";
import { Hospital } from "../../../models/hospital";
import { HospitalCode } from "../../../models/hospital-code";
import { CareModuleService } from "../../../services/care-module.service";
import { GeneralService } from "../../../services/general.service";
import { HospitalCodeService } from "../../../services/hospital-code.service";
import { HospitalService } from "../../../services/hospital.service";

@Component({
  selector: 'app-hospital-care-modules',
  templateUrl: './hospital-care-modules.component.html',
})
export class HospitalCareModulesComponent implements OnInit {
  public care_modules: Array<CareModule>  =  [];
  public hospital_codes: Array<HospitalCode> = [];

  public hospital: Hospital| undefined;  
  public hospitalUid: string;

  public currentPage: number = 1;
  public totalPages: number  = 0;
  public totalElements: number | undefined;
  public currentPageSize: number = 10;

  public isLoading = false;

  constructor(
    public readonly activatedRoute: ActivatedRoute, 
    public hospitalService: HospitalService,
    public modalService: BsModalService,
    public careModuleService: CareModuleService,
    public hospitalCodeService: HospitalCodeService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.hospitalUid = params.hospitalUid;
      this.getHospital();
      this.getHospitalCodes().subscribe();
    });
  }

  private getHospital() {
    this.hospitalService.getHospital(this.hospitalUid).subscribe(hospital => this.hospital = hospital);
  }

  private getHospitalCodes(): Observable<Array<HospitalCode>> {
    return new Observable(observer => {
      this.hospitalCodeService.getHospitalCodes(this.hospitalUid, 0, 999).subscribe(result => {
        observer.next(result.hospital_codes);
        observer.complete();

        this.hospital_codes = result.hospital_codes;
        this.getCareModules().subscribe();
      })
    })
  }

  private getCareModules() {
    return new Observable(observer => {
      this.isLoading = true;
      this.careModuleService.getCareModules(this.hospitalUid, this.currentPage - 1, this.currentPageSize).subscribe(result => {
        this.isLoading = false;
        this.care_modules = result.care_modules;

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        this.attachHospitalCodes();
      })
    });
  }

  private attachHospitalCodes() {
    this.care_modules.forEach(cm => {
      const codes = this.hospital_codes.filter(hc => hc.care_module_uid === cm.uid);
      if (codes && codes.length > 0) {
        cm.hospital_code = codes[0];
      }
    })
  }

  public addCareModule(event: MouseEvent) {
    event.preventDefault();
    const modalref  = this.modalService.show(AddCareModuleModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xxl',
        initialState: {
          hospitalUid: this.hospitalUid
        }
      })
    );

    modalref.content.onCareModuleCreated.subscribe(() => {
      this.getHospital();
      this.getHospitalCodes().subscribe();
    });
  }

  onPaginationPageChanged(page: number) {
    if (page!==this.currentPage) {
      this.currentPage = page;
      this.getCareModules().subscribe();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getCareModules().subscribe();
    }
  }
}
