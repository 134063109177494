import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import { EducationalMaterial } from "../models/educational-material";

@Injectable({
  providedIn: 'root'
})
export class PhaseTranslationService extends ApiService {
  private translations: Array<{ key: string, value: string }> = [];

  public getTranslation(key: string): string {
    return this.find(key);
  }

  public loadAllTranslations(region: string): Observable<void> {
    return new Observable(observer => {
      const url = `${environment.cmsUrl}/v3/content_types/${region}/entries`;

      this.cmsGet(url).subscribe(result => {
        if (result.count > 100) {
          let pages = Math.ceil(result.count / 100);
          result.entries.forEach((item: any) => {
            this.translations.push({
              key: item.title,
              value: item.value
            })
          });

          let promises = [];
          for (let i = 1; i < pages; i++) {
            promises.push(this.cmsGet(`${url}?skip=${i * 100}`));
          }

          const joined = forkJoin(promises);

          joined.subscribe((results) => {
            results.forEach((resultPage: any) => {
              resultPage.entries.forEach((item: any) => {
                this.translations.push({
                  key: item.title,
                  value: item.value
                })
              });
            });

            observer.next();
          });
        }
      });
    });
  }

  private find(key: string): string {
    let result = this.translations.find((item) => item.key === key);

    if (!result) return key;

    return result.value;
  }

  getMaterialTranslations(materials: Array<EducationalMaterial>): Observable<Array<EducationalMaterial>> {
    return new Observable(observer => {
      const ids = materials.map(material => material.reference.key)
      const url = `${environment.cmsUrl}/v3/content_types/educational_material/entries`;
      
      this.cmsGet(url, false, ids).subscribe(translations => {
        materials.forEach(material => {
          const translation = translations.entries.find(translation => translation.uid === material.reference.key);
          material.translatedName = translation?.title ?? 'No translation found';
        })
        
        return materials;
      })
    });
  }
}
