const CmsServerUrl = 'https://eu-cdn.contentstack.com';

export const environment = {
  production: false,
  platformUrl: 'https://public-admin-api.ppp-dev.apps.jnj',
  auth_username: '2c160deb-97b6-b7d3-ceae-f8830f08eaa2',
  auth_password: '0387478e-5a2e-7288-7f4a-28faa89c0090',
  accept_header: 'application/vnd.c4t.public-admin-api.v1+json',
  content_type_header: 'application/vnd.c4t.public-admin-api.v1+json',

  cmsUrl: CmsServerUrl,
  cmsApiKey: 'blt2043dcd74f319d2f',
  cmsAccessToken: 'cs6920a94fad9928a8a9f2f2fa',
  cmsEnvironment: 'pre-qa',

  // Options
  refreshEvaluation: true,
  dev_remember_login_email: true,
  app_version: '1.0.0',

  environments: [
    {
      paths: [
        'https://dev-opsconsole.care4todayplatform.jnj.com',
        'https://d3p2ek2grifgup.cloudfront.net'
      ],
      color: '#008578',
      title: 'Development'
    },
    {
      paths: [
        'https://pre-qa-opsconsole.care4todayplatform.jnj.com',
        'https://d2jl3abz9aj3i1.cloudfront.net'
      ],
      color: '#753BBD',
      title: 'Pre-Qa'
    },
    {
      paths: [
        'https://qa-opsconsole.care4todayplatform.jnj.com',
        'https://d1kiwtl99dka1g.cloudfront.net'
      ],
      color: '#FE5000',
      title: 'Quality assurance'
    },
    {
      paths: [
        'https://demo-opsconsole.care4todayplatform.jnj.com',
        'https://d2mmno9az7ohs4.cloudfront.net'
      ],
      color: '#00B5E2',
      title: 'Demo'
    },
    {
      paths: [
        'https://admin.care4today.eu',
      ],
      color: '#CA001B',
      title: 'Production'
    }
  ],
};
