import { PhoneNumberInterface } from '../interfaces/phone-number.interface';
import { Image } from '../models/image';
import { UserProfileLocale } from './user-profile-locale';

interface MfaInterface {
  activated: boolean,
  channel: string
}

enum ContactChannel {
  EMAIL,
  PHONE
}

export class Profile {
  public uid: string;
  public email: string;
  public first_name: string;
  public last_name: string;
  public preferred_name: string;
  public mobile_number?: PhoneNumberInterface;
  public profile_picture: Image;
  public locale: UserProfileLocale;
  public mfa: MfaInterface;
  public contact_channel: ContactChannel;
  public contact_consented: boolean;
  public mobile_verification_code: string;
  
  constructor(item?: any) {
    if (!item) {
      return;
    }

    this.uid = item.uid;
    this.email = item.email;
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.preferred_name = item.preferred_name;
    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }
    this.mobile_number = item.mobile_number;
    this.mfa = item.mfa;

    if (item.locale) {
      this.locale = new UserProfileLocale(item.locale);
    }
  }
}
