import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { Hospital } from "../../../app/models/hospital";
import { HospitalService } from "../../../app/services/hospital.service";
import { GeneralService } from "../../services/general.service";
import { LanguageService } from "../../services/language.service";
import { LanguageCode } from "../../models/language-code";


@Component({
  selector: 'app-edit-hospital-preferences-modal',
  templateUrl: './edit-hospital-preferences-modal.component.html'
})
export class EditHospitalPreferencesModalComponent implements OnInit {
  @Output() onHospitalUpdated = new EventEmitter<void>();

  public measurementUnits: any[];
  public numberFormats: any[];
  public dateFormats: any;
  public timeFormats: any[];
  public weekdays: any[];
  public timeZones: any[];
  public languages: LanguageCode[];

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public hospital: Hospital;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public generalService: GeneralService,
    public languageService: LanguageService
  ) { }
  
  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.generalService.getMeasurementUnits(),
      this.generalService.getNumberFormats(),
      this.generalService.getDateFormats(),
      this.generalService.getTimeFormats(),
      this.generalService.getWeekDays(),
      this.generalService.getTimeZones(),
      
      this.languageService.getLanguageCodes(),
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.measurementUnits = data[0];
    this.numberFormats = data[1];
    this.dateFormats = data[2].dateFormats;
    this.timeFormats = data[3];
    this.weekdays = data[4];
    this.timeZones = data[5];
    this.languages = data[6]
  };

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      default_locale: new UntypedFormGroup({
        language: new UntypedFormControl(this.hospital.default_locale.language, Validators.required),
        measurement_unit: new UntypedFormControl(this.hospital.default_locale.measurement_unit, Validators.required),
        number_format: new UntypedFormControl(this.hospital.default_locale.number_format, Validators.required),
        date_format: new UntypedFormControl(this.hospital.default_locale.date_format, Validators.required),
        time_24_hours: new UntypedFormControl(this.hospital.default_locale.time_24_hours, Validators.required),
        first_day_of_week: new UntypedFormControl(this.hospital.default_locale.first_day_of_week, Validators.required),
        time_zone: new UntypedFormControl(this.hospital.default_locale.time_zone, Validators.required)
      }),
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const clonedHospital: Hospital = cloneDeep(this.hospital);

    clonedHospital.default_locale.language = this.form.get('default_locale.language')?.value;
    clonedHospital.default_locale.measurement_unit = this.form.get('default_locale.measurement_unit')?.value;
    clonedHospital.default_locale.number_format = this.form.get('default_locale.number_format')?.value;
    clonedHospital.default_locale.date_format = this.form.get('default_locale.date_format')?.value;
    clonedHospital.default_locale.time_24_hours = this.form.get('default_locale.time_24_hours')?.value;
    clonedHospital.default_locale.first_day_of_week = this.form.get('default_locale.first_day_of_week')?.value;
    clonedHospital.default_locale.time_zone = this.form.get('default_locale.time_zone')?.value;

    this.hospitalService.updateHospital(clonedHospital).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();
      this.bsModalRef.hide();
      this.onHospitalUpdated.emit(result);
    })
  };

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.edit_hospital.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
