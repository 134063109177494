<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals', hospitalUid, 'care_modules']"
          class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_care_module' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1 *ngIf="careModule">{{ careModule.defaultName }}</h1>
        </div>
        <div class="col col-auto" *ngIf="activePhase">
          <button class="btn btn-sm btn-secondary" (click)="addEducationalMaterial($event)">{{
            'pages.default.care_module_learning_materials.new' | translate }}</button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-care-module-tabs [active]="'learning_materials'" [hospital_uid]="hospitalUid" [care_module]="careModule">
          </app-care-module-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<div class="body-content">
  <div class="container" *ngIf="!isLoading">
    <div class="row">
      <div class="col">
        <ul class="breadcrumbs">
          <li>
            <a href="#" (click)="onPhaseClicked($event)">{{ 'pages.default.care_module_learning_materials.main_phases' |
              translate }}</a>
          </li>
          <li *ngFor="let phase of breadcrumbs">
            <a href="#" (click)="onPhaseClicked($event, phase)"><span class="icon"
                [inlineSVG]="'/assets/svg/chevron-right.svg'"></span> {{phase.translatedName}}</a>
          </li>
        </ul>
        <h2 *ngIf="!activePhase">{{ 'pages.default.care_module_learning_materials.main_phases' | translate }}</h2>
        <h2 *ngIf="activePhase">{{activePhase.translatedName}}</h2>
        <h6 class="text-muted">{{ activePhase?.id }}</h6>
      </div>
    </div>

    <div class="row align-items-stretch mt-3">
      <div class="col" *ngIf="phases.length === 0">
        <p class="text-muted">{{ 'pages.default.care_module_learning_materials.no_sub_phases' | translate }}</p>
      </div>
      <div class="col-4 mb-2" *ngFor="let phase of phases | sortBy:'asc': 'order'">
        <div class="card h-100">
          <div class="card-body">
            <h3>{{phase.translatedName}}</h3>
            
            <p class="text-muted">{{ phase.id }}</p>
          </div>
          <div class="card-footer">
            <p class="text-muted">{{phase.countEducationalMaterials()}} {{
              'pages.default.care_module_learning_materials.materials' | translate }}</p>
            <a href="#" class="btn btn-secondary m-start-auto" (click)="onPhaseClicked($event, phase)">
              <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!activePhase">
      <div class="col">
        <hr>
        <h2 class="text-muted">{{ 'pages.default.care_module_learning_materials.materials' | translate }}</h2>
        <p class="text-muted">{{ 'pages.default.care_module_learning_materials.materials_phase_disclaimer' | translate
          }}</p>
      </div>
    </div>
    <div class="row" *ngIf="activePhase">
      <div class="col">
        <hr>
        <h2>{{ 'pages.default.care_module_learning_materials.materials_in_this_phase' | translate }}</h2>

        @for (eduMat of activePhase.educational_materials; track eduMat) {
        <div class="action-item-block mb-1">
          <div class="block-wrapper p-start-3 p-end-1 w-100">
            <div class="row">
              <div class="col-1">
                <div class="d-flex h-100 align-items-center">
                  <div class="arrow">
                    <button (click)="move(eduMat, -1)" [disabled]="disabled(eduMat, 'up')"
                      class="btn border-white bg-transparent text-danger p-0"
                      [ngClass]="{'text-muted ': disabled(eduMat, 'up')}">
                      <span [inlineSVG]="'/assets/svg/arrow-up.svg'" class="icon"></span>
                    </button>
                  </div>

                  <div class="arrow">
                    <button (click)="move(eduMat, +1)" [disabled]="disabled(eduMat, 'down')"
                      class="btn border-white bg-transparent text-danger p-0"
                      [ngClass]="{'text-muted ': disabled(eduMat, 'down')}">
                      <span [inlineSVG]="'/assets/svg/arrow-down.svg'" class="icon"></span>
                    </button>
                  </div>
                </div>

              </div>
              <div class="col">
                <div class="row">
                  <div class="col-2">
                    <p class="text-muted mb-0">Order</p>
                    <p>{{eduMat.order}}</p>
                  </div>

                  <div class="col-10">
                    <p class="text-muted mb-0">Reference</p>
                    <p>{{eduMat.reference.key}}</p>
                  </div>
                </div>

                <p class="text-muted mb-0">Title</p>
                <p>{{eduMat.translatedName}}</p>
              </div>
              <div class="col-auto">
                <div class="d-flex h-100 align-items-center">
                  <button class="btn text-danger p-end-1" (click)="removeEducationalMaterial(eduMat, $event)">
                    {{ 'pages.default.faqs.remove' | translate }}
                    <span [inlineSVG]="'/assets/svg/close.svg'" class="m-start-1"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button class="btn block-action m-start-auto" (click)="editEducationalMaterial($event, eduMat)">
            <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon rtl-mirrored-inline-svg"></span>
          </button>
        </div>
        }
        @empty {
        <p class="text-muted">
          {{ 'pages.default.care_module_learning_materials.no_materials_in_this_phase' | translate }}
        </p>
        }
      </div>
    </div>
  </div>
</div>
