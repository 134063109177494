import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PathwayInstanceService } from "../../../services/pathway-instance.service";


@Component({
  selector: 'app-camunda-cockpit-links',
  templateUrl: './camunda-cockpit-links.component.html',
  styleUrls: ['./camunda-cockpit-links.component.scss']
})
export class CamundaCockpitLinksComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading: boolean;

  public links: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public pathwayInstanceService: PathwayInstanceService,
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup(): void {
    if (this.form) {
      return;
    } 

    this.form = this.formBuilder.group({
      uid: ['', [Validators.required]]
    });
  }

  generate(): void {
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;

    this.pathwayInstanceService.generateLinks(this.form.get('uid')?.value).subscribe((result) => {
      this.links = result;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }
}
