import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AdminUser } from "../../../app/models/admin-user";
import { AdminUsersService } from "../../../app/services/admin-users.service";

@Component({
  selector: 'app-add-admin-user-modal',
  templateUrl: './add-admin-user.component.html'
})
export class AddAdminUserComponent implements OnInit {
  @Output() onAdminUserCreated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public showRolesValidationMessage = false;
  public isSaving = false;
  public adminUser: AdminUser = new AdminUser();

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public adminUserService: AdminUsersService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      first_name: new UntypedFormControl(this.adminUser.first_name, [Validators.required, Validators.min(1), Validators.max(50)]),
      last_name: new UntypedFormControl(this.adminUser.last_name, [Validators.required, Validators.min(1), Validators.max(50)]),
      email: new UntypedFormControl(this.adminUser.email, [Validators.required, Validators.min(1), Validators.email]),
      role_org_admin: new UntypedFormControl(),
      role_user_admin: new UntypedFormControl(),
      role_hotline: new UntypedFormControl()
    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    const values = this.form.value;
    if (!values.role_org_admin && !values.role_user_admin && !values.role_hotline) {
      this.showRolesValidationMessage = true;
      this.validationVisible = true;
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.showRolesValidationMessage = false;
    this.validationVisible = false;
    this.isSaving = true;

    this.adminUserService.create(this.form.value).subscribe(() => {
      this.showSuccessToast();
      this.onAdminUserCreated.emit();
      this.onHandleClose();
    }, () => {
      this.isSaving = false
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  public showSuccessToast(): void {
    this.toastrService.info(this.translate.instant('modals.add_admin_user.success_notification'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }
}
