import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import moment from 'moment';
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import PaginationInterface from "../interfaces/pagination.interface";
import { PathwayTemplate } from "../models/pathway-template";
import { PathwayTemplateVersion } from "../models/pathway-template-version";
import { TemplateGroup } from "../models/template-group";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";


@Injectable({
  providedIn: 'root'
})
export class PathwayTemplateService extends ApiService {
  private platformUrl: string;

  constructor(http: HttpClient, authenticationService: AuthenticationService) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  searchTemplates(query: string = ''): Observable<{ templateOptions: Array<any>, templates: Array<any> }> {
    console.debug('searchTemplates');
    return new Observable(observer => {
      let resultSet: { templateOptions: Array<any>, templates: Array<any> } = {
        templateOptions: [],
        templates: []
      };

      this.getTemplates({
        name: query
      }, undefined, 0, 20).subscribe((result) => {
        for (const template of result.templates) {
          resultSet.templateOptions.push({
            value: template.uid,
            label: template.name
          });
          resultSet.templates.push(template);
        }

        observer.next(resultSet);
        observer.complete();
      });
    });
  }

  getTemplates(filters: any = {}, sort = 'name,desc', page: number = 0, size: number = 100): Observable<{ templates: Array<PathwayTemplate>, pagination: PaginationInterface }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      if (filters.hasOwnProperty('name') && filters['name']) {
        paramBits.push(`name=${filters.name}`);
      }

      if (filters.hasOwnProperty('description') && filters['description']) {
        paramBits.push(`description=${filters.description}`);
      }

      if (filters.hasOwnProperty('group') && filters['group'] != undefined && filters['group'] != '') {
        paramBits.push(`group=${filters.group}`);
      }

      if (filters.hasOwnProperty('sub_process_filter_type') && filters['sub_process_filter_type'] != '' && filters['sub_process_filter_type'] != undefined) {
        paramBits.push(`sub_process_filter_type=${filters.sub_process_filter_type}`);
      }

      if (filters.hasOwnProperty('upload_date') && filters['upload_date'].length) {
        paramBits.push(`created_at_from=${moment(filters.upload_date[0]).format('YYYY-MM-DD')}`);
        paramBits.push(`created_at_to=${moment(filters.upload_date[1]).format('YYYY-MM-DD')}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/platform/pathway-templates?${paramsString}`;

      this.authenticatedGet(url, 2).subscribe(result => {
        const templates = this.mapTemplates(result.items);
        observer.next({
          templates,
          pagination: result.pagination
        });
        observer.complete();
      });
    });
  }

  getTemplate(uid: string): Observable<PathwayTemplate> {
    const url = `${this.platformUrl}/platform/pathway-templates/${uid}`;
    return new Observable(observer => {
      this.authenticatedGet(url, 2).subscribe(result => {
        observer.next(new PathwayTemplate(result));
        observer.complete();
      });
    });
  }

  getGroups(): Observable<Array<TemplateGroup>> {
    const url = `${this.platformUrl}/platform/pathway-templates/groups`;
    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const groups = this.mapGroups(result);
        observer.next(groups);
        observer.complete();
      });
    });
  }

  create(form: any): Observable<PathwayTemplate> {
    const url = `${this.platformUrl}/platform/pathway-templates`;
    const data = {
      'name': form.name,
      'description': form.description,
      'is_sub_process': form.is_sub_process,
      'group': form.group
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data, 2).subscribe((result: HttpResponse<any>) => {
        observer.next(new PathwayTemplate(result.body));
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  updateTemplate(form: any, uid: string): Observable<void> {
    const url = `${this.platformUrl}/platform/pathway-templates/${uid}`;

    const data: any = {
      group: form.group,
      name: form.name,
      description: form.description,
      is_sub_process: form.is_sub_process
    };

    return new Observable(observer => {

      this.authenticatedPut(url, data, 2).subscribe(result => {
        observer.next();
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  getVersions(templateUid: string): Observable<PathwayTemplateVersion[]> {
    const url = `${this.platformUrl}/platform/pathway-templates/${templateUid}/versions`;

    return new Observable<PathwayTemplateVersion[]>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        let versions: PathwayTemplateVersion[] = [];

        if (result?.versions?.length) {
          versions = result?.versions?.map((version: any) => {
            let ver = new PathwayTemplateVersion(version);
            ver.templateUid = templateUid;
            return ver;
          });
        }

        observer.next(versions);
        observer.complete();
      }, (error) => observer.error(error));
    });
  }

  getVersionDetails(templateUid: string, version: number): Observable<PathwayTemplateVersion> {
    const url = `${this.platformUrl}/platform/pathway-templates/${templateUid}/versions/${version}`;
    return new Observable<PathwayTemplateVersion>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const version: PathwayTemplateVersion = new PathwayTemplateVersion(result);
        version.templateUid = templateUid;
        observer.next(version);
        observer.complete();
      });
    });
  }

  createMajorPathwayTemplateVersion(pathwayTemplateUid: string, params: any): Observable<HttpResponse<any>> {
    const url = `${this.platformUrl}/platform/pathway-templates/${pathwayTemplateUid}/versions`;
    
    return new Observable<HttpResponse<any>>(observer => {
      this.authenticatedPost(url, params).subscribe((result: HttpResponse<any>) => {
        observer.next(result);
        observer.complete();
      }, (error: HttpErrorResponse) => {
        observer.error(error);
        observer.complete()
      });
    });
  }
  
  createMinorPathwayTemplateVersion(pathwayTemplateUid: string, version: number, params: any): Observable<HttpResponse<any>> {
    const url = `${this.platformUrl}/platform/pathway-templates/${pathwayTemplateUid}/versions/${version}`;
    
    return new Observable<HttpResponse<any>>(observer => {
      this.authenticatedPatch(url, params).subscribe((result: HttpResponse<any>) => {
        observer.next(result);
        observer.complete();
      }, (error: HttpErrorResponse) => {
        observer.error(error);
        observer.complete()
      });
    });
  }

  private mapTemplates(items: Array<any>): Array<PathwayTemplate> {
    const templates: Array<PathwayTemplate> = [];
    items.forEach((item) => templates.push(new PathwayTemplate(item)));
    return templates;
  }

  private mapGroups(items: Array<any>): Array<TemplateGroup> {
    const groups: Array<TemplateGroup> = [];
    items?.forEach((item) => groups.push(new TemplateGroup(item)));
    return groups;
  }
}
