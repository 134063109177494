import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../services/general.service';
import { EditPlatformSupportedLanguagesComponent } from '../../modals/edit-platform-supported-languages/edit-platform-supported-languages.component';

@Component({
  selector: 'app-platform-configuration',
  templateUrl: './platform-configuration.component.html',
  styleUrls: ['./platform-configuration.component.scss']
})
export class PlatformConfigurationComponent implements OnInit {
  public isLoading = false;
  public platformSupportedLanguages: string;

  constructor(
    public languageService: LanguageService,
    public translate: TranslateService,
    public modalService: BsModalService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getPlatformSupportedLanguages();
  }

  getPlatformSupportedLanguages() {
    this.languageService.getPlatformSupportedLanguages().subscribe(languages => {
      this.platformSupportedLanguages = languages.map(lang => lang.description).join(', ');
    });
  }

  editSupportedLanguages() {
    const modalRef = this.modalService.show(EditPlatformSupportedLanguagesComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact',
      })
    );

    modalRef.content.onLanguagesUpdated.subscribe(() => {
      this.toastrService.info(this.translate.instant('modals.edit_platform_supported_languages.success_notification'), undefined, {
        disableTimeOut: false,
        timeOut: 4000
      });

      this.getPlatformSupportedLanguages();
    });
  }

}

