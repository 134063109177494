import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MfaService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  requestCode(): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/request-mfa-code";

      this.authenticatedPost(url, {}).subscribe(() => {
        observer.next();
      }, error => {
        observer.error(error);
      })
    });
  }

  postCode(code: string): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/mfa";

      this.authenticatedPost(url, { code: code }).subscribe(() => {
        observer.next();
      }, error => {
        observer.error(error);
      })
    })
  }
}
