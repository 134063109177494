import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl as FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DialCode } from '../../models/dial-code';
import { CountryService } from '../../services/country.service';
import { GeneralService } from '../../services/general.service';
import { HospitalService } from '../../services/hospital.service';
import { LanguageService } from '../../services/language.service';
import { ModuleService } from '../../services/module.service';
import { LanguageCode } from '../../models/language-code';
import { atLeastOneOtherRequiredValidator } from '../../validators/at-least-one-other-validator';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-add-hospital-modal',
  templateUrl: './add-hospital-modal.component.html'
})
export class AddHospitalModalComponent implements OnInit {
  public form: FormGroup;
  public validationVisible = false;
  public isSaving = false;
  public dialCodeOptions: DialCode[] = [];
  public measurementUnits: any[];
  public numberFormats: any[];
  public dateFormats: any;
  public timeFormats: any[];
  public weekdays: any[];
  public timeZones: any[];
  public countries: any[];
  public languages: LanguageCode[];
  public options: any[];
  public booleanList: any[];

  public hospitalCodeDaysValidMin = 7
  public hospitalCodeDaysValidMax = 365
  public minPWLengthMin = 8
  public minPWLengthMax = 68
  public maxPWLengthMin = 8
  public maxPWLengthMax = 68
  public historySizeMin = 5
  public historySizeMax = 10
  public expiresInDaysMin = 0
  public expiresInDaysMax = 365
  public expiryNotificationInDaysMin = 7
  public expiryNotificationInDaysMax = 30

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public formBuilder: FormBuilder,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public hospitalService: HospitalService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public countryService: CountryService,
    public moduleService: ModuleService
  ) { }

  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.generalService.getDialCodes(),
      this.generalService.getMeasurementUnits(),
      this.generalService.getNumberFormats(),
      this.generalService.getTimeFormats(),
      this.generalService.getWeekDays(),
      this.generalService.getTimeZones(),
      this.generalService.getDateFormats(),
      this.languageService.getLanguageCodes(),
      this.generalService.getOptionList(),
      this.countryService.getCountriesFromCms(),
      this.generalService.getBooleanList()
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data: any) {
    this.dialCodeOptions = data[0];
    this.measurementUnits = data[1];
    this.numberFormats = data[2];
    this.timeFormats = data[3];
    this.weekdays = data[4];
    this.timeZones = data[5];
    this.dateFormats = data[6];
    this.languages = data[7];
    this.options = data[8];
    this.countries = data[9];
    this.booleanList = data[10];

    if (this.generalService.dateFormatMap) {
      this.dateFormats = this.generalService.dateFormatMap.dateFormats;
    }
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      active: ['', [Validators.required]],
      name: ['', [Validators.required]],
      site: new FormGroup({
        name: new FormControl('', Validators.required),
        url: new FormControl('', Validators.required)
      }),
      logo: new FormGroup({
        key: new FormControl('', Validators.required),
        region: new FormControl('hospital_logo')
      }),
      metadata: new FormGroup({
        key: new FormControl('', Validators.required),
        region: new FormControl('hospital')
      }),
      phone_number: new FormGroup({
        code: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required)
      }),
      email: ['', [Validators.required]],
      address: new FormGroup({
        street: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required),
        postal_code: new FormControl('', Validators.required),
        bus: new FormControl(''),
        city: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required)
      }),
      default_locale: new FormGroup({
        language: new FormControl('', Validators.required),
        measurement_unit: new FormControl('', Validators.required),
        number_format: new FormControl('', Validators.required),
        date_format: new FormControl('', Validators.required),
        time_24_hours: new FormControl('', Validators.required),
        first_day_of_week: new FormControl('', Validators.required),
        time_zone: new FormControl('', Validators.required)
      }),
      general_configuration: new FormGroup({
        hospital_code_days_valid_indefinite: new FormControl(false),
        hospital_code_days_valid: new FormControl('', [Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)])
      }),
      security_configuration: new FormGroup({
        password_policy: new FormGroup({
          mfa: new FormControl('', Validators.required),
          use_lowercase: new FormControl('', Validators.required),
          use_uppercase: new FormControl('', Validators.required),
          use_numbers: new FormControl('', Validators.required),
          use_specials: new FormControl('', Validators.required),
          min_length: new FormControl('', [Validators.required, Validators.min(this.minPWLengthMin), Validators.max(this.minPWLengthMax)]),
          max_length: new FormControl('', [Validators.required, Validators.min(this.maxPWLengthMin), Validators.max(this.maxPWLengthMax)]),
          history: new FormControl('', Validators.required),
          history_size: new FormControl('', [Validators.required, Validators.min(this.historySizeMin), Validators.max(this.historySizeMax)]),
          expires: new FormControl('', Validators.required),
          expires_in_days: new FormControl('', [Validators.required, Validators.min(this.expiresInDaysMin), Validators.max(this.expiresInDaysMax)]),
          expiry_notification_in_days: new FormControl('', [Validators.required, Validators.min(this.expiryNotificationInDaysMin), Validators.max(this.expiryNotificationInDaysMax)])
        }),
      }),
      modules: new FormGroup({
        messaging: new FormControl(false),
        appointment: new FormControl(false),
        analytics: new FormGroup({
          general: new FormControl(false),
          pathways_overview: new FormControl(false),
          patient_onboarding: new FormControl(false)
        }, atLeastOneOtherRequiredValidator('general')),
      }),
    });

    this.setupAnalyticsSubscriptions();

    this.form.get('general_configuration.hospital_code_days_valid_indefinite')?.valueChanges.subscribe(value => {
      let codeDaysValid = this.form.get('general_configuration.hospital_code_days_valid');
      if (value) {
        codeDaysValid?.clearValidators();
      } else {
        codeDaysValid?.addValidators([Validators.required, Validators.min(this.hospitalCodeDaysValidMin), Validators.max(this.hospitalCodeDaysValidMax)]);
      }

      codeDaysValid?.updateValueAndValidity();
    });
  }

  setupAnalyticsSubscriptions(): void {
    const analytics = this.form.get('modules.analytics');
    const generalControl = analytics?.get('general');
    const controls = ['pathways_overview', 'patient_onboarding' ];

    generalControl?.valueChanges.subscribe(value => {
      this.handleGeneralControl(value);
    });

    controls.forEach(control => {
      analytics.get(control)?.valueChanges.subscribe(value => {
        this.handleAnalyticsControls(value);
      });
    });
  }

  handleGeneralControl(value: boolean) {
    const analytics = this.form.get('modules.analytics');
    const controls = ['pathways_overview', 'patient_onboarding'];

    if (!value) {
      controls.forEach(control => {
        const ctrl = analytics?.get(control);
        if (ctrl?.value) {
          ctrl.setValue(false, { emitEvent: false });
          ctrl.updateValueAndValidity({ emitEvent: false });
        }
      });
    }
  }

  handleAnalyticsControls(value: boolean) {
    const generalControl = this.form.get('modules.analytics.general');

    if (value && !generalControl?.value) {
      generalControl?.setValue(true, { emitEvent: false });
      generalControl?.updateValueAndValidity({ emitEvent: false });
    }
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;


    const modules = cloneDeep(this.form.value.modules);

    const hospital = cloneDeep(this.form.value);
    delete hospital.modules;

    this.hospitalService.createHospital(this.form.value).subscribe({
      next: hospital => {

        const analyticsModuleStatus = modules.analytics.general;
        const analyticsFeatures: Array<{ name: string, enabled: boolean }> = [
          {
            name: 'pathways_overview',
            enabled: modules.analytics.pathways_overview
          },
          {
            name: 'patient_onboarding',
            enabled: modules.analytics.patient_onboarding
          },
        ];

        forkJoin([
          this.moduleService.setModuleStatus(hospital.uid, 'messaging', this.form.get('modules.messaging')?.value),
          this.moduleService.setModuleStatus(hospital.uid, 'appointment', this.form.get('modules.appointment')?.value),
          this.moduleService.setFeaturedModuleStatus(hospital.uid, 'analytics', analyticsModuleStatus, analyticsFeatures)
        ]).subscribe({
          next: () => {
            this.onCreateSuccess();
          }, error: () => this.isSaving = false
        });
      }, error: () => this.isSaving = false
    });
  };

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.add_hospital.hospital_created'), undefined, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.bsModalRef.hide();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
