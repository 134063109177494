export class HospitalCode {
  public uid: string;
  public code: string;
  public days_valid?: number | null;
  public care_module_uid: string;
  public days_valid_indefinite: boolean;

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) { 
    this.uid = item.uid;
    this.code = item.code;
    this.days_valid = item.days_valid;
    this.care_module_uid = item.care_module.uid;
    this.days_valid_indefinite = item.days_valid_indefinite;
  }
}
