<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospitals' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ hospital?.name }}</h1>
        </div>
        <div class="col col-auto">
          <button class="btn btn-sm btn-secondary" (click)="addConsent($event)">{{ 'pages.default.consents.new' | translate }}</button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-hospital-tabs [hospital]="hospital" [active]="'consents'"></app-hospital-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <p class="text-muted" *ngIf="consents.length === 0">
      {{ 'pages.default.consents.empty' | translate }}
    </p>
    <table class="table admin-table" *ngIf="consents.length > 0">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">{{ 'pages.default.consents.order' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.enabled' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.key' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.version' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.category' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.languages' | translate }}</th>
          <th scope="col">{{ 'pages.default.consents.client' | translate }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let consent of consents; let index = index;">
          <td class="arrow">
            <button (click)="move($event, consent, -1)" [disabled]="disabled(consent, 'up')" class="btn border-white bg-transparent text-danger p-0" [ngClass]="{'text-muted ': disabled(consent, 'up')}">
              <span [inlineSVG]="'/assets/svg/arrow-up.svg'" class="icon"></span>
            </button>
          </td>
          <td class="arrow pl-0">
            <button (click)="move($event, consent, +1)" [disabled]="disabled(consent, 'down')" class="btn border-white bg-transparent text-danger p-0" [ngClass]="{'text-muted ': disabled(consent, 'down')}">
              <span [inlineSVG]="'/assets/svg/arrow-down.svg'" class="icon"></span>
            </button>
          </td>
          <td><strong>{{consent.order}}</strong></td>
          <td>
            <span *ngIf="consent.enabled" [inlineSVG]="'/assets/svg/true.svg'"></span>
            <span *ngIf="!consent.enabled" [inlineSVG]="'/assets/svg/false.svg'"></span>
          </td>
          <td>{{consent.key}}</td>
          <td>{{consent.version}}</td>
          <td>{{consent.category}}</td>
          <td>{{consent.languages.join(', ')}}</td>
          <td>
            <span *ngIf="consent.is_hcp_consent">{{ 'form.labels.hcp' | translate }}</span>
            <span *ngIf="consent.is_hcp_consent && consent.is_patient_consent">, </span>
            <span *ngIf="consent.is_patient_consent">{{ 'form.labels.patient' | translate }}</span>
          </td>
          <td class="action">
            <a href="#" class="action-button" (click)="editConsent($event, consent)">
              <span [inlineSVG]="'/assets/svg/edit.svg'"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="align-items-center pagination-container">
      <!-- total items -->
      <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
      <!-- pagination -->
      <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
      <!-- pagination range -->
      <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
    </div>
  </ng-container>
</div>
