import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LocaleService } from '../services/locale.service';


@Pipe({ name: 'timeZoneDateTime', pure: false })
export class TimeZoneDateTimePipe implements PipeTransform {
  constructor(
    private readonly localeService: LocaleService,
  ) { }

  transform(date: string): string | null {
    const timeZone = this.localeService.getLocalePreferences().locale?.time_zone;
    const time24Hours = this.localeService.getLocalePreferences().locale?.time_24_hours;
    let dateFormat = this.localeService.getLocalePreferences().dateFormat?.format;
    let transformedDate: string = '';
    let transformedTime: string = '';

    if (!dateFormat) {
      return null;
    }

    let mDate = moment(date);
    if (!mDate.isValid()) {
      mDate = moment(new Date(date));
    }

    dateFormat = this.localeService.transformFormatToMomentFormat(dateFormat);

    transformedDate = timeZone ? mDate.tz(timeZone).format(dateFormat) : mDate.format(dateFormat);

    const time = timeZone ? mDate.tz(timeZone) : mDate;
    transformedTime = time24Hours ? time.format('HH:mm') : time.format('hh:mm a');

    return `${transformedDate} ${transformedTime}`;
  }
}
